<template>
	<v-container class="d-flex flex-column align-start" pa-0 fluid fill-height>
		<FullHeader />
		<BlueprintHeader />
		<ForgeViewer />
	</v-container>
</template>

<script>
import FullHeader from '../../Globals/Headers/FullHeader'
import BlueprintHeader from './BlueprintHeader'
import ForgeViewer from './ForgeViewer.vue'

export default {
	name: 'Planimetria',
	components: {FullHeader, BlueprintHeader, ForgeViewer},
	data: () => ({
	}),
	mounted(){
	},
	methods:{
	}
};
</script>
