<template>
	<v-container fluid>
		<v-row align="center" v-if="$vuetify.breakpoint.mobile">
			<v-col cols="2" sm="5">
				<v-icon color="#2D4B95"
					@click="$store.menuStore.commit('setMenuSidebarStatus', !$store.menuStore.state.menuSidebarStatus)">mdi-menu</v-icon>
			</v-col>
			<v-col cols="7" sm="5">
				<HeaderProjectInfoMobile v-if="headerType === 1 || headerType === 99"/>
				<HeaderSelectListingType v-if="headerType === 2"/>
			</v-col>
			<v-col cols="3" sm="5">
				<div class="d-flex align-center">
					<NotificationHeader size="x-small"/>
					<v-icon dark class="toggle-button-project-sidebar pa-1 ml-2"
						small
						@click="$store.menuStore.commit('setSidebarStatus', !$store.menuStore.state.sidebarStatus)">mdi-expand-all-outline</v-icon>
				</div>
			</v-col>
			<v-col cols="12" class="pa-0">
				<v-divider></v-divider>
			</v-col>
		</v-row>
		<v-row align="center" v-else>
			<v-col cols="12" sm="5">
				<HeaderProjectInfo v-if="headerType === 1"/>
				<HeaderSelectListingType v-if="headerType === 2"/>
			</v-col>
			<v-col cols="12" sm="4">
				<SearchBarHeader />
			</v-col>
			<v-col cols="12" sm="3">
				<div class="d-flex align-center">
					<NotificationHeader />
					<ProjectActionButtons v-if="actionButtonsType === 1"/>
					<UserActionButtons v-if="actionButtonsType === 2 && checkPermissions(['create_users'])"/>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import HeaderProjectInfo from './HeaderProjectInfo'
import HeaderProjectInfoMobile from './HeaderProjectInfoMobile'
import HeaderSelectListingType from './HeaderSelectListingType'
import SearchBarHeader from './SearchBarHeader'
import NotificationHeader from './NotificationHeader'
import UserActionButtons from './ActionButtons/UserActionButtons'
import ProjectActionButtons from './ActionButtons/ProjectActionButtons'
import {checkPermissions} from '@/helpers/permissions'

export default {
	name: 'FullHeader',
	components: {HeaderProjectInfo, HeaderProjectInfoMobile, SearchBarHeader, NotificationHeader, HeaderSelectListingType, UserActionButtons, ProjectActionButtons},
	props: {
		headerType: {
			type: Number,
			default: 1
		},
		actionButtonsType: {
			type: Number,
			default: 1
		},
	},
	data: () => ({
		//
	}),
	mounted(){
	},
	methods: {checkPermissions}
};
</script>
<style scoped>
	.toggle-button-project-sidebar{
		background: #5E85EF;
	}
</style>