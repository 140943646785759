<template>
	<v-container fluid pa-0>
		<v-text-field
				outlined
				clearable
				dense
				rounded
				hide-details
				label="Búsqueda"
				type="text"
				class="search-input"
				v-model="searchInput"
				@keydown="sendToSearch"
			>
				<template v-slot:prepend-inner>
					<v-btn
						class="search-button"
						fab
						dark
						small
						color="#5E85EF"
						@click="sendToSearch"
					>
						<v-icon dark>mdi-magnify</v-icon>
					</v-btn>
				</template>
				<template v-slot:label>
					<p class="mb-0 ml-5">Búsqueda</p>
				</template>
		</v-text-field>
		<div class="search-container" :class="$vuetify.breakpoint.mobile ? 'mobile':''">
			<SearchBlock v-if="showResults" :searching="searching" :searchResult="searchResult" :closeResults="closeResults"/>
		</div>
	</v-container>
</template>

<script>

import {search} from '@/helpers/api/general'
import SearchBlock from './SearchBlock'

export default {
	name: 'SearchBarHeader',
	components: {
		SearchBlock
	},
	data: () => ({
		searchInput: '',
		showResults: false,
		searching: false,
		searchResult: []
	}),
	mounted(){
	},
	methods: {
		closeResults(){
			this.searchInput = ''
			this.showResults = false
			this.searching = false
			this.searchResult = []
		},
		sendToSearch(event){
			if(event instanceof KeyboardEvent && event.keyCode == 13){
				this.searchForString(this.searchInput)
			}
			else if(event instanceof PointerEvent){
				this.searchForString(this.searchInput)
			}
		},
		async searchForString(searchString){
			this.searching = true
			this.showResults = true
			const data = {
				search: searchString,
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const searchResult = await search(data)
			if(searchResult.code == 200){
				this.searchResult = searchResult
			}
			this.searching = false
		}
	}
};
</script>

<style scoped>
	.search-input{
		position: relative;
		background-color: white;
		border-color: red;
	}
	/deep/ .search-input input{margin-left: 20px;}
	.search-button{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	.search-container{
		position: absolute;
		width: 400px;
		z-index: 999;
	}
	.search-container.mobile{
		left: 0;
		padding: 0 5px;
		max-width: 100%;
	}
</style>
