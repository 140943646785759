<template>
	<div class="px-4">
		<h4 class="mb-1 text-primary">Observaciones</h4>
		<div v-for="(observation,index) in observations" :key="index">
			<div v-html="observation.description"></div>
			<v-row align="start" class="mt-0">
				<v-col cols="12" md="12">
					<div class="d-flex justify-space-between">
						<p class="mb-0 text-primary font-weight-bold">
							{{observation.attachments.length === 0 ? 'Sin ':''}}
							Adjuntos
						</p>
						<div v-if="observation.attachments.length > 0">
							<v-btn
								class="mx-2 my-1"
								rounded
								color="#2D4B95"
								dark
								x-small
								:loading="loadingFilePath"
								@click="() => downloadAttachments(observation.attachments)"
							>
								<v-icon dark small>mdi-paperclip</v-icon>
								<p class="mb-0 ml-2">Descargar Todo</p>
							</v-btn>
							<v-btn
								class="mx-2 my-1"
								rounded
								color="#5E85EF"
								dark
								x-small
								:loading="loadingFilePath"
								@click="() => showAttachments = !showAttachments"
							>
								<v-icon dark small>{{showAttachments ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
								<p class="mb-0 ml-2">{{showAttachments ? 'Ocultar':'Ver Más'}}</p>
							</v-btn>
						</div>
					</div>
					<v-scroll-y-transition leave-absolute v-if="observation.attachments.length > 0">
						<div v-if="showAttachments" :key="1">
							<MediaGallery
								v-if="observation.attachments.filter(item => item.file.pathmedia).length > 0"
								:images="observation.attachments.filter(item => item.file.pathmedia).map(item => item.file.pathmedia)" />
							<v-divider class="my-2"></v-divider>
							<div class="adjuntos-container"
								v-if="observation.attachments.filter(item => !item.file.pathmedia).length > 0">
								<div class="d-flex flex-row align-center justify-space-between my-2 py-1 px-2"
									:class="index%2 != 0 ? 'bg-dark-list':''"
									:key="index"
									v-for="(attachment, index) in observation.attachments.filter(item => !item.file.pathmedia)">
									<p class="mb-0">{{attachment.file.name}}</p>
									<p class="mb-0 text-primary">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													class="mx-1 my-1"
													color="#5E85EF"
													dark
													small
													icon
													:loading="loadingFilePath"
													v-bind="attrs"
													v-on="on"
													@click="getFilesUrl(attachment, true)"
												>
													<v-icon color="white" small class="info-icon small-icon">mdi-open-in-new</v-icon>
												</v-btn>
											</template>
											<span>Visualizar en navegador</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													class="mx-1 my-1"
													color="#5E85EF"
													dark
													x-small
													:icon="$vuetify.breakpoint.mobile"
													:loading="loadingFilePath"
													v-bind="attrs"
													v-on="on"
													@click="getFilesUrl(attachment, true, true)"
												>
													<v-icon color="white" small class="bg-light-blue mr-2" :class="$vuetify.breakpoint.mobile ? 'info-icon':''">mdi-cloud-download-outline</v-icon>
													<p class="mb-0" v-if="!$vuetify.breakpoint.mobile">Descargar</p>
												</v-btn>
											</template>
											<span>Descargar archivo</span>
										</v-tooltip>
									</p>
								</div>
							</div>
						</div>
					</v-scroll-y-transition>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import {getPublicUrl} from '@/helpers/api/tasks'

export default {
	name: 'RevisionObservation',
	props: {
		observations: {
      type: Array,
      required: true
    }
	},
	data: () => ({
		loadingFilePath: false,
		showAttachments: false
	}),
	methods: {
    async getFilesUrl(attachments, singleFile = false, download = false){
      if(singleFile){ attachments = [attachments] }
      this.loadingFilePath = true
      const files = await getPublicUrl({attachments, force_download: download})
      if(files.code === 200){
        files.files.forEach((file) => {
          window.open(file.url);
        })
      }
      this.loadingFilePath = false
    },
	}
}
</script>