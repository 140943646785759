<template>
	<div class="px-2 d-flex align-center justify-center" style="min-height: 70px; border-left: 1px solid #eaeaea; border-right: 1px solid #eaeaea;"
		@click.stop="clickParent">
		<v-form ref="form" v-model="validform">
			<v-text-field
				v-model="newPaymentStatusForm.progress"
				placeholder="% Avance"
				type="number"
				outlined
				dense
				hide-details
				:height="20"
				class="custom-input-numbers text-center"
				:rules="[...requiredRule, ...percentRule]"
				@input="onChangeProgress"
			>
			</v-text-field>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<div class="d-flex flex-row justify-end align-center mt-1"
						v-bind="attrs"
						v-on="on">
						<div class="d-flex flex-row align-center mr-1">
							<v-icon color="#2D4B95" class="mr-1" small>mdi-currency-usd</v-icon>
							<p class="mb-0 normal caption font-weight-bold">
								{{+parseFloat(additional.last_revision.total_requested*newPaymentStatusForm.progress/100).toFixed( 2 )}}
							</p>
						</div>
					</div>
				</template>
				<span>Monto correspondiente</span>
			</v-tooltip>
		</v-form>
	</div>
</template>
<script>
export default {
	name: 'NewPaymentStatusAdditionalForm',
	props: {
		additional: {
			type: Object,
			required: true
		},
		onChangeProgress: {
			type: Function,
			required: true
		},
		closeForm: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		loading: false,
    validform: true,
		newPaymentStatusForm: {
			description: null,
			progress: null,
			amount: null
		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
		percentRule: [
			v => v && v.toString().match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) || 'El porcentaje de avance debe estar entre 0 y 100'
		],
		decimalsRule: [
			v => v && v.toString().match(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/) || 'El monto puede tener 2 decimales, formato XXXXXX.XX'
		],
	}),
	methods: {
		clickParent(){
			console.log('clickParent')
		},
		changeFormValue(value){
			console.log('changeFormValue: ', value)
			this.onChangeProgress(value)
		}
	}
}
</script>

<style scoped>
	.text-primary-color{
		color: #2D4B95;
	}
	.high{
		color: #F4516C;
	}
	.medium{
		color: #fb8c00;
	}
	.normal{
		color: #3EBFA3;
	}
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;min-height: 30px!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center; font-size: 12px; padding: 0}
</style>