<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.usersStore.state.newUserModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-account-plus-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Creación de Usuario</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Nombre</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.name"
                    label="Nombre"
                    placeholder="Ingrese nombre del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Empresa {{this.$store.userStore.state.userCompanies.length}}</h2>
                </v-col>
                <v-col cols="9" class="py-0">
                  <div class="d-flex flex-row">
                    <v-select
                      v-model="newUserData.company_id"
                      :items="$store.userStore.state.userCompanies"
                      :loading="!$store.userStore.state.userInfo"
                      item-text="company.name"
                      item-value="company.id"
                      label="Seleccione Empresa"
                      single-line
                      outlined
                      dense
                      required
                      :rules="requiredRule"
                    ></v-select>
                    <v-btn class="mx-2" fab dark x-small color="#5E85EF"
                      v-if="checkCompanyLevelPermissions(['create_company'])"
                      @click="$store.projectsStore.commit('setNewCompanyModal', true)">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Proyecto</h2>
                </v-col>
                <v-col cols="9" class="py-0">
                  <div class="d-flex flex-row">
                    <v-select
                      v-model="newUserData.project_id"
                      :items="$store.projectsStore.state.projectsList"
                      :loading="!$store.projectsStore.state.projectsList"
                      item-text="name"
                      item-value="id"
                      label="Asigne al usuario a un proyecto existente"
                      single-line
                      outlined
                      dense
                      required
                      multiple
                      :rules="requiredRule"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Imagen de Perfil</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-file-input
                    v-model="newUserData.avatar"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Seleccione imagen de perfil"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Email</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.email"
                    label="Email"
                    placeholder="Ingrese email del usuario"
                    outlined
                    required
                    dense
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Teléfono</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.phone"
                    label="Teléfono"
                    placeholder="Ingrese teléfono del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Dirección</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.address"
                    label="Dirección"
                    placeholder="Ingrese dirección del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Cargo por defecto</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-select
                    v-model="newUserData.default_job"
                    :items="$store.usersStore.state.jobTypes"
                    item-text="name"
                    item-value="id"
                    label="Seleccione cargo por defecto"
                    hint="Al asignar a este usuario a un nuevo proyecto, se ingresará con este cargo si no seleccionas uno"
                    persistent-hint
                    single-line
                    outlined
                    dense
                    required
                    :rules="requiredRule"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#2D4B95"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AcceptCancelModal :acceptRejectModal="acceptRejectModal" :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel"/>
  </div>
</template>

<script>
import {createUser, getUserByEmail} from '@/helpers/api/user'
import {getProjectsByUser} from '@/helpers/api/project'
import AcceptCancelModal from '@/components/Globals/Modals/General/AcceptCancelModal.vue'

export default {
  name: 'NewUserModal',
  components: { AcceptCancelModal },
  data: () => ({
    validform: true,
    newUserData: {
      name: null,
      email: null,
      phone: null,
      address: null,
      default_job: 2,
      avatar: null,
      company_id: null,
      project_id: null,
    },
		userCreated: null,
    acceptRejectModal: false,
    acceptRejectModalData: null,
    loadingData: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  mounted(){
    const projectID = this.$store.projectsStore.state.selectedProject.id
    this.$store.usersStore.dispatch('jobTypesGet', projectID)
    if(!this.$store.projectsStore.state.projectsList){
      this.getProjectList()
    }
  },
  methods: {
    closeModal(){
      this.$store.usersStore.commit('setNewUserModal', false)
      this.newUserData = {
        name: null,
        email: null,
        phone: null,
        address: null,
        default_job: 2,
        avatar: null,
        company_id: null,
        project_id: null,
      }
      this.validform = true
      this.loadingData = false
    },
    async getProjectList(){
      this.loading = true
      const projects = await getProjectsByUser()
      if(projects.code === 200){
        this.$store.projectsStore.commit('setProjectsList', projects.projects)
      }
      this.loading = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const formData = new FormData()
        let newUserData = {...this.newUserData}
        Object.keys(this.newUserData).forEach((key) => {

          if(newUserData[key]){
            if(key == 'project_id'){ newUserData[key] = JSON.stringify(newUserData[key]) }
            formData.append(key, newUserData[key])
          }
        })
        const user = await createUser(formData)
        if(user.code === 200){
					this.userCreated = user.user
          const data = {
            project_id: this.$store.projectsStore.state.selectedProject.id,
            start: 0,
            limit: 20
          }
          this.$store.usersStore.dispatch('getUsersList', data)
          const messageData = {
            message: 'Se ha creado correctamente el usuario',
            title: 'Usuario creado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
          this.setMessage(messageData)
          this.closeModal()
        }
        else if(user.data){
					this.userCreated = user.user
          user.data.forEach(message => {
            const messageData = {
              message,
              title: 'Error',
              type: 'danger',
              icon: 'mdi-close-octagon',
              color: '#F4516C',
            }
            this.setMessage(messageData)
          })
					if( user.data.length == 1 && user.data[0].includes('Ese email ya está en uso') ){
						this.showAdduserToProjectAlert()
					}
        }
        this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.globalStore.dispatch('addMessage', data)
    },
    showAdduserToProjectAlert(){
      this.acceptRejectModalData = {
        title: 'Agregar usuario a proyecto',
        content_a: 'El correo ya está en uso. ¿Deseas configurar los proyectos de este usuario?.'
      }
      this.acceptRejectModal = true
    },
    async closeModalAcceptCancel(status = false){
      this.acceptRejectModal = false
      this.acceptRejectModalData = null
      if(status){
				const user = await getUserByEmail({email: this.newUserData.email})
        this.closeModal()
				if(user.code == 200){
					this.editUserProjects(user.user)
				}
      }
    },
		editUserProjects(user){
      this.$store.usersStore.commit('setSelectedUserInfo', user)
      this.$store.usersStore.commit('setEditUserProjectsModal', true)
		},
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>