<template>
	<v-container class="d-flex flex-column align-start" fluid fill-height
		:class="this.$store.rdiStore.state.selectedType === 2 || this.$store.rdiStore.state.selectedType === 3 ? 'scrollable-list-bak':''">
		<FullHeader />
		<RdiContainer />
	</v-container>
</template>

<script>
import FullHeader from '../../Globals/Headers/FullHeader'
import RdiContainer from './RdiContainer'

export default {
	name: 'RdiIndex',
	components: {FullHeader, RdiContainer},
	data: () => ({
		loading: false
	}),
	mounted(){
	},
	methods: {
	}
};
</script>


<style>
	.scrollable-list{
		flex: 1 1 auto;
		height: 100%;
	}
	.scrollable-list #consultas-container{
		flex: 1 1 auto;
		height: 100%;
	}
</style>