<template>
	<v-container class="d-flex flex-column align-start" pa-0 fluid fill-height>
		<FullHeader />
		<ProjectDetail v-if="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject != 1" />
		<NoSelectedProject v-else />
	</v-container>
</template>

<script>
import FullHeader from '../../Globals/Headers/FullHeader'
import ProjectDetail from './ProjectDetails/ProjectDetail'
import NoSelectedProject from './NoSelectedProject'
export default {
	name: 'DashboardIndex',
	components: {FullHeader, ProjectDetail, NoSelectedProject},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
