<template>
	<v-container fluid pa-0 style="background-color: #F0F2F8;" fill-height class="d-flex flex-row">
		<Sidebar v-if="$store.userStore.state.isAuthenticated"/>
		<RouterContainer/>
		<ProjectSidebar v-if="$store.userStore.state.isAuthenticated" />
		<GlobalModalsIndex v-if="$store.userStore.state.isAuthenticated" />
		<FileUploadCenter />
		<TasksManager />
		<AlertMessageCenter />
		<FirebaseController v-if="$store.userStore.state.isAuthenticated && $store.userStore.state.userInfo"/>
		<Reminders />
		<UpdateVersion />
	</v-container>
</template>

<script>
import Sidebar from './Sidebar'
import RouterContainer from './RouterContainer'
import ProjectSidebar from './ProjectSidebar/ProjectSidebarIndex'
import GlobalModalsIndex from '../Globals/Modals/GlobalModalsIndex'
import FileUploadCenter from '@/components/Globals/Helpers/FileUploadCenter'
import TasksManager from '@/components/Globals/Helpers/TasksManager'
import AlertMessageCenter from '@/components/Globals/Helpers/AlertMessageCenter'
import FirebaseController from '@/components/Globals/Helpers/FirebaseController'
import Reminders from '@/components/Globals/Helpers/Reminders'
import UpdateVersion from '@/components/Globals/Modals/General/UpdateVersion'

export default {
	name: 'MainLayout',
	components: {
		Sidebar,
		RouterContainer,
		ProjectSidebar,
		GlobalModalsIndex,
		FileUploadCenter,
		TasksManager,
		AlertMessageCenter,
		FirebaseController,
		Reminders,
		UpdateVersion
	},
	data: () => ({}),
};
</script>
