<template>
	<v-container fluid pa-0 fill-height>
		<v-card fill-height style="width: 100%;">
			<v-card-text>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="5">
						<router-link tag="p" class="mb-0 font-weight-bold pointer-cursor" :to="{ name: 'Consultas' }">{{info.title}}</router-link>
						<p class="mb-0">Velocidad de Solución Promedio</p>
						<p class="mb-0 font-weight-bold"><span class="headline">{{info.speed_solution.value | oneDecimalsNumber}}</span> {{info.speed_solution.suffix}}</p>
					</v-col>
					<v-col cols="7">
						<div class="d-flex flex-row" style="width: 100%;">
							<div class="d-flex flex-row justify-start align-center" style="flex: 1;">
								<p class="mb-0 font-weight-bold mr-1">{{info.total_title}}</p>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon :color="$options.filters.getColorStatusModulo(info.semaforo_consultas)" small
											v-bind="attrs"
											v-on="on">mdi-record-circle</v-icon>
									</template>
									<p class="caption mb-0">Estado general del módulo: <span class="font-weight-bold">{{$options.filters.getTextStatusModulo(info.semaforo_consultas)}}</span></p>
								</v-tooltip>
							</div>
							<v-btn text class="pa-0" style="height: auto;"
								v-if="checkPermissions(['create_tasks'])"
								@click="$store.consultasStore.commit('setCreateTareaModal', true)">
								<v-icon color="#5e85ef" small class="mr-1">mdi-plus-circle</v-icon>
								<p class="mb-0 caption text-capitalize">Nueva Tarea</p>
							</v-btn>
						</div>
						<v-row>
							<v-col cols="4" class="px-1" v-for="(graph, index) in info.circle_graphs" :key="index">
								<DoughnutChart
									:chartdata="{
										datasets: [
											{
												backgroundColor: [graph.color,'#D9D9D9'],
												data: [graph.value, 100 - graph.value],
											} 
										]
									}"
									:centerText="graph.center_text"
									:options="{
										tooltips: {enabled: false},
										cutoutPercentage: 75,
										responsive: true,
										maintainAspectRatio: true,
										elements: {
											center: {
												text: graph.center_text
											}
										}
									}"/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row class="project-detail-card" v-else>
					<v-col cols="5">
						<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
						<v-skeleton-loader type="heading"></v-skeleton-loader>
					</v-col>
					<v-col cols="7">
						<v-skeleton-loader type="list-item"></v-skeleton-loader>
						<v-skeleton-loader type="avatar,avatar,avatar" class="d-flex"></v-skeleton-loader>
					</v-col>
				</v-row>
				<v-divider class="my-3"></v-divider>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="5">
						<p class="mb-0 font-weight-bold">{{info.adicionales.title}}</p>
						<p class="mb-0">Tiempo promedio de espera</p>
						<p class="mb-0 font-weight-bold">
							<span class="headline">{{info.adicionales.speed_solution.value | oneDecimalsNumber}}</span> {{info.adicionales.speed_solution.suffix}}
							<span class="caption" v-if="info.adicionales.speed_solution_last">/ <span>{{info.adicionales.speed_solution_last.value | oneDecimalsNumber}}</span> {{info.adicionales.speed_solution_last.suffix}}</span>
						</p>
					</v-col>
					<v-col cols="7">
						<BarChart
							style="max-height: 100px;"
							:chartdata="{
								labels: ['S4', 'S3', 'S2', 'S1'],
								datasets: [
									{
										backgroundColor: '#EAB261',
										data: info.adicionales.graphs.created_by_week
									},
									{
										backgroundColor: '#34BFA3',
										data: info.adicionales.graphs.solved_by_week
									}
								]
							}"
							:options="{
								responsive: true,
								maintainAspectRatio: false,
								legend: {
									display: false
								},
								tooltips: {
									callbacks: {
										label: function(tooltipItem) {
											return tooltipItem.yLabel;
										}
									}
								},
								scales: {
									yAxes: [{
										ticks: {
											beginAtZero: true
										}
									}]
								}
							}"
						/>
						<!-- <p class="mb-0 font-weight-bold">{{info.adicionales.total_title}} <v-icon :color="info.adicionales.general_status" small>mdi-record-circle</v-icon></p>
						<div class="d-flex align-center" v-for="(graph, index) in info.adicionales.graphs" :key="index">
							<p class="mb-0">{{graph.title}}</p>
							<v-progress-linear :value="graph.value" :color="graph.color" rounded class="ml-2" style="flex: 1;"></v-progress-linear>
						</div> -->

					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import DoughnutChart from '@/components/Charts/Doughnut'
import BarChart from '@/components/Charts/Bar'
export default {
	name: 'ProjectDetailCardConsultas',
	components: {DoughnutChart, BarChart},
	props: ['info'],
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>

<style scoped>
	.pointer-cursor{cursor: pointer}
	.project-detail-card, .project-detail-card p{
		color: #2D4B95;
	}
</style>