<template>
	<div class="text-center" v-if="closeOpenProjectModal.status">
		<v-dialog
			v-model="closeOpenProjectModal.status"
			max-width="500"
			scrollable
			persistent
		>
			<v-card>
				<v-card-text class="pa-0">
					<div class="py-4 px-8">
						<div class="d-flex flex-row align-center justify-space-between">
							<div>
								<p class="mb-0 font-weight-bold text-primary title">{{isClosing ? 'Cerrar Proyecto':'Reabrir Proyecto'}}</p>
								<p class="mb-0 text-primary">{{isClosing ? 'Estás finalizando el proyecto':'Estás reabriendo el proyecto'}} <b>{{closeOpenProjectModal.info.name}}</b>.</p>
								<div v-if="isClosing">
									<p class="mb-2 text-primary">Al continuar con esta acción este proyecto desaparecerá de todos los listados y selecciones de los usuarios asociados.</p>
									<p class="mb-2 text-primary caption font-weight-bold">Podrás reestablecer este proyecto desde el listado de cerrados en caso de que lo necesites.</p>
								</div>
								<div v-else>
									<p class="mb-2 text-primary">Al continuar con esta acción este proyecto volverá a aparecer en los listados de los usuarios asociados a este.</p>
									<p class="mb-2 text-primary caption font-weight-bold">Podrás volver a finalizarlo si lo necesitas.</p>
								</div>
							</div>
						</div>
						<v-row align="center" class="my-2">
							<v-col cols="12">
								<p class="mb-2 text-primary">Por favor indica una razón de por qué estás {{isClosing ? 'cerrando':'reabriendo'}} este proyecto</p>
								<v-textarea
									v-model="message"
									solo
									rows="2"
									label="Razón"
									class="mt-3 mb-5"
									hide-details=""
								></v-textarea>
							</v-col>
						</v-row>
						<v-row align="center">
							<v-col cols="12">
								<div class="d-flex flex-row justify-space-between">
									<v-btn
										class="mx-2 my-1"
										rounded
										color="white"
										raised
										:loading="loading"
										@click="closeModalSelf()"
									>
										<p class="mb-0 text-primary">Cancelar</p>
									</v-btn>
									<v-btn
										class="mx-2 my-1"
										rounded
										color="#2D4B95"
										raised
										dark
										:loading="loading"
										@click="changeProject()"
									>
										<p class="mb-0">{{isClosing ? 'Cerrar Proyecto':'Reabrir Proyecto'}}</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {closeProject, openProject, getProjectsByUser, getClosedProjectsByUser} from  '@/helpers/api/project'

export default {
	name: 'CloseOpenProjectModal',
	computed: {
		isClosing() {
			return !this.closeOpenProjectModal.info || (this.closeOpenProjectModal.info && !this.closeOpenProjectModal.info.deleted_at)
		}
	},
	props: {
		closeModal: {
			type: Function,
		},
		closeOpenProjectModal: {
			type: Object
		}
	},
	data: () => ({
		loading: false,
		message: ''
	}),
	mounted(){
	},
	methods: {
		closeModalSelf(){
			this.$store.projectsStore.commit('setCloseOpenProjectModal', {status: false, info: null})
			this.loading = false
			this.message = ''
		},
		
		async changeProject(){
			this.loading = true
			let project = null
			if(this.isClosing){
				project = await closeProject({project_id: this.closeOpenProjectModal.info.id, reason: this.message})
			}
			else{
				project = await openProject({project_id: this.closeOpenProjectModal.info.id, reason: this.message})
			}
			if(project.code === 200 && project.project && project.project !== 0){
				const values = await Promise.all([getProjectsByUser(), getClosedProjectsByUser()])
				if(values[0] && values[0].code === 200){
					this.$store.projectsStore.commit('setProjectsList', values[0].projects)
				}
				if(values[1] && values[1].code === 200){
					this.$store.projectsStore.commit('setClosedProjectsList', values[1].projects)
				}
				this.closeModalSelf()
			}
			this.loading = false
		}
	}
};
</script>

<style scoped>
</style>