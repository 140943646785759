<template>
	<div
		class="reminders-container" 
		:class="$vuetify.breakpoint.mobile ? 'mobile':''"
		v-if="$store.notificationsStore.state.reminders.length > 0">
		<v-menu
			min-width="350"
			max-width="350"
			max-height="450"
			:bottom="!$vuetify.breakpoint.mobile"
			:top="$vuetify.breakpoint.mobile"
			offset-y
			left
			content-class="container-reminders-menu-header"
		>
			<template v-slot:activator="{ on: onMenu }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on: onTooltip }">
						<v-badge
							color="#2D4B95"
							:content="$store.notificationsStore.state.reminders.length"
							overlap
						>
							<v-btn
								fab
								dark
								small
								icon
								color="#5E85EF"
								class="mx-2"
								v-on="{...onMenu, ...onTooltip}"
							>
								<v-img :src="require('@/assets/Imgs/calendar.gif')"
									max-height="50"
									max-width="50"
									contain/>
							</v-btn>
						</v-badge>
					</template>
					<span>Recordatorios del día</span>
				</v-tooltip>
			</template>
			<div class="d-flex flex-row align-center justify-space-between py-2">
				<div class="d-flex flex-row px-4" style="border-left: 2px solid #2D4B95;">
					<v-icon dark color="#2D4B95" class="mr-5">mdi-calendar</v-icon>
					<p class="mb-0 font-weight-bold" style="color: #2D4B95;">Recordatorios del día</p>
				</div>
			</div>
			<v-divider class="mx-2"></v-divider>
			<v-list v-if="$store.notificationsStore.state.reminders.length > 0">
				<v-list-item
					v-for="(item, i) in $store.notificationsStore.state.reminders"
					:key="i"
          class="px-0 py-0"
          @click="viewItem(item.reminderable_id, item.item_type)"
				>
					<div style="width: 100%">
						<div class="d-flex flex-row px-4 pt-2 justify-space-between">
							<div class="d-flex flex-row">
								<img v-if="item.reminderable && item.reminderable.created_by_user_avatar && item.reminderable.created_by_user_avatar.avatar !== ''"
									:src="item.reminderable.created_by_user_avatar.avatar" class="notification-img"/>
								<v-icon v-else class="notification-img icon" color="#2D4B95" >mdi-account</v-icon>
								<div class="px-4 caption">
									<small class="mb-0" style="color: #707070;">{{ item.reminderable && item.reminderable.project && item.reminderable.project.name }}</small>
									<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{getType(item)}}: {{ item.reminderable.title }}</p>
								</div>
							</div>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
										<v-icon 
											v-bind="attrs"
											v-on="on"
											color="grey lighten-1"
											>mdi-chevron-double-right</v-icon>
								</template>
								<span>Visualizar</span>
							</v-tooltip>
						</div>
						<v-divider class="mt-2 mx-2"></v-divider>
					</div>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import {getTareaByID} from '@/helpers/api/tasks'
import {getRdiByID} from '@/helpers/api/rdi'
export default {
	name: 'Reminders',
	data() {
		return {
			options: {
				minimizable: false,
				playerSize: "standard",
				backgroundColor: '#fff',
				backgroundStyle: 'color',
				theme: {
					controlsView: "standard",
					active: "light",
					light: {
						color: '#3D4852',
						backgroundColor: '#fff',
						opacity: '0.7',
					},
					dark: {
						color: '#fff',
						backgroundColor: '#202020',
						opacity: '0.7',
					}
				}
			}
		}
	},
	methods: {
		getType(item){
			if(item.item_type === 1){
				return 'Tarea'
			}
			else if(item.item_type === 2){
				return 'RDI'
			}
			else if(item.item_type === 3){
				return 'Adicional'
			}
			return 'Otro'
		},
		async viewItem(item_id, item_type){
			if(item_type === 1){
				this.viewTarea(item_id)
			}
			else if(item_type === 2){
				this.viewRDI(item_id)
			}
		},
		async viewTarea(task_id){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		async viewRDI(rdi_id){
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				let rdiData = rdi.rdi
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
		},
	}
}
</script>

<style scoped>
	.container-reminders-menu-header{
		background: white;
		margin-top: 10px;
		border-radius: 8px;
	}
	.reminders-container{
		position: fixed;
		top: 50px;
		right: 10px;
	}
	.reminders-container.mobile{
		top: auto!important;
		bottom: 20px;
	}
	.notification-img{
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}
	.notification-img.icon{
		background-color: #F0F2F8;
	}
</style>