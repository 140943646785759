<template>
	<v-container class="d-flex flex-column align-start" fluid fill-height>
		<FullHeader :headerType="2"/>
		<ClosedProjectListIndex :listingType="$store.projectsStore.state.listingType" />
	</v-container>
</template>

<script>
import FullHeader from '../../Globals/Headers/FullHeader'
import ClosedProjectListIndex from './ClosedProjectListIndex'

export default {
	name: 'ClosedProjectsIndex',
	components: {FullHeader,ClosedProjectListIndex},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
