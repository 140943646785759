<template>
	<v-container class="d-flex flex-column justify-end alert-message-center-container pa-0"
		v-if="$store.globalStore.state.messagesToMessageCenter.length > 0">
		<div class="alert-message-center-inner-container py-1 pl-4 pr-8 mx-1 mb-1"
			:class="message.action ? 'clickable':''"
			v-for="(message, index) in $store.globalStore.state.messagesToMessageCenter"
			@click="sendAction(message)"
			:key="index">
			<v-icon class="mr-4" :color="message.color" large>{{message.icon}}</v-icon>
			<div>
				<p class="caption mb-0 text--secondary"
					v-if="message.project_id && $store.projectsStore.state.selectedProject.id != message.project_id">{{message.project}}</p>
				<p class="caption mb-0 light-blue-text font-weight-bold">{{message.title}}</p>
				<p class="mb-0 text--secondary caption">{{message.message}}</p>
			</div>
			<v-icon class="close-icon" color="#5E85EF" small @click.stop="closeMessage(message.uuid)">mdi-close-circle</v-icon>
		</div>
	</v-container>
</template>

<script>
import {getTareaByID} from '@/helpers/api/tasks'
export default {
	name: 'AlertMessageCenter',
	components: { },
	filters: {
	},
	data: () => ({}),
	mounted(){
	},
	methods: {
		closeMessage(uuid){
			this.$store.globalStore.commit('removeMessagesToMessageCenter', uuid)
		},
		sendAction(message){
			if(message.action == 'task'){
				this.viewTarea(message.task_id)
				this.closeMessage(message.uuid)
			}
		},
		async viewTarea(task_id){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = false
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		}
	}
};
</script>

<style scoped>
	.text-primary{color: #2D4B95;}
	.light-blue-text{color: #5E85EF;}
	.alert-message-center-container{
		position: fixed;
		top: 5%;
		right: 30px;
		max-width: 400px;
		z-index: 9999;
	}
	.alert-message-center-inner-container{
		position: relative;
		background-color: white;
		border-radius: 10px;
		border: 1px solid #5E85EF;
		display: flex;
	}
	.clickable{
		cursor: pointer;
	}
	.close-icon{
		position: absolute;
		top: 5px;
		right: 10px;
	}
</style>