

import {endpoints} from './endpoints'
import request from '../../request'
import axios from 'axios';

export function search(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.search,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getNotifications(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getNotifications,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function markNotificationAsReaded(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markNotificationAsReaded,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getReminders() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getReminders,
			method: 'post'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getSystemCurrencies() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSystemCurrencies,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setUserAvoidEmails(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setUserAvoidEmails,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getUserAvoidEmailsOptions(params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserAvoidEmailsOptions,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getWeather(address) {
	return new Promise((resolve, reject) => {
		axios.get(`https://api.openweathermap.org/data/2.5/forecast`, {
			params: {
				q: address,
				units: 'metric',
				lang: 'es',
				exclude: 'current,minutely,hourly,alerts',
				appid: 'c102528228a647b38e0684038aa90ac8'
			}
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getWeatherCoords(latitude, longitude) {
	return new Promise((resolve, reject) => {
		axios.get(`https://api.openweathermap.org/data/2.5/forecast`, {
			params: {
        lat: latitude,
        lon: longitude,
				units: 'metric',
				lang: 'es',
				exclude: 'current,minutely,hourly,alerts',
				appid: 'c102528228a647b38e0684038aa90ac8'
			}
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}