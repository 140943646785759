var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":"","id":"listado-consultas"}},[_c('v-card',{staticClass:"mb-2 py-1"},[_c('v-card-text',{staticClass:"header-table-card py-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","color":"#2D4B95","dark":"","small":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" Filtros ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{staticClass:"custom-button-consultas d-flex flex-row align-center justify-space-between",attrs:{"block":""},on:{"click":_vm.toggleOrder}},[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.getNameOrder()))]),_c('v-icon',{attrs:{"color":"#5e85ef","small":""}},[_vm._v(_vm._s(_vm.getIconOrder()))])],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"offset-y":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-button-consultas d-flex flex-row align-center justify-space-between",attrs:{"block":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Especialidad")]),_c('v-icon',{attrs:{"color":"#5e85ef","small":""}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.specialtyMenu),callback:function ($$v) {_vm.specialtyMenu=$$v},expression:"specialtyMenu"}},[_c('v-list',{staticClass:"filter-selector-list",attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 font-weight-bold caption text-primary-color text-capitalize"},[_vm._v("Filtrar Por:")]),_c('v-divider')],1)]),(_vm.$store.projectsStore.state.selectedProject)?_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.specialtyFilter),callback:function ($$v) {_vm.specialtyFilter=$$v},expression:"specialtyFilter"}},_vm._l((_vm.$store.projectsStore.state.selectedProject.specialties),function(user,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"auto"},attrs:{"value":user.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-title',{staticClass:"my-0 d-flex flex-row align-center"},[_c('v-checkbox',{staticClass:"caption my-0 py-0",attrs:{"dense":"","hide-details":"","input-value":active}}),_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(user.name))])],1)]}}],null,true)})}),1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('v-progress-circular',{attrs:{"size":20,"width":2,"color":"#2D4B95","indeterminate":""}})],1),_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","block":"","color":"#5e85ef","dark":""},on:{"click":function($event){return _vm.filterList()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-filter-outline")]),_c('p',{staticClass:"mb-0 caption text-capitalize"},[_vm._v("Aplicar")])],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"offset-y":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-button-consultas flex-row align-center justify-space-between",attrs:{"block":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Materia")]),_c('v-icon',{attrs:{"color":"#5e85ef","small":""}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.subjectMenu),callback:function ($$v) {_vm.subjectMenu=$$v},expression:"subjectMenu"}},[_c('v-list',{staticClass:"filter-selector-list",attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 font-weight-bold caption text-primary-color text-capitalize"},[_vm._v("Filtrar Por:")]),_c('v-divider')],1)]),(_vm.$store.projectsStore.state.projectSubjects)?_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.subjectFilter),callback:function ($$v) {_vm.subjectFilter=$$v},expression:"subjectFilter"}},_vm._l((_vm.$store.projectsStore.state.projectSubjects),function(subject,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"auto"},attrs:{"value":subject.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-title',{staticClass:"my-0 d-flex flex-row align-center"},[_c('v-checkbox',{staticClass:"caption my-0 py-0",attrs:{"dense":"","hide-details":"","input-value":active}}),_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(subject.name))])],1)]}}],null,true)})}),1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('v-progress-circular',{attrs:{"size":20,"width":2,"color":"#2D4B95","indeterminate":""}})],1),_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","block":"","color":"#5e85ef","dark":"","loading":!_vm.$store.projectsStore.state.projectSubjects},on:{"click":function($event){return _vm.filterList()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-filter-outline")]),_c('p',{staticClass:"mb-0 caption text-capitalize"},[_vm._v("Aplicar")])],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"offset-y":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-button-consultas flex-row align-center justify-space-between",attrs:{"block":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Responsable")]),_c('v-icon',{attrs:{"color":"#5e85ef","small":""}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.responsableMenu),callback:function ($$v) {_vm.responsableMenu=$$v},expression:"responsableMenu"}},[_c('v-list',{staticClass:"filter-selector-list",attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 font-weight-bold caption text-primary-color text-capitalize"},[_vm._v("Filtrar Por:")]),_c('v-divider')],1)]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.responsableFilter),callback:function ($$v) {_vm.responsableFilter=$$v},expression:"responsableFilter"}},_vm._l(([{value: 2, name: 'Jefe OT'}, {value: 1, name: 'ITO'}]),function(resp,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"auto"},attrs:{"value":resp.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-title',{staticClass:"my-0 d-flex flex-row align-center"},[_c('v-checkbox',{staticClass:"caption my-0 py-0",attrs:{"dense":"","hide-details":"","input-value":active}}),_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(resp.name))])],1)]}}],null,true)})}),1),_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","block":"","color":"#5e85ef","dark":""},on:{"click":function($event){return _vm.filterList()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-filter-outline")]),_c('p',{staticClass:"mb-0 caption text-capitalize"},[_vm._v("Aplicar")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"flex-row justify-center align-center"},[_c('div',{staticClass:"d-flex flex-row justify-end align-center py-0 icon-container float-total-icon"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-top total"},'div',attrs,false),on),[_c('span',{staticClass:"white--text caption"},[_vm._v(_vm._s(_vm.$store.rdiStore.state.listadoRDI.total_rdi))])])]}}])},[_c('span',[_vm._v("Total de RDI")])])],1)])])],1)],1),(_vm.$store.rdiStore.state.listadoRDI.status === 99)?_c('v-progress-linear',{staticClass:"listing-loading-bar",attrs:{"indeterminate":"","color":"#5e85ef"}}):_vm._e()],1),(_vm.$store.rdiStore.state.listadoRDI.status === 1 || _vm.$store.rdiStore.state.listadoRDI.count > 0)?_c('div',{staticClass:"listing-scroll"},[_vm._l((_vm.$store.rdiStore.state.listadoRDI.data),function(rdi,index){return _c('v-card',{key:index,staticClass:"tarea-card mb-2",staticStyle:{"overflow":"hidden"},on:{"click":function($event){$event.stopPropagation();_vm.viewRDI(rdi.id, _vm.$options.filters.seenByMe(rdi.seen_by_me))}}},[(rdi.private)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"incognito-container"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-incognito")])],1)]}}],null,true)},[_c('span',[_vm._v("Tarea privada: sólo la puedes ver tú y el responsable")])]):_vm._e(),_c('v-card-text',{staticClass:"header-table-card py-2",class:rdi.status === 3 ? 'status-ended':''},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row icon-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-top number"},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 white--text text-task-number caption"},[_vm._v(_vm._s(rdi.number))])])]}}],null,true)},[_c('span',[_vm._v("RDI número "+_vm._s(rdi.number))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top",class:_vm.getTareaPriorityColor(rdi),attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-exclamation")])]}}],null,true)},[_c('span',[_vm._v("Prioridad "+_vm._s(_vm.getTareaPriorityText(rdi)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"icon-top d-flex justify-center align-center",class:_vm.$options.filters.seenByMeColor(rdi.seen_by_me)},[_c('v-icon',_vm._g(_vm._b({class:_vm.$options.filters.seenByMeIconAnimation(rdi.seen_by_me),attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-bell")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.seenByMeText(rdi.seen_by_me))+" RDI")])])],1)])]),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"mb-0 light-blue-text caption"},[_vm._v("Especialidades")]),_vm._l((rdi.specialties),function(specialty,index){return _c('div',{key:index,staticClass:"light-blue-text caption font-weight-bold d-inline-block",staticStyle:{"margin-right":"1px"}},[_vm._v(" "+_vm._s(specialty.specialty.name)),(index + 1 < rdi.specialties.length)?_c('span',[_vm._v(",")]):_vm._e()])}),_c('v-divider'),_c('p',{staticClass:"mb-0 light-blue-text caption"},[_vm._v("Materia")]),_c('div',{staticClass:"d-flex flex-row justify-start align-center"},[_c('p',{staticClass:"mb-0 body-2 text--secondary caption"},[_vm._v(_vm._s(rdi.subject.name))])])],2),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column justify-start align-center"},[_c('p',{staticClass:"mb-0 body-2 text--secondary"},[_vm._v(_vm._s(rdi.title))]),_c('div',{staticClass:"mb-0 caption body-2 text--secondary text-rdi-field",staticStyle:{"width":"100%"},domProps:{"innerHTML":_vm._s(rdi.description)}})])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column justify-start align-center"},[(rdi.status != 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-column mr-1 rounded px-1"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex flex-row mr-1"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.responseStatus(rdi) ? '#fb8c00':'#3EBFA3'}},[_vm._v("mdi-account-outline")]),_c('p',{staticClass:"mb-0 font-weight-bold",class:_vm.responseStatus(rdi) ? 'medium':'normal'},[_vm._v(_vm._s(_vm._f("rdiResponsableUserType")(rdi.response_status)))])],1),(rdi.waitingresponsetime != 0)?_c('p',{staticClass:"d-flex mb-0 caption align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"#2D4B95"}},[_vm._v("mdi-clock-time-seven-outline")]),_vm._v(" "+_vm._s(rdi.waitingresponsetime)+" ")],1):_vm._e()])]}}],null,true)},[_c('span',[_vm._v("Responsable Actual - Hace: "+_vm._s(rdi.waitingresponsetime))])]):_c('div',{staticClass:"d-flex flex-row align-center mr-1"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#3EBFA3"}},[_vm._v("mdi-alert-circle-check")]),_c('p',{staticClass:"mb-0 font-weight-bold normal caption"},[_vm._v("Finalizada")])],1),_c('div',{staticClass:"status-container d-flex flex-row align-center mt-3"},[_c('v-img',{staticClass:"mr-1",attrs:{"src":_vm.setLights(rdi.lightstatus),"max-height":"50","max-width":"35","contain":""}}),_c('div',[_c('div',{staticClass:"d-flex flex-row mr-1 rounded"},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"#2D4B95"}},[_vm._v("mdi-calendar-range-outline")]),_c('p',{staticClass:"mb-0 text--secondary caption"},[_c('small',[_vm._v(_vm._s(_vm._f("toDate")(rdi.created_at))+" "+_vm._s(_vm._f("toHour")(rdi.created_at)))])])],1),(rdi.critical_date)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row mr-1 rounded px-1",class:_vm.checkIfNearDeadline(rdi) ? 'near-deadline':''},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.checkIfNearDeadline(rdi) ? 'white':'#2D4B95'}},[_vm._v("mdi-calendar-range-outline")]),_c('p',{staticClass:"mb-0 caption",class:_vm.checkIfNearDeadline(rdi) ? 'white--text':'text--secondary'},[_c('small',[_vm._v(_vm._s(_vm._f("toDate")(rdi.critical_date)))])])],1)]}}],null,true)},[_c('span',[_vm._v("Fecha crítica: "+_vm._s(_vm._f("toDate")(rdi.critical_date)))])]):_vm._e(),(rdi.ended_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row mr-1 rounded px-1 bg-normal mt-1"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"white"}},[_vm._v("mdi-calendar-range-outline")]),_c('p',{staticClass:"mb-0 caption white--text"},[_vm._v(_vm._s(_vm._f("toDate")(rdi.ended_at,'YYYY-MM-DD HH:mm:ss')))])],1)]}}],null,true)},[_c('span',[_vm._v("Fecha término: "+_vm._s(_vm._f("toDate")(rdi.ended_at,'YYYY-MM-DD HH:mm:ss')))])]):_vm._e()],1)],1)],1)])],1)],1)],1)}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"color":"#2D4B95","length":Math.ceil(_vm.$store.rdiStore.state.listadoRDITotal/50),"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],2):_c('div',_vm._l((5),function(i){return _c('v-card',{key:i,staticClass:"mb-2 py-2"},[_c('v-card-text',{staticClass:"py-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }