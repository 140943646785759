<template>
	<div class="text-center" v-if="addParticipantModal">
		<v-dialog
			v-model="addParticipantModal"
			max-width="500"
			scrollable
			persistent
		>
			<v-card>
				<v-card-text class="pa-0">
					<div class="py-4 px-8">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row">
								<p class="mb-0 font-weight-bold text-primary title">Agregar Participante</p>
							</div>
						</div>
						<v-row align="center" class="mt-2">
							<v-col cols="12">
								<p class="mb-2 text-primary">Selecciona a los usuarios que deseas agregar como participantes de la {{this.addingToType == 1 ? 'Tarea/Restricción':'RDI'}}</p>
								<v-combobox
									v-model="participants"
									:loading="$store.usersStore.state.loadingUserListForItems"
									:items="toShowUsers"
									:item-disabled="isPresentParticipant"
									item-text="name"
									item-value="id"
									single-line
									outlined
									dense
									required
									multiple
									label="Agregar Participante"
									taggable
								>
									<template v-slot:item="data">
										<v-list-item-avatar>
											<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
											<v-icon v-else>mdi-account</v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
											<v-list-item-subtitle>
												{{data.item.email}}
												<small class="text--disabled" v-if="isPresentParticipant(data.item)">- Ya participa en la tarea</small>
											</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-combobox>
							</v-col>
						</v-row>
						<v-row align="center">
							<v-col cols="12">
								<div class="d-flex flex-row justify-space-between">
									<v-btn
										class="mx-2 my-1"
										rounded
										color="white"
										raised
										:loading="loading"
										@click="closeModalSelf()"
									>
										<p class="mb-0 text-primary">Cancelar</p>
									</v-btn>
									<v-btn
										class="mx-2 my-1"
										rounded
										color="#2D4B95"
										raised
										dark
										:loading="loading"
										@click="addParticipants()"
									>
										<p class="mb-0">Aceptar</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {addParticipant} from '@/helpers/api/tasks'

export default {
	name: 'AddParticipantModal',
	props: {
		closeModal: {
			type: Function,
		},
		addParticipantModal: {
			type: Boolean,
			default: false
		},
		addingToType: {
			type: Number,
			default: 1 //1: Tarea , 2: RDI
		}
	},
	computed: {
		toShowUsers(){
			const usersToShow = this.$store.usersStore.state.usersListForItems.users
			return usersToShow
		}
	},
	watch: {
		addParticipantModal(newData) {
			if(newData){
				if(this.addingToType === 1){
					this.presentParticipants = this.$store.consultasStore.state.currentTarea.participants
					this.projectID = this.$store.consultasStore.state.currentTarea.project_id
				}
				else if(this.addingToType === 2){
					// this.presentParticipants = this.$store.consultasStore.state.currentRDI.participants
					this.projectID = this.$store.rdiStore.state.currentRDI.project_id
				}
				this.checkUsers()
			}
		}
	},
	data: () => ({
		loading: false,
		participants: null,
		presentParticipants: []
	}),
	mounted(){
	},
	methods: {
		isPresentParticipant(user){
			return this.presentParticipants.some((participant) => user.id === participant.id) ||
							this.$store.consultasStore.state.currentTarea.responsable === user.id ||
							this.$store.consultasStore.state.currentTarea.created_by === user.id
		},
		checkUsers(){
			if(!this.$store.usersStore.state.loadingUserListForItems &&
					this.$store.usersStore.state.usersListForItems.project_id !== this.projectID
				){
				this.getUserListforItems()
			}
		},
		getUserListforItems(){
			const data = {
				project_id: this.projectID,
				start: 0,
				limit: 9999
			}
			this.$store.usersStore.dispatch('getUsersListForItems', data)
		},
		closeModalSelf(){
			this.participants = null
			this.closeModal()
		},
		async addParticipants(){
			const participants = this.participants.map((participant) => participant.id)
			const data = {
				task_id: this.$store.consultasStore.state.currentTarea.id,
				participants: participants
			}
			this.loading = true
			const response = await addParticipant(data)
			this.loading = false
			if(response.code === 200){
				let tareaData = response.task
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
				this.closeModalSelf()
			}
		}
	}
};
</script>

<style scoped>
	.light-blue-text{color: #5E85EF;}
</style>