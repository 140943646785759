import projectStore from '@/stores/ProjectsStore'
import userStore from '@/stores/UserStore'

export function checkCompanyLevelPermissions(permissionsToCheck = []){
	let status = []
	const userPermissions = userStore.state.companyPermissions
	if(userPermissions){
		permissionsToCheck.forEach(element => {
			status.push(userPermissions[element])
		})
	}
	return status.length > 0 && status.every(elem => elem)
}

export function checkPermissions(permissionsToCheck = []){
	let status = []
	const userProjectPermissions = projectStore.state.projectPermissions
	if(userProjectPermissions){
		permissionsToCheck.forEach(element => {
			status.push(userProjectPermissions[element])
		})
	}
	return status.length > 0 && status.every(elem => elem)
}