var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[(_vm.$store.rdiStore.state.currentRDI.official_interactions.length == 0)?_c('div',{staticClass:"d-flex align-center justify-center finalization-not-allowed"},[_c('p',{staticClass:"mb-0 text-center text-primary",staticStyle:{"font-size":"10px","line-height":"10px"}},[_vm._v("No hay respuesta oficial, no se puede modificar estado de finalización")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn mx-1",attrs:{"plain":"","disabled":_vm.disabledButtons},on:{"click":function () { return _vm.preSetApprovalCheck(0); }}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center align-center text-center"},[_c('v-icon',{attrs:{"color":_vm.getColor(0),"large":""}},[_vm._v(_vm._s(_vm.getIcon(0)))]),_c('div',{staticClass:"custom-jobtype-text"},[_vm._v("Creador")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getName(0)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn mx-1",attrs:{"plain":"","disabled":_vm.disabledButtons},on:{"click":function () { return _vm.preSetApprovalCheck(2); }}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center align-center text-center"},[_c('v-icon',{attrs:{"color":_vm.getColor(2),"large":""}},[_vm._v(_vm._s(_vm.getIcon(2)))]),_c('div',{staticClass:"custom-jobtype-text"},[_vm._v("Jefe OT")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getName(2)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn mx-1",attrs:{"plain":"","disabled":_vm.disabledButtons},on:{"click":function () { return _vm.preSetApprovalCheck(4); }}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center align-center text-center"},[_c('v-icon',{attrs:{"color":_vm.getColor(4),"large":""}},[_vm._v(_vm._s(_vm.getIcon(4)))]),_c('div',{staticClass:"custom-jobtype-text"},[_vm._v("ITO")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getName(4)))])]),_c('AcceptCancelModal',{attrs:{"acceptRejectModal":_vm.acceptRejectModal,"acceptRejectModalData":_vm.acceptRejectModalData,"closeModal":_vm.closeModalAcceptCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }