import {endpoints} from './endpoints'
import request from '../request'

export function login (email, pass) {
	return new Promise((resolve, reject) => {
		const data = {
			email: email,
			password: pass
		}
		request({
			url: endpoints.login,
			method: 'post',
			data
		})
			.then(async function (response) {
				setUserToken(response)
				await getUser()
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

function setUserToken (token) {
	localStorage.setItem('userToken', token.access_token)
}

export function getUser() {
	return new Promise((resolve) => {
		request({
			url: endpoints.userInfo,
			method: 'post'
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getJobTypes(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getJobTypes,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function createUser(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.createUser,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateUser(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUser,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getUsersList(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.usersList,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function setCapabilitiesByUserProject(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.setCapabilitiesByUserProject,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getCapabilitiesByUserProject(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getCapabilitiesByUserProject,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function setCapabilitiesByUserCompany(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.setCapabilitiesByUserCompany,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getCapabilitiesByUserCompany(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getCapabilitiesByUserCompany,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function setUserProjects(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.setUserProjects,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function removeUserFromProject(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.removeUserFromProject,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getUserByEmail(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getUserByEmail,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function checkUserToken(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.checkFirstTokenAndChangePassword,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function saveUserProjectOptions(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.saveUserProjectOptions,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function resetPassRequest(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.resetPassRequest,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function checkToken(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.checkToken,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function resetPass(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.resetPass,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}