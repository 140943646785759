const endpoints = {
	create: '/additionals/create',
	list: '/additionals/list',
	listPaymentStatus: '/additionals/listPaymentStatus',
	getAdditionalByID: '/additionals/getAdditionalByID',
	addRevision: '/additionals/addRevision',
	createAdditionalLikely: '/additionals/createAdditionalLikely',
	listAdditionalLikely: '/additionals/listAdditionalLikely',
	getAdditionalLikely: '/additionals/getAdditionalLikely',
	getLastAdditionalData: '/additionals/getLastAdditionalData',
	newPaymentStatus: '/additionals/newPaymentStatus',
	updatePaymentStatus: '/additionals/updatePaymentStatus',
	setStatusToPaymentStatus: '/additionals/setStatusToPaymentStatus',
	newPaymentStatusInteraction: '/additionals/newPaymentStatusInteraction'
}
export {endpoints}