<template>
  <div class="text-center" v-if="$store.consultasStore.state.currentTarea && !$store.consultasStore.state.currentTarea.is_posible_additional">
    <v-dialog
      v-model="$store.consultasStore.state.currentTarea"
      max-width="800"
			scrollable
      persistent
    >
      <v-card v-if="$store.consultasStore.state.currentTarea.loading">
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Cargando Tarea</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row align="center" class="mt-2">
              <v-col cols="12" md="12">
                <div class="d-flex flex-column text-center align-center py-1" style="width: 100%">
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="#2D4B95"
                    indeterminate
                  ></v-progress-circular>
                  <p class="mb-0 body-2 ml-2">Cargando información</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
			</v-card>
			<v-card v-else>
				<v-card-title class="px-8">
					<div class="d-flex align-center justify-space-between header-modals" style="width: 100%;"
						:class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'"> <!-- Inicio Header -->
						<div class="d-flex flex-row align-center order-1 order-sm-0">
							<v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
							<div :class="$vuetify.breakpoint.mobile ? 'mt-2':''">
								<p class="mb-0 font-weight-bold text-primary title" :class="$vuetify.breakpoint.mobile ? 'body-2':''">{{$store.consultasStore.state.currentTarea.title}} - Nº {{$store.consultasStore.state.currentTarea.number}}</p>
								<p class="mb-0 text-primary body-1 text-wrap" style="word-break: break-word" :class="$vuetify.breakpoint.mobile ? 'caption d-flex flex-row align-center':''" v-if="$store.consultasStore.state.currentTarea.rdi_relation">
									RDI Asociada Nº {{$store.consultasStore.state.currentTarea.rdi_relation.rdi.number}} - {{$store.consultasStore.state.currentTarea.rdi_relation.rdi.title}}
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												fab
												dark
												x-small
												color="#5E85EF"
												class="ml-2"
												style="width: 25px; height: 25px;"
												v-bind="attrs"
												v-on="on"
												@click="viewTarea($store.consultasStore.state.currentTarea.rdi_relation.rdi.id, $options.filters.seenByMe($store.consultasStore.state.currentTarea.rdi_relation.rdi.seen_by_me))"
											>
												<v-icon dark x-small>mdi-arrow-right-bold</v-icon>
											</v-btn>
										</template>
										<span>Visualizar RDI</span>
									</v-tooltip>
								</p>
							</div>
						</div>
						<HeaderButtons :closeModal="closeModal"/>
					</div>
				</v-card-title>
        <v-card-text class="pa-0">
          <div class="pb-4 px-0">
						<div class="px-8 content-containera"> <!-- Inicio contenido -->
							<v-row align="center" class="mt-2">
								<v-col cols="12" md="6">
									<p class="mb-1"><b class="text-primary">Código:</b> {{$store.consultasStore.state.currentTarea.code ? $store.consultasStore.state.currentTarea.code.sku + ' - ' + $store.consultasStore.state.currentTarea.code.specialties:'No asignado'}}</p>
									<p class="mb-1"><b class="text-primary">Proyecto:</b> {{$store.consultasStore.state.currentTarea.project.name}}</p>
									<p class="mb-1"><b class="text-primary">Tarea Nº:</b> {{$store.consultasStore.state.currentTarea.number}}</p>
									<p class="mb-1"><b class="text-primary">Creador:</b> {{$store.consultasStore.state.currentTarea.created_by_user.name}}</p>
									<div class="d-flex flex-row align-center">
										<p class="mb-1"><b class="text-primary">Responsable:</b> {{$store.consultasStore.state.currentTarea.responsable_user.name}}</p>
										<v-menu offset-y v-if="checkPermissions(['change_responsable_task'])" @input="checkUsers()">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													fab
													dark
													x-small
													color="#5E85EF"
													class="info-icon ml-2"
													v-bind="attrs"
													v-on="on"
													:loading="loadingChangeUser"
												>
													<v-icon dark x-small>mdi-swap-horizontal-bold</v-icon>
												</v-btn>
											</template>
											<v-list dense class="py-0">
												<v-list-item dense class="mb-0" style="min-height: 25px; background: #5E85EF;">
													<p class="caption mb-0 font-weight-bold white--text">Cambiar Responsable</p>
												</v-list-item>
												<div v-if="$store.usersStore.state.loadingUserListForItems" class="d-flex flex-column align-center justify-center">
													<v-progress-linear
														indeterminate
														color="#5E85EF"
													></v-progress-linear>
													<v-icon class="mt-3 mb-0">mdi-account-group</v-icon>
													<p class="caption">Cargando usuarios</p>
												</div>
												<v-list-item-group v-else color="#5E85EF" class="overflow-y-auto mt-1" style="max-height: 300px">
													<v-list-item
														v-for="(user, index) in $store.usersStore.state.usersListForItems.users"
														v-show="$store.consultasStore.state.currentTarea.responsable != user.id"
														:key="index"
														dense
														class="px-0 py-0"
														style="width:100%"
														@click="changeUser(user.id)"
													>
														<div style="width:100%">
															<div class="d-flex flex-row align-center px-4 pt-2">
																<img :src="user.info.avatar" class="notification-img"/>
																<div class="px-4">
																	<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ user.name }} - <span style="color: #5E85EF;">{{user.projectjob.userjobtype.name}}</span></p>
																	<p class="mb-0 caption" style="color: #707070;">{{ user.email }}</p>
																</div>
															</div>
															<v-divider class="mt-2"></v-divider>
														</div>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</v-menu>
									</div>
									<div class="d-flex flex-row align-center"
										v-if="$store.consultasStore.state.currentTarea">
										<p class="mb-1"><b class="text-primary">Participantes</b></p>

										<v-menu offset-y :close-on-content-click="false">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													fab
													dark
													x-small
													color="#5E85EF"
													class="info-icon ml-2"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon dark x-small>mdi-eye</v-icon>
												</v-btn>
											</template>
											<v-list dense class="py-0">
												<v-list-item-group color="#5E85EF" class="overflow-y-auto" style="max-height: 300px">
													<v-list-item dense class="mb-0" style="min-height: 25px; background: #5E85EF;">
														<div class="d-flex flex-row align-center justify-space-between" style="width: 100%">
															<p class="caption mb-0 font-weight-bold white--text">Participantes</p>
															<v-btn
																icon
																dark
																x-small
																color="white"
																@click="addParticipantModal = true"
															>
																<v-icon dark>mdi-plus-circle</v-icon>
															</v-btn>
														</div>
													</v-list-item>
													<v-list-item
														v-if="$store.consultasStore.state.currentTarea.participants && $store.consultasStore.state.currentTarea.participants.length === 0"
														dense
														class="px-0 py-0"
														style="width:100%"
													>
														<div class="d-flex flex-column align-center justify-center pa-2">
															<v-icon class="my-1">mdi-close-octagon-outline</v-icon>
															<p class="body-2 caption mb-0">No se han agregado participantes aún</p>
														</div>
													</v-list-item>
													<v-list-item
														v-else
														v-for="(user, index) in $store.consultasStore.state.currentTarea.participants"
														v-show="$store.consultasStore.state.currentTarea.responsable != user.id"
														:key="index"
														dense
														class="px-0 py-0"
														style="width:100%"
													>
														<div style="width:100%">
															<div class="d-flex flex-row align-center justify-space-between px-4 pt-2">
																<div class="d-flex flex-row align-center">
																	<img :src="user.basic_info.avatar" class="notification-img"/>
																	<div class="px-4">
																		<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ user.name }}</p>
																		<p class="mb-0 caption" style="color: #707070;">{{ user.email }}</p>
																	</div>
																</div>
																<v-btn
																	icon
																	dark
																	x-small
																	color="#5E85EF"
																	class="ml-2"
																	:loading="loadingRemoveParticipant"
																	@click="removeParticipant(user)"
																>
																	<v-icon dark>mdi-delete-outline</v-icon>
																</v-btn>
															</div>
															<v-divider class="mt-2"></v-divider>
														</div>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</v-menu>
									</div>
								</v-col>
								<v-col cols="12" md="6">
									<div class="align-center d-flex mb-1">
										<p class="mb-0 mr-2"><b class="text-primary">Indicador:</b></p>
										<div class="d-flex flex-row align-center">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="white"
														class="icon-top success"
														v-if="$store.consultasStore.state.currentTarea.status == 3"
														v-bind="attrs"
														v-on="on"
														small>mdi-star</v-icon>
												</template>
												<span>Tarea finalizada el {{$store.consultasStore.state.currentTarea.ended_at | toDateTime}}</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="white"
														class="icon-top"
														:class="!$store.consultasStore.state.currentTarea.seen ? 'high':'success'"
														v-bind="attrs"
														v-on="on"
														small>mdi-check</v-icon>
												</template>
												<span>El responsable {{!$store.consultasStore.state.currentTarea.seen ? 'no':''}} ha visualizado esta tarea</span>
											</v-tooltip>
											<v-tooltip bottom v-if="checkIfNearDeadline($store.consultasStore.state.currentTarea)">
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="white"
														class="icon-top medium"
														v-bind="attrs"
														v-on="on"
														small>mdi-flag-outline</v-icon>
												</template>
												<span>Se acerca la fecha crítica {{$store.consultasStore.state.currentTarea.critical_date | toDate}}</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="white"
														class="icon-top dark-grey"
														v-if="$store.consultasStore.state.currentTarea.private"
														v-bind="attrs"
														v-on="on"
														small>mdi-incognito</v-icon>
												</template>
												<span>Tarea privada, sólo visible para el creador y el responsable</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="white"
														class="icon-top"
														v-bind="attrs"
														v-on="on"
														:class="getTareaPriorityColor($store.consultasStore.state.currentTarea)" small
														v-if="$store.consultasStore.state.currentTarea.waiting_status != 1">mdi-exclamation</v-icon>
												</template>
												<span>Prioridad {{getTareaPriorityText($store.consultasStore.state.currentTarea)}}</span>
											</v-tooltip>
										</div>
									</div>
									<p class="mb-1">
										<v-icon color="#2D4B95" class="mr-2">mdi-calendar-month-outline</v-icon> <b class="text-primary">Fecha Emisión:</b> {{$store.consultasStore.state.currentTarea.created_at | toDateTime}}
									</p>
									<p class="mb-1"
											v-if="$store.consultasStore.state.currentTarea.status == 3 && $store.consultasStore.state.currentTarea.ended_at">
										<v-icon color="#2D4B95" class="mr-2">mdi-calendar-month-outline</v-icon> <b class="text-primary">Finalizada:</b> {{$store.consultasStore.state.currentTarea.ended_at | toDateTime}}
									</p>
									<p class="mb-1" v-if="$store.consultasStore.state.currentTarea.critical_date">
										<v-icon color="#2D4B95" class="mr-2">mdi-calendar-month-outline</v-icon> <b class="text-primary">Fecha Crítica:</b> {{$store.consultasStore.state.currentTarea.critical_date | toDate}}
									</p>
									<p class="mb-1">
										<v-icon color="#2D4B95" class="mr-2">mdi-clock-time-eight-outline</v-icon> <b class="text-primary">Tiempo de espera:</b> {{$store.consultasStore.state.currentTarea.waitingtime}}
									</p>
								</v-col>
							</v-row>
							<v-row align="center" class="mt-2">
								<v-col cols="12">
									<p class="mb-2 title black--text">Descripción</p>
									<div v-html="$store.consultasStore.state.currentTarea.description"></div>
									<div v-if="$store.consultasStore.state.currentTarea.checklists.length > 0" class="mb-1">
										<h4>SubTareas</h4>
										<div class="parent-subtareas">
											<v-checkbox
												v-for="(subtarea, index) in $store.consultasStore.state.currentTarea.checklists"
												:key="index"
												v-model="subtarea.completed"
												:label="subtarea.description"
												dense
												hide-details
												class="mx-2 subtareas-checkbox"
												@change="changedChecklist"
											></v-checkbox>
										</div>
									</div>
									<MediaGallery
										v-if="$store.consultasStore.state.currentTarea.attachments.filter(item => item.file.pathmedia).length > 0"
										:images="$store.consultasStore.state.currentTarea.attachments.filter(item => item.file.pathmedia).map(item => item.file.pathmedia)" />
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row align="center" class="mt-2">
								<v-col cols="12">
									<div class="d-flex justify-space-between">
										<p class="mb-0 title black--text">Adjuntos</p>
										<v-btn
											v-if="$store.consultasStore.state.currentTarea.attachments.length > 1"
											class="mx-2 my-1"
											rounded
											color="#2D4B95"
											dark
											x-small
											:loading="loadingFilePath"
											@click="() => downloadAttachments($store.consultasStore.state.currentTarea.attachments)"
										>
											<v-icon dark small>mdi-paperclip</v-icon>
											<p class="mb-0 ml-2">Descargar Todo</p>
										</v-btn>
									</div>
									<div class="adjuntos-container"
										v-if="$store.consultasStore.state.currentTarea.attachments.filter(item => !item.file.pathmedia).length > 0">
										<div class="d-flex flex-row align-center justify-space-between my-2 py-1 px-2"
											:class="index%2 != 0 ? 'bg-dark-list':''"
											:key="index"
											v-for="(attachment, index) in $store.consultasStore.state.currentTarea.attachments.filter(item => !item.file.pathmedia)">
											<p class="mb-0">{{attachment.file.name}}</p>
											<p class="mb-0 text-primary">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															class="mx-1 my-1"
															color="#5E85EF"
															dark
															small
															icon
															:loading="loadingFilePath"
															v-bind="attrs"
															v-on="on"
															@click="getFilesUrl(attachment, true)"
														>
															<v-icon color="white" small class="info-icon small-icon">mdi-open-in-new</v-icon>
														</v-btn>
													</template>
													<span>Visualizar en navegador</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															class="mx-1 my-1"
															color="#5E85EF"
															dark
															x-small
															:icon="$vuetify.breakpoint.mobile"
															:loading="loadingFilePath"
															v-bind="attrs"
															v-on="on"
															@click="getFilesUrl(attachment, true, true)"
														>
															<v-icon color="white" small class="bg-light-blue mr-2" :class="$vuetify.breakpoint.mobile ? 'info-icon':''">mdi-cloud-download-outline</v-icon>
															<p class="mb-0" v-if="!$vuetify.breakpoint.mobile">Descargar</p>
														</v-btn>
													</template>
													<span>Descargar archivo</span>
												</v-tooltip>
											</p>
										</div>
									</div>
									<p v-else>No hay adjuntos</p>
								</v-col>
							</v-row>
							<v-row align="center" class="mt-2">
								<v-col cols="12">
									<div class="d-flex flex-row justify-space-between">
										<p class="mb-0 title black--text">Interacción</p>
										<div class="d-flex flex-row align-center">
											<v-text-field
													outlined
													clearable
													dense
													rounded
													hide-details
													label="Búsqueda"
													type="text"
													class="search-input"
													v-model="interactionSearch"
												>
													<template v-slot:prepend-inner>
														<v-btn
															class="search-button"
															fab
															dark
															small
															color="#5E85EF"
														>
															<v-icon dark>mdi-magnify</v-icon>
														</v-btn>
													</template>
													<template v-slot:label>
														<p class="mb-0 ml-5">Búsqueda</p>
													</template>
											</v-text-field>
											<v-btn
												v-if="checkPermissions(['create_rdi'])"
												class="mx-2 my-1"
												rounded
												color="#5E85EF"
												dark
												small
												@click="() => sendToRDI()"
											>
												<v-icon dark small>mdi-clipboard-text-multiple-outline</v-icon>
												<p class="mb-0 ml-2">Enviar a RDI</p>
											</v-btn>
											<!-- <v-btn
												class="mx-2 my-1"
												rounded
												color="#3EBFA3"
												dark
												small
												@click="() => console.log('Click')"
											>
												<v-icon dark small>mdi-paperclip</v-icon>
												<p class="mb-0 ml-2">Adicionales</p>
											</v-btn> -->
										</div>
									</div>
									<v-row align="center" class="mt-2"
										v-if="$store.consultasStore.state.currentTarea.interactions.length < 1">
										<v-col cols="12" class="text-center">
											No hay interacciones
										</v-col>
									</v-row>
									<Interactions
										:interactions="filteredSearch(this.$store.consultasStore.state.currentTarea.interactions)"
										:searchText="interactionSearch"
										/>
								</v-col>
							</v-row>
							<v-row align="center"
								v-if="$store.consultasStore.state.currentTarea.status != 3 && checkInteractPermission(['interact_tasks'])">
								<v-col cols="12">
									<v-btn-toggle
										v-model="response_type"
										mandatory
										color="#2D4B95"
									>
										<v-btn small @click="is_response = false" :class="$vuetify.breakpoint.mobile ? 'caption':''">Interacción</v-btn>
										<!-- <v-btn small @click="is_response = true" :class="$vuetify.breakpoint.mobile ? 'caption':''">Respuesta Oficial</v-btn> -->
									</v-btn-toggle>
									<tiptap-vuetify
										v-model="message"
										:extensions="extensions"
										id="interaction-texteditor"
									/>
									<v-alert type="warning" dense icon="mdi-alert-outline" class="mt-1" v-if="is_response">
										<div class="d-flex flex-row align-center">
											<p class="mb-0 caption mr-1">Estás respondiendo de forma oficial esta tarea, si sólo quieres realizar una interacción presiona el botón.</p>
											<v-btn
												color="white"
												outlined
												small
												@click="() => {
													is_response = false
													response_type = 0
												}"
											>
												Interacción
											</v-btn>
										</div>
									</v-alert>
								</v-col>
							</v-row>
							<v-row align="center" v-if="files.length > 0">
								<v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
									<div v-for="(file,index) in files" :key="index" class="ma-1" style="flex: 1 0 30%;">
										<div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
											<v-btn
												icon
												color="#5E85EF"
												x-small
												class="button-preview-img"
												@click="removeAttachment(index)"
											>
												<v-icon>mdi-close</v-icon>
											</v-btn>
											<img :src="filesPreview[index].src" class="preview-img"/>
										</div>
										<div v-else class="d-flex flex-row align-center justify-start">
											<v-btn
												icon
												color="#5E85EF"
												x-small
												@click="removeAttachment(index)"
											>
												<v-icon>mdi-close</v-icon>
											</v-btn>
											{{file.name}}
										</div>
									</div>
								</v-col>
							</v-row>
							<v-row align="center"
								v-if="$store.consultasStore.state.currentTarea.status != 3 && checkInteractPermission(['interact_tasks'])">
								<v-col cols="12">
									<div class="d-flex justify-space-between" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
										<v-btn
											class="mx-2 my-1"
											rounded
											color="#5E85EF"
											dark
											v-if="checkInteractPermission(['interact_tasks'])"
											:loading="loading"
											@click="preAddInteraction()"
										>
											<p class="mb-0">{{confirmButtonText}}</p>
										</v-btn>
										<div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
											<v-btn
												class="mx-2 my-1"
												rounded
												color="white"
												raised
												v-if="checkInteractPermission(['interact_tasks'])"
												@click="onButtonClick"
											>
												<p class="mb-0 text-primary">Adjuntar Archivo</p>
											</v-btn>
											<input
												ref="uploader"
												class="d-none"
												type="file"
												@change="onFileChanged"
											>
											<v-btn
												class="mx-2 my-1"
												rounded
												color="white"
												raised
												v-if="checkPermissions(['end_tasks']) || ( isMyTask() && $store.consultasStore.state.currentTarea.private )"
												@click="startCloseTasks"
											>
												<p class="mb-0 text-primary">Finalizar Tarea</p>
											</v-btn>
										</div>
									</div>
								</v-col>
							</v-row>
						</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AcceptCancelModal :acceptRejectModal="acceptRejectModal" :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel"/>
    <AddParticipantModal :addParticipantModal="addParticipantModal"
      :closeModal="closeAddParticipantModal"/>
  </div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import EventBus from '@/helpers/event_bus'
import AcceptCancelModal from '@/components/Globals/Modals/General/AcceptCancelModal'
import AddParticipantModal from '@/components/Globals/Modals/General/AddParticipantModal'
import {addInteraction, getTareaByID, getPublicUrl, closeTask, updateTask, changeResponsable, removeParticipant} from '@/helpers/api/tasks'
import {getRdiByID} from '@/helpers/api/rdi'
import {getZippedFiles} from '@/helpers/api/fileUploads'
import MediaGallery from '@/components/Globals/Helpers/MediaGallery'
import Interactions from '@/components/Globals/Helpers/Interactions'
import HeaderButtons from '@/components/Globals/Modals/Tasks/Parts/HeaderButtons'

export default {
  name: 'TareaModal',
  components: { TiptapVuetify, AcceptCancelModal, AddParticipantModal, MediaGallery, HeaderButtons, Interactions },
  computed: {
    currentTarea() {
      return this.$store.consultasStore.state.currentTarea
    }
  },
  watch: {
    currentTarea(newData) {
      if(newData && !newData.loading && newData.id){
        setTimeout(() => {
          this.pasteListener()
        }, 500);
      }
    }
  },
  data: () => ({
    dialog: true,
    showMore: false,
		interactionSearch: '',
    extensions: [
      History,
			// Table,
			// TableCell,
			// TableHeader,
			// TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    message: '',
    response_type: 0,
    need_reload: false,
    files: [],
    filesPreview: [],
    loadingFilePath: false,
    is_response: false,
    loading: false,
    changeInChecklists: false,
    loadingChangeUser: false,
    acceptRejectModal: false,
    acceptRejectModalData: null,
		addParticipantModal: false,
		loadingRemoveParticipant: false,
		loadingEdit: false,
		cancelTimer: 4,
		inCancelTime: false,
		cancelInterval: null,
		confirmButtonText: 'Responder'
  }),
  mounted(){
    this.setListeners()
  },
	beforeDestroy(){
		EventBus.$off('completedFileUpload')
	},
  methods: {
		checkUsers(){
			if(!this.$store.usersStore.state.loadingUserListForItems &&
					this.$store.usersStore.state.usersListForItems.project_id !== this.$store.consultasStore.state.currentTarea.project_id
				){
				this.getUserListforItems()
			}
		},
		getUserListforItems(){
			const data = {
				project_id: this.$store.consultasStore.state.currentTarea.project_id,
				start: 0,
				limit: 9999
			}
			this.$store.usersStore.dispatch('getUsersListForItems', data)
		},
		filteredSearch(items){
			if(this.interactionSearch && this.interactionSearch !== '' && items){
				const filtered = items.filter((item) => {
					return item.message.toLowerCase().includes(this.interactionSearch.toLowerCase())
				})
				return filtered
			}
			return items
		},
    checkInteractPermission(permissionToCheck){
      if(!this.checkPermissions(permissionToCheck)){
        return false
      }
      else{
        return this.checkPermissions(['interact_tasks_others']) || this.isUserPart()
      }
    },
    isUserPart(){
			const isCreator = this.$store.userStore.state.userInfo.id === this.$store.consultasStore.state.currentTarea.created_by_user.id
			const isResponsable = this.$store.userStore.state.userInfo.id === this.$store.consultasStore.state.currentTarea.responsable_user.id
			const isParticipant = this.$store.consultasStore.state.currentTarea.participants.some((participant) => {
				return participant.id === this.$store.userStore.state.userInfo.id
			})
      return isCreator || isResponsable || isParticipant
    },
    pasteListener(){
      const textEditor = document.querySelector('#interaction-texteditor .ProseMirror')
      if(textEditor){
        textEditor.addEventListener('paste', this.pasteListenerHandler)
      }
    },
		async pasteListenerHandler(event) {
			const dT = event.clipboardData || window.clipboardData
			const file = dT.files[0]
			if(file){
				this.files.push(file)
				if(file.type.includes('image')){
					const base64File = await this.toBase64(file)
					this.filesPreview.push({src: base64File})
				}
				else{
					this.filesPreview.push({noImage: true})
				}
			}
		},
    changedChecklist(){
      this.changeInChecklists = true
    },
    async updateTaskInfo(taskInfo){
      await updateTask(taskInfo)
    },
    setListeners(){
      EventBus.$on('completedFileUpload', async (payload) => {
        if(payload && payload.task_id && this.$store.consultasStore.state.currentTarea && payload.task_id == this.$store.consultasStore.state.currentTarea.id){
          const tarea = await getTareaByID({task_id: payload.task_id})
          if(tarea.code === 200){
            let tareaData = tarea.task
            this.$store.consultasStore.commit('setCurrentTarea', tareaData)
          }
        }
      })
    },
    setClassInteraction(interaction){
      let final_class = this.isMyInteraction(interaction.user) ? 'right':'left'
      final_class += interaction.is_response ? ' response':''
      return final_class
    },
		isMyTask(){
      return this.$store.consultasStore.state.currentTarea.created_by == this.$store.userStore.state.userInfo.id
		},
    isMyInteraction(interactionUser){
      return interactionUser.id == this.$store.userStore.state.userInfo.id
    },
    async getConsultasList(){
      let project_id = null
      if(this.$store.projectsStore.state.selectedProject){
        project_id = this.$store.projectsStore.state.selectedProject.id
      }
      else{
        const id = localStorage.getItem('selectedProjectID')
        project_id = parseInt(id)
      }
      if(project_id){
        this.$store.consultasStore.dispatch('getMyTasks', {project_id})
      }
    },
    getTareaPriorityColor(tarea){
      if(tarea.priority === 3){
        return 'high'
      }
      else if(tarea.priority === 2){
        return 'medium'
      }
      return 'normal'
    },
    getTareaPriorityText(tarea){
      if(tarea.priority === 3){
        return 'Inmediata'
      }
      else if(tarea.priority === 2){
        return 'Media'
      }
      return 'Estándar'
    },
    checkIfNearDeadline(tarea){
      if(!tarea.critical_date){ return false }
      const now = moment()
      const tarea_date = moment(tarea.critical_date)
      return tarea_date.diff(now, 'days') <= 2
    },
    getColorStatus(){
      if(this.$store.consultasStore.state.currentTarea.status === 1){
        return '#3EBFA3'
      }
      return '#F4516C'
    },
    closeModal(){
      const reload = this.need_reload || !this.$store.consultasStore.state.currentTarea.prev_seen_by_me
      if(this.changeInChecklists){
        const taskInfo = {...this.$store.consultasStore.state.currentTarea}
        this.updateTaskInfo(taskInfo)
      }
      this.$store.consultasStore.commit('setCurrentTarea', null)
      if(reload){
        this.getConsultasList()
      }
			this.changeInChecklists = false
      this.need_reload = false
      this.loading = false
      this.files = []
      this.filesPreview = []
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      const newFile = e.target.files[0]
      this.files.push(newFile)
      if(newFile.type.includes('image')){
        const base64File = await this.toBase64(newFile)
        this.filesPreview.push({src: base64File})
      }
      else{
        this.filesPreview.push({noImage: true})
      }
    },
    removeAttachment(index){
      this.files.splice(index, 1)
      this.filesPreview.splice(index, 1)
    },
    toBase64(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result)
        };
        reader.onerror = function () {
          resolve(null)
        };
      })
    },
    async getFilesUrl(attachments, singleFile = false, download = false){
      if(singleFile){ attachments = [attachments] }
      this.loadingFilePath = true
      const files = await getPublicUrl({attachments, force_download: download})
      if(files.code === 200){
        files.files.forEach((file) => {
          window.open(file.url);
        })
      }
      this.loadingFilePath = false
    },
    async preAddInteraction(){
			if(this.inCancelTime){
				clearInterval(this.cancelInterval)
				this.confirmButtonText = 'Confirmar'
				this.inCancelTime = false
				return null
			}
      if(this.message === '' || !this.message){
        const datas = {
         message: 'Por favor escribe un mensaje. Este campo no puede estar vacio.',
         title: 'Error',
          created: new Date(),
          type: 'success',
          icon: 'mdi-alert-circle',
          color: '#fb8c00'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
        return false
      }

			this.inCancelTime = true
			this.cancelTimer = 4
			this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
			this.cancelInterval = setInterval(() => {
				this.cancelTimer--
				this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
				if(this.cancelTimer == 0){
					this.inCancelTime = false
					this.confirmButtonText = 'Responder'
					clearInterval(this.cancelInterval)
					this.addInteraction()
				}
			}, 1000);
    },
    async addInteraction(){
      this.loading = true
      const biggerFiles = []
      const formData = new FormData()
      formData.append('task_id', this.$store.consultasStore.state.currentTarea.id)
      formData.append('message', this.message)
      formData.append('is_response', this.is_response)
      this.files.forEach((file) => {
        if(file && file.size < 1000){
          formData.append('files[]', file)
        }
        else if(file){
          biggerFiles.push({
            type: 2,
            payload: {'task_id': this.$store.consultasStore.state.currentTarea.id, 'project_id': this.$store.projectsStore.state.selectedProject.id},
            description: this.$store.consultasStore.state.currentTarea.title,
            total: file.size,
            uuid: uuidv4(),
            file
          })
        }
      })
      const interaction = await addInteraction(formData)
      if(interaction.code === 200){
        if(biggerFiles.length > 0){
          biggerFiles.map(file => {
            file.payload = Object.assign(file.payload, {tr_interaction_id: interaction.interaction, project_id: this.$store.projectsStore.state.selectedProject.id})
            this.$store.globalStore.commit('setFilesToUploadCenter', file)
          })
        }
        this.need_reload = true
        this.message = ''
        this.files = []
        this.filesPreview = []
        let tareaData = interaction.task
        this.$store.consultasStore.commit('setCurrentTarea', tareaData)
      }
      this.loading = false
    },
    closeModalAcceptCancel(status = false){
      this.acceptRejectModal = false
      if(status){
        this.closeTask()
      }
      this.acceptRejectModalData = null
    },
    startCloseTasks(){
      this.acceptRejectModalData = {
        title: '¿Cerrar la tarea Nº ' + this.$store.consultasStore.state.currentTarea.number + '?',
        content_a: 'Por favor confirma si deseas cerrar la tarea indicada.'
      }
      this.acceptRejectModal = true
    },
    async closeTask(){
      this.message += '<p><b>Consulta cerrada por usuario</b></p>'
      this.addInteraction()
      const task = await closeTask({task_id: this.$store.consultasStore.state.currentTarea.id})
      if(task.code === 200){
        let currentTarea = this.$store.consultasStore.state.currentTarea
        currentTarea.status = 3
        this.$store.consultasStore.commit('setCurrentTarea', currentTarea)
				this.$store.notificationsStore.dispatch('getReminders')
        this.$store.projectsStore.dispatch('getDashboardInfo')
        this.need_reload = true
        this.message = ''
        this.files = []
        this.filesPreview = []
      }
    },
    sendToRDI(){
      const data = {
        title: this.$store.consultasStore.state.currentTarea.title,
        description: this.$store.consultasStore.state.currentTarea.description,
        task_id: this.$store.consultasStore.state.currentTarea.id,
      }
      this.$store.rdiStore.commit('setInitialDataRDI', data)
      this.$store.rdiStore.commit('setCreateRDIModal', true)
    },
    async changeUser(userID){
      this.loadingChangeUser = true
      const data = {
        task_id: this.$store.consultasStore.state.currentTarea.id,
        user_id: userID
      }
      const taskUpdate = await changeResponsable(data)
      if(taskUpdate.code == 200){
        this.$store.consultasStore.commit('setCurrentTarea', taskUpdate.task)
        this.getConsultasList()
      }
      this.loadingChangeUser = false
    },
		async viewTarea(rdi_id, seen_by_me){
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				let rdiData = rdi.rdi
				rdiData.prev_seen_by_me = seen_by_me
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
		},
    async downloadAttachments(attachments){
      this.loadingFilePath = true
			const toZip = attachments.map(item => item.file)
      const files = await getZippedFiles({files_to_zip: toZip})
      if(files.code == 200){
          window.open(files.fileurl);
      }
      this.loadingFilePath = false
    },
    downloadFile(dataurl, filename) {
      var a = document.createElement("a")
      a.href = dataurl
      a.setAttribute("download", filename)
      a.setAttribute('target', '_blank')
      a.click()
    },
		closeAddParticipantModal(){
			this.addParticipantModal = false
		},
		async removeParticipant(participant){
			this.loadingRemoveParticipant = true
			const task = await removeParticipant({user_id: participant.id, task_id: participant.laravel_through_key})
			this.loadingRemoveParticipant = false
			if(task.code === 200){
				let tareaData = task.task
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		}
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
	.bg-primary{background-color: #2D4B95;}
	.bg-light-blue-text{background-color: #5E85EF;}
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 165px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .interaction-container.response{
    background-color: #CAE7CC;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .icon-top{
    padding: 2px;
    margin: 0 2px;
    border-radius: 100%;
  }
  .icon-top.high{
    background-color: #F4516C;
  }
  .icon-top.medium{
    background-color: #fb8c00;
  }
  .icon-top.normal{
    background-color: #3EBFA3;
  }
  .icon-top.primary{
    background-color: #2D4B95;
  }
  .icon-top.light-blue{
    background-color: #5E85EF;
  }
  .icon-top.dark-grey{
    background-color: #616161;
  }
  .parent-subtareas{
    display: flex;
    flex-wrap: wrap;
  }
  .parent-subtareas .subtareas-checkbox{
    flex: 1 0 30%;
  }
  .notification-img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
  .bg-dark-list{
    background-color: rgba(128, 128, 128, 0.15);
  }
	.user-avatar.mini{
		width: 30px;
		height: 30px;
		border-radius: 30px;
	}
	/* .header-modals{} */
	.search-input{
		position: relative;
		background-color: white;
		border-color: red;
		max-width: 200px;
		max-height: 30px;
	}
	/deep/ .search-input input{margin-left: 10px;padding: 0;line-height: 14px; font-size: 14px;}
	/deep/ .search-input .v-input__slot{min-height: 30px!important;}
	/deep/ .search-input.v-text-field--outlined.v-input--dense .v-label{top: 6px;font-size: 14px;}
	/deep/ .search-input.v-text-field--outlined.v-input--dense .v-input__append-inner{margin-top: 3px;}
	.search-button{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 32px;
		height: 32px;
	}
</style>