<template>
	<div class="d-flex" style="position: relative;">
		<div
			v-if="$store.rdiStore.state.currentRDI.official_interactions.length == 0"
			class="d-flex align-center justify-center finalization-not-allowed">
			<p class="mb-0 text-center text-primary" style="font-size: 10px;line-height: 10px;">No hay respuesta oficial, no se puede modificar estado de finalización</p>
		</div>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => preSetApprovalCheck(0)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(0)" large>{{getIcon(0)}}</v-icon>
						<div class="custom-jobtype-text">Creador</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(0)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => preSetApprovalCheck(2)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(2)" large>{{getIcon(2)}}</v-icon>
						<div class="custom-jobtype-text">Jefe OT</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(2)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => preSetApprovalCheck(4)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(4)" large>{{getIcon(4)}}</v-icon>
						<div class="custom-jobtype-text">ITO</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(4)}}</span>
		</v-tooltip>
    <AcceptCancelModal :acceptRejectModal="acceptRejectModal" :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel"/>
	</div>
	<!-- <v-speed-dial
		v-else
		v-model="fab"
		top
		class="custom-speed-dial"
	>
		<template v-slot:activator>
			<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" v-model="fab">
				<div class="d-flex flex-column justify-center align-center text-center">
					<v-icon v-if="fab" color="green" large>mdi-close</v-icon>
					<v-icon v-else color="green" large>mdi-check-circle-outline</v-icon>
				</div>
			</v-btn>
		</template>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => setApprovalCheck(0)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(0)" large>{{getIcon(0)}}</v-icon>
						<div class="custom-jobtype-text">Creador</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(0)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => setApprovalCheck(2)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(2)" large>{{getIcon(2)}}</v-icon>
						<div class="custom-jobtype-text">Jefe OT</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(2)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => setApprovalCheck(4)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(4)" large>{{getIcon(4)}}</v-icon>
						<div class="custom-jobtype-text">ITO</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(4)}}</span>
		</v-tooltip>
	</v-speed-dial> -->
</template>

<script>
import moment from 'moment'
import {setApprovalCheck, getRdiByID} from '@/helpers/api/rdi'
import {checkPermissions} from '@/helpers/permissions'
import AcceptCancelModal from '@/components/Globals/Modals/General/AcceptCancelModal.vue'

export default {
	name: 'ApprovalChecks',
	components: {AcceptCancelModal},
  computed: {
		disabledButtons(){
			return this.loading || this.$store.rdiStore.state.currentRDI.official_interactions.length == 0
		},
		checkApprovalChecks(){
			const creator = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
				return item.user_id == this.$store.rdiStore.state.currentRDI.created_by
			})
			const jefe_ot = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
				return item.job_type == 2
			})
			const ito = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
				return item.job_type == 4
			})
			return creator && jefe_ot && ito
		}
  },
	data: () => ({
		loading: false,
		fab: false,
    acceptRejectModal: false,
    acceptRejectModalData: null
	}),
	mounted(){
		// this.$store.projectsStore.state.selectedProject.job_type.id
	},
	methods: {
    closeModalAcceptCancel(status = false){
      this.acceptRejectModal = false
      if(status && this.acceptRejectModalData && this.acceptRejectModalData.type === 1){
        this.setApprovalCheck(this.acceptRejectModalData.job_type_id)
      }
      this.acceptRejectModalData = null
    },
		preSetApprovalCheck(job_type_id){
			if(!this.getCurrentStatus(job_type_id) && this.checkUserApproval(job_type_id)){
				this.acceptRejectModalData = {
					title: 'Vas a aprobar la RDI Nº ' + this.$store.rdiStore.state.currentRDI.number,
					content_a: 'Por favor confirma esta acción. Considera que después de aprobarla no podrás cambiar este estado.',
					content_b: 'La RDI sólo volverá al estado inicial cuando se genere una nueva respuesta oficial.',
					type: 1,
					job_type_id
				}
				this.acceptRejectModal = true
			}
			else if(this.getCurrentStatus(job_type_id)){
					const data = {
						message: 'No puedes retirar la aprobación.',
						title: 'Error',
						created: new Date(),
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C'
					}
					this.$store.globalStore.dispatch('addMessage', data)
					return false
			}
		},
		async setApprovalCheck(job_type_id){
			if(this.checkUserApproval(job_type_id)){
				const current_status = this.getCurrentStatus(job_type_id)
				const data = {
					rdi_id: this.$store.rdiStore.state.currentRDI.id,
					new_status: !current_status
				}
				this.loading = true
				await setApprovalCheck(data)

				const rdi = await getRdiByID({rdi_id: data.rdi_id})
				if(rdi.code === 200){
					const rdiData = rdi.rdi
					this.$store.rdiStore.commit('setCurrentRDI', rdiData)
				}
				this.loading = false
			}
		},
		checkUserApproval(job_type_id){
			if(!checkPermissions(['end_rdi'])){
					const data = {
						message: 'No posees permisos para cerrar RDIs',
						title: 'Error',
						created: new Date(),
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C'
					}
					this.$store.globalStore.dispatch('addMessage', data)
					return false
			}
			if(job_type_id == 0){
				//Creador
				const is_creator = this.$store.userStore.state.userInfo.id == this.$store.rdiStore.state.currentRDI.created_by
				if(!is_creator){
					const data = {
						message: 'No eres el creador de esta RDI',
						title: 'Error',
						created: new Date(),
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C'
					}
					this.$store.globalStore.dispatch('addMessage', data)
					return false
				}
				return true
			}
			
			const is_job = this.$store.projectsStore.state.selectedProject.job_type.id == job_type_id
			if(!is_job){
				const user_job_type = job_type_id == 2 ? 'Jefe OT':'ITO'
				const data = {
					message: 'En el proyecto de esta RDI no eres un usuario ' + user_job_type,
					title: 'Error',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C'
				}
				this.$store.globalStore.dispatch('addMessage', data)
				return false
			}
			return true
		},
		getCurrentStatus(job_type_id){
			if(job_type_id == 0){
				//Creador
				const present = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
					return item.user_id == this.$store.rdiStore.state.currentRDI.created_by
				})
				return present
			}
			
			const present = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
				return item.job_type == job_type_id
			})
			return present
		},
		getColor(job_type_id){
			if(job_type_id == 0){
				//Creador
				const present = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
					return item.user_id == this.$store.rdiStore.state.currentRDI.created_by
				})
				return present ? 'green':'#DD5050'
			}
			
			const present = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
				return item.job_type == job_type_id
			})
			return present ? 'green':'#DD5050'
		},
		getIcon(job_type_id){
			if(job_type_id == 0){
				//Creador
				const present = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
					return item.user_id == this.$store.rdiStore.state.currentRDI.created_by
				})
				return present ? 'mdi-check-circle-outline':'mdi-circle-outline'
			}
			
			const present = this.$store.rdiStore.state.currentRDI.approval_checks.some((item) => {
				return item.job_type == job_type_id
			})
			return present ? 'mdi-check-circle-outline':'mdi-circle-outline'
		},
		getName(job_type_id){
			if(job_type_id == 0){
				//Creador
				const user = this.$store.rdiStore.state.currentRDI.approval_checks.find((item) => {
					return item.user_id == this.$store.rdiStore.state.currentRDI.created_by
				})
				if(user){
					return user.user.name + ' - ' + moment(user.updated_at).format('DD/MM/YYYY HH:mm')
				}
				return '--'
			}
			
			const user = this.$store.rdiStore.state.currentRDI.approval_checks.find((item) => {
				return item.job_type == job_type_id
			})
			if(user){
					return user.user.name + ' - ' + moment(user.updated_at).format('DD/MM/YYYY HH:mm')
			}
			return '--'
		}
	}
};
</script>

<style scoped>
	.custom-btn,
	.custom-btn:disabled {
		height: auto !important;
		min-height: unset !important;
		min-width: unset !important;
		padding: 0 !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: transparent !important;
		box-shadow: none !important;
		border: none;
	}
	.custom-name-text{
		font-size: 8px;
		color: grey;
	}
	.custom-jobtype-text{
		font-size: 9px;
		color: grey;
	}
	.custom-btn.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content{
		opacity: 0.8;
	}
	.custom-speed-dial.v-speed-dial--top{
		top: 0;
	}
	.custom-speed-dial .v-speed-dial__list {
		background-color: white !important; /* Fondo blanco */
		border-radius: 50%; /* Opcional: redondea los bordes */
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Opcional: añade sombra */
	}
	.finalization-not-allowed{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.6);
		z-index: 99;
	}
</style>