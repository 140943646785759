var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paymentStatus)?_c('div',{staticClass:"payment-status-container pa-2",style:(_vm.disabled ? 'opacity: 0.5':'')},[_c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"caption"},'div',attrs,false),on),[_c('div',{staticClass:"font-weight-bold"},[_c('span',{class:_vm.statusColorClass(_vm.paymentStatus)},[_vm._v(_vm._s(_vm.paymentStatus.statustext))])]),_c('div',{staticClass:"d-flex flex-row align-center mr-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"#2D4B95","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-progress-check")])]}}],null,true)},[_c('span',[_vm._v("Porcentaje de avance informado")])]),_c('v-progress-linear',{attrs:{"value":_vm.paymentStatus.progress*100,"color":"primary","height":"12","rounded":""}},[_c('small',{class:_vm.paymentStatus.progress < 0.43 ? '':'white--text'},[_vm._v(_vm._s(Math.ceil(_vm.paymentStatus.progress*100))+"%")])])],1),_c('div',{staticClass:"d-flex flex-row align-center mt-1 mr-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"#2D4B95","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd")])]}}],null,true)},[_c('span',[_vm._v("Monto negociado para el estado de pago")])]),_c('p',{staticClass:"mb-0 mr-2 normal font-weight-bold"},[_vm._v(_vm._s(_vm.additional.last_revision.currency_info.code)+"$"+_vm._s(_vm.paymentStatus.amount))]),_c('v-progress-circular',{attrs:{"size":20,"width":6,"value":_vm.paymentStatus.amount*100/_vm.additional.last_revision.total_requested,"color":"#3EBFA3"}})],1)])]}}],null,false,735844122)},[_c('PaymentStatusInteraction',{attrs:{"paymentStatus":_vm.paymentStatus,"additional":_vm.additional}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }