<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.usersStore.state.editUserProjectsModal"
      max-width="1000"
      scrollable
      persistent
    >
      <v-card :loading="$store.usersStore.state.loadingJobTypes || loading_user_projects" :disabled="$store.usersStore.state.loadingJobTypes || loading_user_projects">
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-account-plus-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Editar Proyectos Usuario: {{$store.usersStore.state.selectedUserInfo.name}}</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-col cols="12" class="py-0">
                <h2 class="mb-0 body-2">Elimina o agrega los proyectos que tiene asignado el usuario.</h2>
            </v-col>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row>
                <v-col cols="12" sm="4" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Tus Proyectos</h2>
                  <div class="d-flex flex-row">
                    <v-select
                      v-model="user_projects"
                      :items="$store.projectsStore.state.projectsList"
                      :loading="!$store.projectsStore.state.projectsList"
                      item-text="name"
                      item-value="id"
                      label="Seleccione Proyecto"
                      single-line
                      hint="Selecciona los proyectos para asignar al usuario"
                      persistent-hint
                      outlined
                      multiple
                      dense
                      required
                      return-object
                      @change="setNewProject"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12" sm="8" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Proyectos Asignados al Usuario</h2>
                  <div v-for="(item, index) in user_projects" :key="index" class="my-2">
                    <div class="d-flex justify-space-between align-start" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
                      <div class="d-flex flex-row align-center" :class="$vuetify.breakpoint.mobile ? 'mb-2':''">
                        <v-icon class="mr-2" color="#5E85EF" small @click.stop="removeProject(index)">mdi-close-circle</v-icon>
                        <p class="mb-0 font-weight-bold light-blue-text">{{item.name}}</p>
                      </div>
                      <div :style="$vuetify.breakpoint.mobile ? 'width: 100%;':''">
                        <v-select
                          v-model="item.job"
                          :items="$store.usersStore.state.jobTypes"
                          item-text="name"
                          item-value="id"
                          label="Sin cambio"
                          hint="Cargo que se asignará al usuario en este proyecto."
                          persistent-hint
                          single-line
                          outlined
                          dense
                          required
                          :rules="requiredRule"
                          style="width: 300px;"
                        ></v-select>
                      </div>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#2D4B95"
                      raised
                      dark
                      :loading="loadingData"
                      @click="setUserProjects"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {setUserProjects} from '@/helpers/api/user'
import {getProjectsByUserID, getProjectsByUser} from '@/helpers/api/project'

export default {
  name: 'EditUserProjectsModal',
  data: () => ({
    validform: true,
    user_projects: [],
    loading_user_projects: true,
    loading_project_list: false,
    loadingData: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
  }),
  mounted(){
    const projectID = this.$store.projectsStore.state.selectedProject.id
    this.$store.usersStore.dispatch('jobTypesGet', projectID)
    this.getUserProjects()
  },
  methods: {
    setNewProject(){
      let default_job = 2
      if(this.$store.usersStore.state.selectedUserInfo && this.$store.usersStore.state.selectedUserInfo.info && this.$store.usersStore.state.selectedUserInfo.info.default_job){
        default_job = this.$store.usersStore.state.selectedUserInfo.info.default_job
      }
      this.user_projects.map((project) => {
        if(!project.job){
          project.job = default_job
        }
      })
    },
    removeProject(index){
      let projects = [...this.user_projects]
      projects.splice(index, 1)
      this.user_projects = projects
    },
    async setUserProjects(){
      const data = {
        user_id: this.$store.usersStore.state.selectedUserInfo.id,
        user_projects: this.user_projects
      }
      const user_projects = await setUserProjects(data)
      if(user_projects.code == 200){
        const datas = {
          message: 'Se han actualizado los proyectos asociados al usuario.',
          title: 'Usuario Actualizado',
          created: new Date(),
          type: 'success',
          icon: 'mdi-check-circle',
          color: '#3EBFA3'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
      }
      else{
        const datas = {
          message: 'Hubo un error al actualizar los datos, por favor intenta nuevamente.',
          title: 'Error',
          created: new Date(),
          type: 'success',
          icon: 'mdi-alert-circle',
          color: '#fb8c00'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
      }
    },
    async getProjectList(){
      if(!this.$store.projectsStore.state.projectsList){
        this.loading_project_list = true
        const projects = await getProjectsByUser()
        if(projects.code === 200){
          this.$store.projectsStore.commit('setProjectsList', projects.projects)
        }
        this.loading_project_list = false
      }
    },
    setJobTypeSelected(select){
      if(select && select.default_capabilities){
        this.permisos = select.default_capabilities
      }
    },
    async getUserProjects(){
      this.loading_user_projects = true
      const projects = await getProjectsByUserID({user_id: this.$store.usersStore.state.selectedUserInfo.id})
      if(projects.code == 200){
        let projectsData = [...projects.projects]
        projectsData.map((project) => {
          project.job = project.userjobtype.job_type_id
        })
        this.user_projects = projectsData
      }
      this.loading_user_projects = false
    },
    closeModal(){
      this.$store.usersStore.commit('setSelectedUserInfo', null)
      this.$store.usersStore.commit('setEditUserProjectsModal', false)
      this.permisos = {
        create_users: false,
        edit_users: false,
        create_tasks: false,
        assign_responsable_task: false,
        change_responsable_task: false,
        view_tasks_listing: false,
        view_personal_tasks: false,
        interact_tasks: false,
        interact_tasks_others: false,
        end_tasks: false,
        create_rdi: false,
        view_rdi: false,
        interact_rdi: false,
        end_rdi: false,
      }
      this.validform = true
      this.loadingData = false
    }
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .permisos-type-container{
    border: 1px solid #5E85EF;
    border-radius: 10px;
    padding: 10px;
  }
  /deep/ .permisos-type-container .v-input--checkbox label.v-label{font-size: 14px;}
</style>