<template>
	<v-container fluid pa-0 fill-height>
		<v-card class="fill-height" style="width: 100%;">
			<v-card-text>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="5">
						<p class="mb-0 font-weight-bold">{{info.title}}</p>
						<p class="mb-0">Velocidad de Solución Promedio</p>
						<p class="mb-0 font-weight-bold">Por Día = {{info.speed_solution.value}}</p>
					</v-col>
					<v-col cols="7">
						<p class="mb-0 font-weight-bold">{{info.total_title}} <v-icon :color="info.general_status" small>mdi-record-circle</v-icon></p>
						<v-row>
							<v-col cols="3" class="px-1" v-for="(graph, index) in info.circle_graphs" :key="index">
								<DoughnutChart
									:chartdata="{
										datasets: [
											{
												backgroundColor: [graph.color,'#D9D9D9'],
												data: [graph.value, 100 - graph.value],
											} 
										]
									}"
									:options="{
										tooltips: {enabled: false},
										cutoutPercentage: 75,
										responsive: true,
										maintainAspectRatio: true,
										elements: {
											center: {
												text: ''
											}
										}
									}"/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row class="project-detail-card" v-else>
					<v-col cols="5">
						<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
						<v-skeleton-loader type="heading"></v-skeleton-loader>
					</v-col>
					<v-col cols="7">
						<v-skeleton-loader type="list-item"></v-skeleton-loader>
						<v-skeleton-loader type="avatar,avatar,avatar,avatar" class="d-flex"></v-skeleton-loader>
					</v-col>
				</v-row>
				<v-divider class="my-3"></v-divider>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="5">
						<p class="mb-0">Tiempo de Respuesta</p>
						<p class="mb-0 font-weight-bold">Por Día = {{info.response_time.value}}</p>
					</v-col>
					<v-col cols="7">
						<LineChart
							:height="170"
							:chartdata="info.line_graph"
							:options="{
								legend: {display: false},
								tooltips: {enabled: false},
								responsive: true,
								maintainAspectRatio: true,
								scales : {
										xAxes: [{ gridLines: {display: false} }],
										yAxes: [{
												stacked: true,
												ticks: {
													min: 0,
													stepSize: 10,
												}
										}]
								}
							}"/>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import DoughnutChart from '@/components/Charts/Doughnut'
import LineChart from '@/components/Charts/Line'
export default {
	name: 'ProjectDetailCardAvance',
	components: {DoughnutChart, LineChart},
	props: ['info'],
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>

<style scoped>
	.project-detail-card, .project-detail-card p{
		color: #2D4B95;
	}
</style>