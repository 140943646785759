<template>
	<v-container class="d-flex flex-column align-start" fluid fill-height>
		<FullHeader :headerType="99" :actionButtonsType="2"/>
		<UsersList />
	</v-container>
</template>

<script>
import FullHeader from '../../Globals/Headers/FullHeader'
import UsersList from './UsersList'

export default {
	name: 'UsersIndex',
	components: {FullHeader, UsersList},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
