import {endpoints} from './endpoints'
import request from '../request'

export function create(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCompany,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompaniesByUserID(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompaniesByUserID,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}