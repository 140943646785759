<template>
	<v-container fluid pa-0>
		<v-row align="center" no-gutters
			v-if="$store.projectsStore.state.selectedProject == 1">
				<div class="info-container d-flex flex-column text-center align-center py-1" style="width: 100%">
					<v-progress-circular
						:size="20"
						:width="2"
						color="#2D4B95"
						indeterminate
					></v-progress-circular>
					<p class="mb-0 body-2 ml-2">Cargando información del proyecto</p>
				</div>
		</v-row>
		<v-row align="center" no-gutters
			v-else-if="!$store.projectsStore.state.selectedProject">
				<div class="info-container d-flex flex-column text-center align-center py-1" style="width: 100%">
					<p class="mb-0 body-2 ml-2">No hay proyectos seleccionados</p>
					<p class="mb-0 body-2 ml-2 caption">Crea tu primer proyecto</p>
					<v-btn
						class="mx-2 my-1"
						rounded
						color="#34BFA3"
						dark
						x-small
						@click="() => $store.projectsStore.commit('setNewProjectModal', true)"
					>
						<v-icon dark small>mdi-square-edit-outline</v-icon>
						<p class="mb-0 ml-2">Nuevo Proyecto</p>
					</v-btn>
				</div>
		</v-row>
		<v-row align="center" no-gutters v-else-if="$store.projectsStore.state.selectedProject">
			<v-col cols="6" md="5">
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-office-building-outline</v-icon>
					<p class="mb-0 body-2 ml-2"><b>Proyecto:</b> {{$store.projectsStore.state.selectedProject.name}}</p>
				</div>
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-clipboard-minus-outline</v-icon>
					<p class="mb-0 body-2 ml-2"><b>{{$store.projectsStore.state.selectedProject.description}}</b></p>
				</div>
			</v-col>
			<v-col cols="6" md="4">
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-domain</v-icon>
					<p class="mb-0 body-2 ml-2"><b>Empresa:</b> {{$store.projectsStore.state.selectedProject.company.name}}</p>
				</div>
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-developer-board</v-icon>
					<p class="mb-0 body-2 ml-2"><b>Tipo:</b> {{$store.projectsStore.state.selectedProject.type.name}}</p>
				</div>
			</v-col>
			<v-col sm="0" md="3">
				<div class="d-flex align-center py-1">
					<img :src="$store.projectsStore.state.selectedProject.company.logo" class="info-img" />
				</div>
			</v-col>
		</v-row>
		<v-row align="center" no-gutters v-else>
			<v-col cols="6" md="5">
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-office-building-outline</v-icon>
					<v-skeleton-loader type="list-item" class="skeleton-loader-custom"></v-skeleton-loader>
				</div>
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-clipboard-minus-outline</v-icon>
					<v-skeleton-loader type="list-item" class="skeleton-loader-custom"></v-skeleton-loader>
				</div>
			</v-col>
			<v-col cols="6" md="4">
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-domain</v-icon>
					<v-skeleton-loader type="list-item" class="skeleton-loader-custom"></v-skeleton-loader>
				</div>
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-developer-board</v-icon>
					<v-skeleton-loader type="list-item" class="skeleton-loader-custom"></v-skeleton-loader>
				</div>
			</v-col>
			<v-col cols="0" md="3">
				<div class="d-flex align-center py-1">
					<v-skeleton-loader type="button" class="skeleton-loader-custom"></v-skeleton-loader>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

export default {
	name: 'HeaderProjectInfo',
	components: {
	},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>

<style scoped>
	.info-container{
		color: #606060;
	}
	.info-container b{
		color: #2D4B95;
	}
	.info-icon{
		background-color: #2D4B95;
		border-radius: 25px;
		padding: 3px;
		width: 25px;
		height: 25px;
	}
	.info-img{
		width: 100%;
		max-height: 50px;
		object-fit: contain;
	}
	.skeleton-loader-custom{width: 100%;}
	/deep/ .skeleton-loader-custom > div.v-skeleton-loader__list-item{background-color: transparent!important;}
</style>
