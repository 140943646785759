import {endpoints} from './endpoints'
import request from '../../request'

export function create(data, onUploadProgress = null) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.create,
			method: 'post',
			data,
			onUploadProgress
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function list(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.list,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function listPaymentStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listPaymentStatus,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getAdditionalByID(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAdditionalByID,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addRevision(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addRevision,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createAdditionalLikely(data, onUploadProgress = null) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createAdditionalLikely,
			method: 'post',
			data,
			onUploadProgress
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function listAdditionalLikely(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listAdditionalLikely,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getAdditionalLikely(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAdditionalLikely,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function changeAdditionalStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.changeAdditionalStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getLastAdditionalData(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getLastAdditionalData,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function newPaymentStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.newPaymentStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updatePaymentStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updatePaymentStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setStatusToPaymentStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setStatusToPaymentStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function newPaymentStatusInteraction(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.newPaymentStatusInteraction,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}