import {endpoints} from './endpoints'
import request from '../../request'

export function forgeGetToken(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.forgeGetToken,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function forgeGetFiles(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.forgeGetFiles,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setProjectMainModel(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setProjectMainModel,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectMainModel(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectMainModel,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteProjectMainModel(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteProjectMainModel,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}