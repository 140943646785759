import Vue from 'vue'
import Vuex from 'vuex'
import {forgeGetToken, forgeGetFiles} from '@/helpers/api/forge'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		forgeToken: null,
		loadingToken: true,
		forgeFiles: [],
		loadingFiles: true,
		forgeSelectedFile: null,
		projectMainFile: null,
		projectMainModel: null
	},
	mutations: {
		setForgeSelectedFile(state, file){
			state.forgeSelectedFile = file
		},
		setForgeToken(state, token){
			state.forgeToken = token
		},
		setLoadingToken(state, status){
			state.loadingToken = status
		},
		setForgeFiles(state, files){
			state.forgeFiles = files
		},
		setLoadingFiles(state, status){
			state.loadingFiles = status
		},
		setProjectMainFile(state, file){
			state.projectMainFile = file
		},
		setProjectMainModel(state, file){
			state.projectMainModel = file
		}
	},
	actions: {
		async fetchForgeToken(context, scopes){
			context.commit('setLoadingToken', true)
			context.commit('setForgeToken', null)

			const forgeToken = await forgeGetToken({scopes})
			if(forgeToken.code == 200 && forgeToken.access_token && forgeToken.access_token.applicationToken){
				context.commit('setForgeToken', forgeToken.access_token.applicationToken)
			}

			context.commit('setLoadingToken', false)
		},
		async fetchForgeFiles(context, data){
			context.commit('setLoadingFiles', true)
			context.commit('setForgeFiles', [])
			context.commit('setProjectMainModel', null)

			const files = await forgeGetFiles(data)
			context.commit('setLoadingFiles', false)
			if(files.code == 200 && files.forge_files){
				context.commit('setForgeFiles', files.forge_files)
				context.commit('setProjectMainFile', files.project_main_file)
				let main_file = null
				if(files.project_main_file){
					main_file = files.forge_files.find((file) => {
						return files.project_main_file.file_id == file.id
					})
				}
				context.commit('setProjectMainModel', main_file)

				if(!context.state.forgeSelectedFile && files.forge_files.length > 0){
					if(!main_file){
						main_file = files.forge_files[0]
					}
					context.commit('setForgeSelectedFile', main_file)
				}
			}
			return new Promise((resolve) => {
				resolve(true)
			})
		}
	},
})

export default store