import menuStore from './MenuStore.js'
import userStore from './UserStore.js'
import usersStore from './UsersStore.js'
import projectsStore from './ProjectsStore.js'
import notificationsStore from './NotificationsStore.js'
import consultasStore from './ConsultasStore.js'
import rdiStore from './RdiStore.js'
import adicionalesStore from './AdicionalesStore.js'
import globalStore from './GlobalStore.js'
import generalStore from './GeneralStore.js'
import fileManagerStore from './FileManagerStore.js'
import forgeStore from './ForgeStore.js'

const store = {
	menuStore,
	generalStore,
	userStore,
	projectsStore,
	usersStore,
	notificationsStore,
	consultasStore,
	globalStore,
	rdiStore,
	adicionalesStore,
	fileManagerStore,
	forgeStore,
}

export default store