<template>
	<v-container fluid>
		<v-row align="stretch">
			<v-col cols="12" sm="5">
				<v-card class="fill-height">
					<ForgeViewer style="min-height: 240px" class="pa-0"
										v-if="$store.forgeStore.state.projectMainModel"/>
					<v-img
						height="200"
						v-else-if="$store.projectsStore.state.selectedProject"
						:src="$store.projectsStore.state.selectedProject.project_image"
					>
						<v-icon dark class="icon-general-project-status">mdi-information-outline</v-icon>
					</v-img>
					<v-card-text class="pa-0">
						<v-divider></v-divider>
						<div class="my-2 px-2">
							<p class="mb-0 font-weight-bold text-primary">
								<v-icon small color="#2D4B95" class="mr-1">mdi-file-cog-outline</v-icon> Almacenamiento Utilizado:
							</p>
							<v-skeleton-loader type="list-item" v-if="$store.fileManagerStore.state.loadingStorageInfo"></v-skeleton-loader>
							<v-progress-linear
								v-else
								:value="$store.fileManagerStore.state.projectStorageInfo.total_size*100/10737418240"
								class="my-1"
								rounded
								color="#2D4B95"
								background-color="#787878"
								height="18"
							>
								<template v-slot:default>
									<p class="white--text mb-0 font-weight-bold">{{$store.fileManagerStore.state.projectStorageInfo.total_size | toDataSize}}<small>/10 GB</small></p>
								</template>
							</v-progress-linear>
						</div>
						<div v-if="editingProjectBoard">
							<tiptap-vuetify
								v-model="projectStatus"
								:extensions="extensions"
								required
								id="edit-project-status"
							/>
							<div class="d-flex flex-row justify-space-between my-2">
								<v-btn
									class="mx-2 my-1"
									rounded
									color="white"
									small
									v-if="checkPermissions(['end_rdi'])"
									:loading="loading"
									@click="editingProjectBoard = false"
								>
									<p class="mb-0 text-primary">Cancelar</p>
								</v-btn>
								<v-btn
									class="mx-2 my-1"
									rounded
									color="#5E85EF"
									dark
									small
									v-if="checkPermissions(['end_rdi'])"
									:loading="loading"
									@click="updateBoard()"
								>
									<p class="mb-0">Actualizar</p>
								</v-btn>
							</div>
						</div>
						<div v-else>
							<v-divider></v-divider>
							<div class="d-flex flex-row justify-end">
								<v-btn text class="pa-0" style="height: auto;"
									v-if="checkCompanyLevelPermissions(['edit_project'])"
									@click="editProjectBoard()">
									<v-icon color="#5e85ef" small class="mr-1">mdi-note-edit</v-icon>
									<p class="mb-0 caption text-primary text-capitalize">Editar</p>
								</v-btn>
							</div>
							<div
								class="pa-2 status-board-info"
								v-if="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject.status_board"
								v-html="$store.projectsStore.state.selectedProject.status_board.status_board">
							</div>
						</div>
					</v-card-text>

					<!-- <v-card-text>
						<v-btn
							color="#EB4964"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/gestiondocumentos.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Gestión de Documentos</p>
							</div>
						</v-btn>
						<v-btn
							color="#32B99E"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/programacompras.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Programa de Compras</p>
							</div>
						</v-btn>
						<v-btn
							color="#E29E38"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/subcontrato.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Programa de Sub-contratos</p>
							</div>
						</v-btn>
						<v-btn
							color="#2D51DC"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/bodega.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Bodega</p>
							</div>
						</v-btn>
						<v-btn
							color="#E6E277"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/programa.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Programación</p>
							</div>
						</v-btn>
					</v-card-text> -->
				</v-card>
			</v-col>
			<v-col cols="12" sm="5">
				<div class="d-flex flex-column align-space-between justify-space-between fill-height">
					<ProjectDetailCardConsultas :info="$store.projectsStore.state.selectedConsultasInfo"/>
					<ProjectDetailCardRDI :info="$store.projectsStore.state.selectedRdiInfo"/>
				</div>
			</v-col>
			<v-col cols="12" sm="2">
				<v-card>
					<v-card-title class="pb-2 mb-2" style="border-bottom: 1px solid #cacaca;">
						<div style="width: 100%;">
							<div class="d-flex">
								<v-icon color="#2D4B95">mdi-clouds</v-icon>
								<div class="ml-2">
									<p class="mb-0 body-2">Clima</p>
									<p class="mb-0 font-weight-bold body-2" style="color: grey;">{{weather_city ? weather_city:$store.projectsStore.state.selectedProject.address}}</p>
								</div>
							</div>
						</div>
					</v-card-title>
					<v-card-text class="pa-2">
						<v-row v-if="$store.projectsStore.state.selectedProject.address">
							<v-col cols="12" v-for="(weather, index) in dailyWeather" :key="index">
								<div class="d-flex flex-column align-center justify-center" v-if="index == 0">
									<div class="d-flex">
										<div class="d-flex flex-column align-center justify-center mx-3">
											<p class="mb-0 font-weight-bold caption">{{weather.day}}</p>
											<p class="mb-0 caption">{{weather.date}}</p>
										</div>
										<v-img :src="require('@/assets/Imgs/weather/' + weather.weather_icon)"
											max-height="70"
											max-width="70"
											contain/>
									</div>
									<p class="mb-0 font-weight-bold text-primary text-h4">{{weather.maxTemp}}º/{{weather.minTemp}}º</p>
								</div>
								<div class="d-flex align-center justify-center" v-else>
									<v-img :src="require('@/assets/Imgs/weather/' + weather.weather_icon)"
										max-height="50"
										max-width="50"
										contain/>
									<div class="d-flex flex-column align-center justify-center mx-3">
										<p class="mb-0 font-weight-bold caption">{{weather.day}}</p>
										<p class="mb-0 caption">{{weather.date}}</p>
										<p class="mb-0 font-weight-bold text-primary text-h6">{{weather.maxTemp}}º/{{weather.minTemp}}º</p>
									</div>
								</div>
							</v-col>
						</v-row>
						<v-row v-else>
							<v-col cols="12">
								<div class="d-flex flex-column align-center justify-center pa-0">
									<p class="mb-0 ml-3 mr-5 body-2" v-if="checkCompanyLevelPermissions(['edit_project'])">Para obtener un reporte del clima por favor ingresa la ciudad o comuna en la que se encuentra el proyecto</p>
									<p class="mb-0 ml-3 mr-5 body-2" v-else>Para obtener un reporte del clima por favor solicítale al encargado que agregue la ciudad o comuna al proyecto</p>
									<v-btn
										class="mx-2 my-1"
										rounded
										color="#5E85EF"
										dark
										x-small
										v-if="checkCompanyLevelPermissions(['edit_project'])"
										@click="() => $store.projectsStore.commit('setEditProjectModal', true)"
									>
										<v-icon dark small>mdi-square-edit-outline</v-icon>
										<p class="mb-0 ml-2 font">Editar Proyecto</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row align="start">
			<v-col cols="12" sm="6">
				<ProjectDetailCardAdicionales :info="$store.projectsStore.state.selectedAdicionalesInfo"/>
				<!-- <v-container fluid pa-0 fill-height>
					<v-card fill-height style="width: 100%;" class="pa-0">
						<v-card-text class="pa-0">
							<p class="px-4 py-2 mb-0 font-weight-bold" style="color: #2D4B95">Modelo principal del proyecto</p>
							<ForgeViewer style="min-height: 240px" class="pa-0" :hideControls="true"
								v-if="$store.forgeStore.state.projectMainModel"/>
							<div class="pa-4 d-flex justify-center align-acenter" style="min-height: 240px"
								v-else>
								No hay modelo principal asignado
							</div>
						</v-card-text>
					</v-card>
				</v-container> -->
			</v-col>
			<v-col cols="12" sm="6">
				<ProjectDetailCardAvance :info="$store.projectsStore.state.selectedAvancesInfo"/>
			</v-col>
		</v-row>
		<v-row align="start">
			<v-col cols="12">
				<ProjectListItem v-for="(project, index) in $store.projectsStore.state.projectsList" :info="project" :projectIndex="index" :key="index"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import ProjectDetailCardConsultas from './ProjectDetailCardConsultas'
import ProjectDetailCardAvance from './ProjectDetailCardAvance'
import ProjectDetailCardAdicionales from './ProjectDetailCardAdicionales'
import ProjectDetailCardRDI from './ProjectDetailCardRDI'
import ProjectListItem from '@/components/Views/Projects/ProjectListItem'
import Chart from "chart.js"
import {updateProjectBoard} from '@/helpers/api/project'
import {getWeather, getWeatherCoords} from '@/helpers/api/general'
import moment from 'moment'
import 'moment/locale/es'
import ForgeViewer from '../../Planimetria/ForgeViewer.vue'

export default {
	name: 'ProjectDetail',
	components: {ProjectDetailCardAvance, ProjectDetailCardConsultas, ProjectDetailCardAdicionales, ProjectDetailCardRDI, ProjectListItem, TiptapVuetify, ForgeViewer},
	computed: {
		selectedProject() {
			return this.$store.projectsStore.state.selectedProject
		}
	},
	watch: {
		selectedProject(newData, oldData) {
			if(newData != oldData){
				this.textCenter()
				this.getWeatherCoords()
				this.setSelectedModel()
			}
		}
	},
	data: () => ({
		loading: false,
		weather: null,
		weather_city: null,
		projectStatus: '',
		editingProjectBoard: false,
    extensions: [
      History,
			// Table,
			// TableCell,
			// TableHeader,
			// TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
		dailyWeather: null,
		weatherIcons: {
			'Clear': 'sunny.png',
			'Clouds': 'cloudy.png',
			'Drizzle': 'rain.png',
			'Rain': 'rain.png',
			'Thunderstorm': 'storm.png',
			'Snow': 'snow.png',
			'Mist': 'cloudy.png',
			'Smoke': 'cloudy.png',
			'Haze': 'cloudy.png',
			'Dust': 'cloudy.png',
			'Fog': 'cloudy.png',
			'Sand': 'cloudy.png',
			'Ash': 'cloudy.png',
			'Squall': 'cloudy.png',
			'Tornado': 'storm.png',
		}
	}),
	mounted(){
		this.textCenter()
		this.getWeatherCoords()
		this.setSelectedModel()
	},
	methods: {
		async setSelectedModel(){
			await this.$store.forgeStore.dispatch('fetchForgeFiles', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
			if(this.$store.forgeStore.state.projectMainModel){
				this.$store.forgeStore.commit('setForgeSelectedFile', this.$store.forgeStore.state.projectMainModel)
			}
		},
		async getWeatherA(){
			if(this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.address){
				try {
					const response = await getWeather(this.$store.projectsStore.state.selectedProject.address)
					if(response.status == 200){
						const dailyTemperatures = {};
						this.weather = response.data.list
						this.weather.forEach(item => {
							const date = new Date(item.dt * 1000); // Convertir la fecha Unix a objeto Date
							const day = date.toISOString().split('T')[0]; // Obtener la fecha en formato YYYY-MM-DD

							if (!dailyTemperatures[day]) {
								// Inicializar el objeto para el día si no existe
								dailyTemperatures[day] = {
									maxTemp: -Infinity,
									minTemp: Infinity,
									weather: '',
									weather_icon: ''
								};
							}

							// Actualizar las temperaturas máximas y mínimas para el día actual
							if (item.main.temp_max > dailyTemperatures[day].maxTemp) {
								dailyTemperatures[day].maxTemp = item.main.temp_max;
							}
							if (item.main.temp_min < dailyTemperatures[day].minTemp) {
								dailyTemperatures[day].minTemp = item.main.temp_min;
							}
							dailyTemperatures[day].weather = item.weather[0].description; // Condición meteorológica principal
							dailyTemperatures[day].weather_icon = this.weatherIcons[item.weather[0].main];
						});

					// Convertir el objeto en un array de objetos para facilitar el manejo posterior
					const dailyTemperaturesArray = Object.keys(dailyTemperatures).map(day => ({
						date: day,
						day: moment(day).locale('es').format('dddd'),
						maxTemp: Math.round(dailyTemperatures[day].maxTemp),
						minTemp: Math.round(dailyTemperatures[day].minTemp),
						weather: dailyTemperatures[day].weather,
						weather_icon: dailyTemperatures[day].weather_icon
					}));

					this.dailyWeather = dailyTemperaturesArray.slice(0, 5)
					}
				} catch (error) {
					console.error(error)
				}
			}
			else{
				this.weather = null
				this.dailyWeather = null
			}
		},
		async getWeatherCoords(){
			if(this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.geo){
				try {
					const response = await getWeatherCoords(
						this.$store.projectsStore.state.selectedProject.geo.latitude,
						this.$store.projectsStore.state.selectedProject.geo.longitude
					)
					if(response.status == 200){
						const dailyTemperatures = {};
						this.weather_city = response.data.city.name
						this.weather = response.data.list
						this.weather.forEach(item => {
							const date = new Date(item.dt * 1000); // Convertir la fecha Unix a objeto Date
							const day = date.toISOString().split('T')[0]; // Obtener la fecha en formato YYYY-MM-DD

							if (!dailyTemperatures[day]) {
								// Inicializar el objeto para el día si no existe
								dailyTemperatures[day] = {
									maxTemp: -Infinity,
									minTemp: Infinity,
									weather: '',
									weather_icon: ''
								};
							}

							// Actualizar las temperaturas máximas y mínimas para el día actual
							if (item.main.temp_max > dailyTemperatures[day].maxTemp) {
								dailyTemperatures[day].maxTemp = item.main.temp_max;
							}
							if (item.main.temp_min < dailyTemperatures[day].minTemp) {
								dailyTemperatures[day].minTemp = item.main.temp_min;
							}
							dailyTemperatures[day].weather = item.weather[0].description; // Condición meteorológica principal
							dailyTemperatures[day].weather_icon = this.weatherIcons[item.weather[0].main];
						});

					// Convertir el objeto en un array de objetos para facilitar el manejo posterior
					const dailyTemperaturesArray = Object.keys(dailyTemperatures).map(day => ({
						date: day,
						day: moment(day).locale('es').format('dddd'),
						maxTemp: Math.round(dailyTemperatures[day].maxTemp),
						minTemp: Math.round(dailyTemperatures[day].minTemp),
						weather: dailyTemperatures[day].weather,
						weather_icon: dailyTemperatures[day].weather_icon
					}));

					this.dailyWeather = dailyTemperaturesArray.slice(0, 5)

					console.log('Temperaturas diarias:', dailyTemperaturesArray);
					}
				} catch (error) {
					console.error(error)
				}
			}
			else{
				this.weather = null
				this.dailyWeather = null
			}
		},
    processData(data) {
      const dailyData = {};

      data.forEach(item => {
        const date = item.dt_txt.split(' ')[0];
        if (!dailyData[date]) {
          dailyData[date] = {
            temp: [],
            description: item.weather[0].description
          };
        }
        dailyData[date].temp.push(item.main.temp);
      });

      return Object.keys(dailyData).map(date => {
        const temps = dailyData[date].temp;
        const avgTemp = (temps.reduce((sum, temp) => sum + temp, 0) / temps.length).toFixed(1);
        return {
          date: new Date(date).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
          }),
          temp: avgTemp,
          description: dailyData[date].description
        };
      });
    },
		editProjectBoard(){
			if(this.$store.projectsStore.state.selectedProject.status_board){
				this.projectStatus = this.$store.projectsStore.state.selectedProject.status_board.status_board
			}
			this.editingProjectBoard = true
		},
		async updateBoard(){
			this.loading = true
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				status_board: this.projectStatus,
			}
			const board = await updateProjectBoard(data)
			if(board.code == 200){
				let project = {...this.$store.projectsStore.state.selectedProject}
				project.status_board = board.status
				this.$store.projectsStore.commit('setSelectedProject', project)
			}
			this.editingProjectBoard = false
			this.loading = false
		},
		textCenter(){
			Chart.pluginService.register({
				beforeDraw: function(chart) {
					var width = chart.chart.width;
					var height = chart.chart.height;
					var ctx = chart.chart.ctx;

					ctx.restore();
					var fontSize = (height / 50).toFixed(2);
					ctx.font = fontSize + "em sans-serif";
					ctx.textBaseline = "middle";

					let text = ''
					if(chart.config.options.elements && chart.config.options.elements.center && chart.config.options.elements.center.text){
						text = chart.config.options.elements.center.text
					}
					var textX = Math.round((width - ctx.measureText(text).width) / 2);
					var textY = height / 1.8;

					ctx.fillText(text, textX, textY);
					ctx.save();
				}
			});
		}
	}
};
</script>

<style>
  .text-primary{
    color: #2D4B95;
  }
	.button-project-detail-card{
		border-radius: 8px;
		position: relative;
		z-index: 1;
		height: auto!important;
	}
	.button-project-detail-card:after{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(2,0,36,0.45) 0%, rgba(0,0,0,0) 100%);
		z-index: 2;
	}
	.button-project-detail-card span.v-btn__content{z-index: 3;}
	.icon-general-project-status{
		position: absolute;
		top: 5px;
		left: 5px;
		padding: 2px;
		background-color: #34BFA3;
		border-radius: 100%;
		border: 2px solid white;
	}
	#edit-project-status .tiptap-vuetify-editor__content{
		min-height: 150px;
		padding: 0;
	}
	#edit-project-status .tiptap-vuetify-editor__content .ProseMirror{
		padding: 15px;
    margin: 0!important;
    height: 100%;
    min-height: 150px;
	}
	#edit-project-status .tiptap-vuetify-editor__content .ProseMirror p{
		margin-top: 0!important;
		margin-bottom: 0!important;
	}
	.status-board-info p{
		margin-bottom: 0;
	}
	.status-board-info table{
		width: 100%;
		border-collapse: collapse;
		margin: 5px 0px;
	}
	.status-board-info table td{
		border: 1px solid grey;
		padding: 4px;
	}
</style>
