var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-end align-center order-0 order-sm-1",staticStyle:{"min-width":"168px"}},[(_vm.checkPermissions(['change_priority_task']) || _vm.checkPermissions(['change_critical_date_task']))?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"#5E85EF"}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-clipboard-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Modificar Adicional")])])]}}],null,false,1106577646)},[_c('div',{staticStyle:{"background":"white"}},[_c('div',{staticClass:"bg-light-blue-text py-1 px-2"},[_c('p',{staticClass:"caption mb-0 font-weight-bold white--text"},[_vm._v("Modificar Adicional")])]),_c('div',{staticClass:"pa-2"},[(_vm.checkPermissions(['change_critical_date_task']))?_c('div',[_c('p',{staticClass:"mt-2 mb-0 caption light-blue-text font-weight-bold"},[_vm._v("Agregar/modificar Fecha Crítica")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"remove-bottom-details date-limit-input mt-2",attrs:{"value":_vm.editRdiForm.critical_date,"label":"Fecha Crítica","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":""},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}],null,false,2831507266),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.datePicker = false}},model:{value:(_vm.editRdiForm.critical_date),callback:function ($$v) {_vm.$set(_vm.editRdiForm, "critical_date", $$v)},expression:"editRdiForm.critical_date"}})],1)],1):_vm._e(),_c('div',[_c('p',{staticClass:"mt-2 mb-0 caption light-blue-text font-weight-bold"},[_vm._v(" Estado Visualización "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#5E85EF","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1281959473)},[_c('span',[_vm._v("Maneja con que estado quieres ver la campana asociada a esta tarea.")])])],1),_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"success","outlined":_vm.$options.filters.seenByMeColor(_vm.currentAdicional.seen_by_me) !== 'success',"loading":_vm.isLoadingChangeVisualizationStatus(_vm.currentAdicional.id)},on:{"click":function($event){$event.stopPropagation();return _vm.changeVisualizationStatus(1)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-bell")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"warning","outlined":_vm.$options.filters.seenByMeColor(_vm.currentAdicional.seen_by_me) !== 'warning',"loading":_vm.isLoadingChangeVisualizationStatus(_vm.currentAdicional.id)},on:{"click":function($event){$event.stopPropagation();return _vm.changeVisualizationStatus(2)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-bell")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"high","outlined":_vm.$options.filters.seenByMeColor(_vm.currentAdicional.seen_by_me) !== 'high',"loading":_vm.isLoadingChangeVisualizationStatus(_vm.currentAdicional.id)},on:{"click":function($event){$event.stopPropagation();return _vm.changeVisualizationStatus(0)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-bell")])],1)],1)]),_c('div',[_c('p',{staticClass:"mt-2 mb-0 caption light-blue-text font-weight-bold"},[_vm._v(" Suscribir a Adicional "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#5E85EF","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1281959473)},[_c('span',[_vm._v("Si te suscribes a un Adicional recibirás notificaciones en tu correo cuando ocurran cambios importantes: Revisiones, Fechas, Cierre. ")])])],1),_c('div',[_c('v-switch',{staticClass:"my-0",attrs:{"dense":"","hide-details":""},on:{"change":_vm.changeInSubscription},model:{value:(_vm.editRdiForm.subscribe_status),callback:function ($$v) {_vm.$set(_vm.editRdiForm, "subscribe_status", $$v)},expression:"editRdiForm.subscribe_status"}},[_c('template',{slot:"label"},[_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(_vm.editRdiForm.subscribe_status ? 'Recibirás notificaciones':'No recibirás notificaciones'))])])],2),_c('v-switch',{staticClass:"my-0",attrs:{"dense":"","disabled":!_vm.editRdiForm.subscribe_status,"hide-details":""},model:{value:(_vm.editRdiForm.subscribe_status_mail),callback:function ($$v) {_vm.$set(_vm.editRdiForm, "subscribe_status_mail", $$v)},expression:"editRdiForm.subscribe_status_mail"}},[_c('template',{slot:"label"},[_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(_vm.editRdiForm.subscribe_status_mail ? 'Recibirás correos de esta RDI':'No recibirás correos de esta RDI'))])])],2)],1)]),_c('div',[_c('p',{staticClass:"mt-2 mb-0 caption light-blue-text font-weight-bold"},[_vm._v(" Recordatorio "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#5E85EF","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1281959473)},[_c('span',[_vm._v("Selecciona uno o más días de la semana en los que quieras que te recordemos esta tarea. Aparecerá un recordatorio al ingresar a tu panel LaOT.")])])],1),_c('div',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","block":"","color":"#5E85EF"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-clipboard-edit-outline")]),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v("Seleccionar Días")])],1)]}}],null,false,3354652941),model:{value:(_vm.datePickerRemider),callback:function ($$v) {_vm.datePickerRemider=$$v},expression:"datePickerRemider"}},[_c('div',{staticClass:"grey lighten-4 pa-2"},[_vm._l((_vm.weekDays),function(day,index){return _c('v-btn',{key:index,staticClass:"mx-1",attrs:{"dark":"","x-small":"","color":"#5E85EF","outlined":!_vm.isWeekDaySelected(index)},on:{"click":function($event){return _vm.addWeekDay(index)}}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(day))])])}),_c('v-switch',{staticClass:"my-1",attrs:{"dense":"","disabled":this.editRdiForm.reminder.length === 0,"hide-details":""},model:{value:(_vm.editRdiForm.reminder_repeatable),callback:function ($$v) {_vm.$set(_vm.editRdiForm, "reminder_repeatable", $$v)},expression:"editRdiForm.reminder_repeatable"}},[_c('template',{slot:"label"},[_c('p',{staticClass:"mb-0 caption"},[_vm._v("Recordar Semanalmente")])])],2),_c('p',{staticClass:"mb-1 caption text--secondary"},[_vm._v("Recuerda confirmar para que este recordatorio tome efecto")])],2)])],1)]),_c('v-divider',{staticClass:"my-2"}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"#2D4B95","raised":"","small":"","block":"","dark":"","loading":_vm.loadingEdit},on:{"click":function () { return _vm.modifyRdi(); }}},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v("Confirmar")])]),(_vm.isMyRdi())?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"#F4516C","outlined":"","x-small":"","block":"","dark":""},on:{"click":function () { return _vm.startDeleteRdi(); }}},[_c('p',{staticClass:"mb-0"},[_vm._v("Eliminar")])]):_vm._e()],1)])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":"#2D4B95"},on:{"click":function($event){_vm.sendByEmailModal = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-email")])],1)]}}])},[_c('span',[_vm._v("Enviar por correo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":"#2D4B95","loading":_vm.loadingPDFDownload},on:{"click":_vm.downloadPDF}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Descargar en PDF")])]),_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":"#fb8c00"},on:{"click":_vm.minimizeRdi}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-window-minimize")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","small":"","color":"#F4516C"},on:{"click":_vm.closeModal}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1),_c('AcceptCancelModal',{attrs:{"acceptRejectModal":_vm.acceptRejectModal,"acceptRejectModalData":_vm.acceptRejectModalData,"closeModal":_vm.closeModalAcceptCancel}}),_c('SendContentByEmailModal',{attrs:{"sendContentByEmailModal":_vm.sendByEmailModal,"closeModal":function () { return _vm.sendByEmailModal = false; },"sendingType":2}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }