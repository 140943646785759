import Vue from 'vue'
import Vuex from 'vuex'
import {fileUpload} from '@/helpers/api/fileUploads'
import {sendRDIMail} from  '@/helpers/api/rdi'
import {sendTaskMail} from  '@/helpers/api/tasks'
import moment from 'moment'
import EventBus from '@/helpers/event_bus'
import { v4 as uuidv4 } from 'uuid'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		filesToUploadCenter: {},
		messagesToMessageCenter: [],
		openTasksHandler: [],
		sendingEmailsID: []
	},
	getters: {
		getFilesInUploadCenter: state => {
			return state.filesToUploadCenter
		}
	},
	mutations: {
		setMessagesToMessageCenter(state, message){
			let messages = [...state.messagesToMessageCenter]
			messages.push(message)
			state.messagesToMessageCenter = messages
		},
		removeMessagesToMessageCenter(state, message_uuid){
			let messages = [...state.messagesToMessageCenter]

			const founded = messages.findIndex((item) => {
				return item.uuid ===  message_uuid
			})
			if(founded != -1){
				messages.splice(founded, 1)
				state.messagesToMessageCenter = messages
			}
		},
		setFilesToUploadCenter(state, files){
			Vue.set(state.filesToUploadCenter, files.uuid,  files)
			this.dispatch('processFiles')
		},
		setUploadProgress(state, data){
			state.filesToUploadCenter = {
				...state.filesToUploadCenter,
				[ data.uuid ]: {
					...state.filesToUploadCenter[data.uuid],
					...{ loaded: data.loaded, status: data.status, start: data.start }
				}
			}
		},
		removeFileFromUploadCenter(state, data){
			// delete state.filesToUploadCenter[uuid]
			Vue.delete(state.filesToUploadCenter, data.uuid)
			this.dispatch('processFiles')
			this.dispatch('checkSendingEmail', data)
		},
		addTaskToTasksHandler(state, task){
			let openTasksHandler = [...state.openTasksHandler]
			const founded = openTasksHandler.findIndex((item) => {
				return item.task_id === task.task_id
			})
			if(founded == -1){
				openTasksHandler.push(task)
				state.openTasksHandler = openTasksHandler
			}
		},
		removeTaskFromTasksHandler(state, task_index){
			let openTasksHandler = [...state.openTasksHandler]
			openTasksHandler.splice(task_index, 1)
			state.openTasksHandler = openTasksHandler
		},
		addIdToSendingEmail(state, data){
			let sendingEmailsID = [...state.sendingEmailsID]
			const founded = sendingEmailsID.findIndex((item) => {
				return item.item_id === data.item_id && item.type === data.type
			})
			if(founded == -1){
				sendingEmailsID.push({item_id: data.id, type: data.type})
				state.sendingEmailsID = sendingEmailsID
			}
		},
		removeIdToSendingEmail(state, data){
			let sendingEmailsID = [...state.sendingEmailsID]
			const foundedIndex = sendingEmailsID.findIndex((item) => {
				return item.item_id === data.item_id && item.type === data.type
			})
			if(foundedIndex == -1){
				sendingEmailsID.splice(foundedIndex, 1)
				state.sendingEmailsID = sendingEmailsID
			}
		}
	},
	actions: {
		addMessage(context, message){
			message.uuid = uuidv4()
			context.commit('setMessagesToMessageCenter', message)
			setTimeout(() => {
				context.commit('removeMessagesToMessageCenter', message.uuid)
			}, message.close ? message.close:5000);
		},
		async checkSendingEmail(context, data){
			console.log('checkSendingEmail: ', data)
			const foundIndex = context.state.sendingEmailsID.findIndex((item) => {
				return item.item_id === data.item_id && item.type === data.type
			})
			if(
				foundIndex !== -1 || !data || (data && (!data.copy_to || data.copy_to === '')) // Si ya se está enviando o no hay recipientes el correo paro la función
			){ return false }

			const isUploading = Object.keys(context.state.filesToUploadCenter).some(key => {
				if(data.type === 1){
					return context.state.filesToUploadCenter[key].payload &&
									context.state.filesToUploadCenter[key].item_id &&
									context.state.filesToUploadCenter[key].item_id === data.item_id // Sigue subiendo archivos de la rdi con id data.item_id
				}
				if(data.type === 3){
					return context.state.filesToUploadCenter[key].payload &&
									context.state.filesToUploadCenter[key].item_id &&
									context.state.filesToUploadCenter[key].item_id === data.item_id // Sigue subiendo archivos de la rdi con id data.item_id
				}
			})
			if(!isUploading){
				// NO está subiendo archivos para tarea/rdi con id data.item_id, se puede enviar el correo con los adjuntos
				context.commit('addIdToSendingEmail', data)
				if(data.type === 1){
					const sendData = {
						emails: data.copy_to,
						task_id: data.item_id,
						attach_files: data.attach_files
					}
					await sendTaskMail(sendData)
				}
				if(data.type === 3){
					const sendData = {
						emails: data.copy_to,
						rdi_id: data.item_id,
						attach_files: data.attach_files
					}
					await sendRDIMail(sendData)
				}
				context.commit('removeIdToSendingEmail', data)
			}
		},
		async processFiles(context){
			let procesing = 0
			Object.keys(context.state.filesToUploadCenter).map(key => {
				if(!context.state.filesToUploadCenter[key].status){
					procesing++
				}
			})
			if(procesing < 4){
				Object.keys(context.state.filesToUploadCenter).map(key => {
					const file = context.state.filesToUploadCenter[key]
					if(!file.status){
						const start = moment()
						context.commit('setUploadProgress', {
							uuid: file.uuid,
							loaded: 0,
							status: true,
							start,
						})
						console.log('Filw: ', file)

						const formData = new FormData()
						formData.append('file', file.file)
						formData.append('type', file.type)
						Object.keys(file.payload).forEach((key) => {
							if(file.payload[key]){
								formData.append(key, file.payload[key])
							}
						})
						fileUpload(formData, (progress) => {
							if(context.state.filesToUploadCenter[file.uuid].loaded < progress.loaded || !context.state.filesToUploadCenter[file.uuid].loaded){
								context.commit('setUploadProgress', {
									uuid: file.uuid,
									loaded: progress.loaded,
									status: true,
									start,
								})
							}
						}).then(() => {
							EventBus.$emit('completedFileUpload', file.payload)
							const data = {
								type: file.type,
								uuid: file.uuid,
								item_id: file.item_id,
								copy_to: file.copy_to,
								attach_files: file.attach_files
							}
							context.commit('removeFileFromUploadCenter', data)
						}).catch((e) => {
							console.log('fileUpload err: ', e)
							context.commit('removeFileFromUploadCenter', file.uuid)
						})

					}
				})
			}
		}
	}
})

export default store