<template>
	<v-container fluid style="display:flex; flex-grow: 1;" v-if="$store.forgeStore.state.loadingToken">
		<div class="d-flex flex-column align-center justify-center" style="width: 100%">
		<v-progress-circular
			:size="50"
			:width="2"
			color="#2D4B95"
			indeterminate
		></v-progress-circular>
		<div class="mt-4">Cargando Autodesk Forge</div>
		</div>
	</v-container>
	<v-container fluid style="display:flex; flex-grow: 1;" v-else>
		<div id="forgeViewer" style="width: 100%; height: 100%; position: relative;min-height: 240px;"></div>
	</v-container>
</template>

<script>
export default {
	name: 'ForgeViewerForgeViewer',
	props: {
		hideControls: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		forgeToken() {
			return this.$store.forgeStore.state.forgeToken
		},
		forgeSelectedFile() {
			return this.$store.forgeStore.state.forgeSelectedFile
		}
	},
	watch: {
		forgeToken(newData) {
			if(newData && this.forgeSelectedFile){
				this.initializedAutodesk()
			}
		},
		forgeSelectedFile(newData) {
			if(newData && this.forgeToken){
				this.initializedAutodesk()
			}
		}
	},
	data: () => ({
		initialized: false,
		forgeViewer: null
	}),
	mounted(){
		this.$store.forgeStore.dispatch('fetchForgeToken', ['data:read','bucket:read'])
	},
	methods:{
		initializedAutodesk(){
			if(this.initialized || window.Autodesk){ 
				this.initialized = true
				this.initializeViewer()
			}
			else{
				const script = document.createElement('script');
				script.src = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';
				script.onload = () => {
					if(window.Autodesk){
						this.initialized = true
						this.initializeViewer()
					}
				};
				document.head.appendChild(script)
			}
		},
    initializeViewer() {
			if(this.forgeViewer){
				this.forgeViewer.tearDown()
				this.forgeViewer.finish()
			}
			
      const options = {
				env: 'AutodeskProduction2',
				api: 'streamingV2',
        getAccessToken: this.getForgeToken
      }
			// eslint-disable-next-line no-undef
      Autodesk.Viewing.Initializer(options, () => {
        const viewerDiv = document.getElementById('forgeViewer');
				// eslint-disable-next-line no-undef
        this.forgeViewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv);
        this.forgeViewer.start();
				this.setForgeFileToViewer()
      });
    },
		setForgeFileToViewer(){
			const urn = this.$store.forgeStore.state.forgeSelectedFile.forge_file.urn
			const base64Urn = btoa(urn); // URN del archivo en Autodesk Forge
			// eslint-disable-next-line no-undef
			Autodesk.Viewing.Document.load('urn:' + base64Urn, (doc) => {
				const defaultModel = doc.getRoot().getDefaultGeometry();
				this.forgeViewer.loadDocumentNode(doc, defaultModel).then(() => {
					this.forgeViewer.navigation.fitBounds(true, this.forgeViewer.model.getBoundingBox())

					if(this.hideControls){
						this.forgeViewer.toolbar.setVisible(false)
					}
					else{
						// eslint-disable-next-line no-undef
						this.forgeViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
							this.loadExtensions();
						});
					}
				
				});
			}, (a,b,c) => {
				// Callback si ocurre un error al cargar el documento
				console.error('Error loading document a:', a);
				console.error('Error loading document b:', b);
				console.error('Error loading document c:', c);
			});
		},
		loadExtensions() {
			console.log('loadExtensions')
			const extensions = [
				'Autodesk.Viewing.MixpanelExtension',
				'Autodesk.DocumentBrowser',
				'Autodesk.ViewCubeUi',
				'Autodesk.BimWalk',
				'Autodesk.Measure'
			];

			extensions.forEach((extension) => {
				this.forgeViewer.loadExtension(extension).catch((error) => {
					console.error(`Failed to load extension ${extension}:`, error);
				});
			});
		},
    getForgeToken(callback) {
      const token = this.$store.forgeStore.state.forgeToken
      const expireTimeInSeconds = 3600;
      callback(token, expireTimeInSeconds);
    }
	}
};
</script>

<style scoped>
@import 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css';

</style>
