<template>
	<div class="d-flex align-center flex-wrap flex-grow-1 mx-2">
		<v-btn
			class="mx-2 my-1"
			rounded
			color="#5E85EF"
			dark
			small
			v-if="checkCompanyLevelPermissions(['edit_project'])"
			@click="() => $store.projectsStore.commit('setEditProjectModal', true)"
		>
			<v-icon dark small>mdi-square-edit-outline</v-icon>
			<p class="mb-0 ml-2">Editar Proyecto</p>
		</v-btn>
		<v-btn
			class="mx-2 my-1"
			rounded
			color="#34BFA3"
			dark
			small
			v-if="checkCompanyLevelPermissions(['create_project'])"
			@click="() => $store.projectsStore.commit('setNewProjectModal', true)"
		>
			<v-icon dark small>mdi-square-edit-outline</v-icon>
			<p class="mb-0 ml-2">Nuevo Proyecto</p>
		</v-btn>
	</div>
</template>
<script>
export default {
	name: 'ProjectActionButtons',
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>