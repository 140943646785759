<template>
	<v-container fluid fill-height pa-0
		:class="setClases()">
		<router-view></router-view>
	</v-container>
</template>

<script>
export default {
	name: 'RouterContainer',
	computed: {
    currentRouteName() {
        return this.$route.name;
    }
	},
	data: () => ({
		//
	}),
	methods: {
		setClases(){
			let classToAdd = ''
			if(this.$store.menuStore.state.sidebarMini){
				classToAdd += 'mini'
			}
			if(!['Login', 'ResetPassRequest', 'ResetPass'].includes(this.currentRouteName)){
				classToAdd += ' router-container'
			}
			if(this.$vuetify.breakpoint.mobile){
				classToAdd += ' mobile'
			}
			return classToAdd
		}
	}
};
</script>
<style scoped>
	.router-container{
		flex: 1;
		width: calc(100% - 220px);
		margin-left: 220px;
		margin-right: 0;
	}
	.router-container.mini{
		width: calc(100% - 56px);
		margin-left: 56px;
	}
	.router-container.mobile{
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.router-container.mini{
		width: calc(100% - 56px);
		margin-left: 56px;
	}
</style>
