import Vue from 'vue'
import Vuex from 'vuex'
import {list, getAdditionalByID, listAdditionalLikely, getAdditionalLikely, listPaymentStatus} from '@/helpers/api/additionals'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		additionalTypes: [
			{
				id: 1,
				name: 'Obra Extraordinaria'
			},
			{
				id: 2,
				name: 'Aumento de Obra'
			},
			{
				id: 3,
				name: 'Disminución de Obra'
			}
		],
		listado: {
			status: 99,
			count: 0,
			total_additional: 0,
			data: []
		},
		listadoTotal: 0,
		listadoInformativeTable: {
			status: 99,
			count: 0,
			total_additional: 0,
			data: []
		},
		listadoInformativeTableTotal: 0,
		listadoPosibles: {
			status: 99,
			count: 0,
			total_additional: 0,
			data: []
		},
		listadoPosiblesTotal: 0,
		createAdicionalModal: false,
		createPosibleAdicionalModal: false,
		listingOrder: 1,
		selectedType: 2,
		currentAdicional: null,
		currentAdicionalPosible: null,
		initialDataAdicional: null,
		loadingChangeVisualizationStatus: []
	},
	mutations: {
		setListado(state, adicionales){
			state.listado = adicionales
			state.listadoTotal = adicionales.total_additional
		},
		setListadoInformativeTable(state, adicionales){
			state.listadoInformativeTable = adicionales
			state.listadoInformativeTableTotal = adicionales.total_additional
		},
		setListadoPosibles(state, adicionales){
			console.log('setListadoPosibles: ', adicionales)
			state.listadoPosibles = adicionales
			state.listadoPosiblesTotal = adicionales.total_additional
		},
		setCurrentAdicional(state, adicional){
			state.currentAdicional = adicional
		},
		setCurrentAdicionalPosible(state, adicional){
			state.currentAdicionalPosible = adicional
		},
		setBorradorRDI(state, rdi){
			state.borradorRDI = rdi
		},
		setSelectedType(state, type){
			state.selectedType = type
		},
		setListingOrder(state){
			let currentStatus = state.listingOrder
			if(currentStatus === 4){
				currentStatus = 1
			}
			else{
				currentStatus++
			}
			state.listingOrder = currentStatus
		},
		setCreateAdicionalModal(state, status){
			state.createAdicionalModal = status
		},
		setCreatePosibleAdicionalModal(state, status){
			state.createPosibleAdicionalModal = status
		},
		setInitialDataRDI(state, data){
			state.initialDataRDI = data
		},
		setRdiGraphsDataCountBySpecialty(state, data){
			state.rdiGraphsDataCountBySpecialty = data
		},
		addLoadingChangeVisualizationStatus(state, newItemID){
			const changingStatus = [...state.loadingChangeVisualizationStatus]
			const found = changingStatus.find((item) => item === newItemID)
			if(!found){
				changingStatus.push(newItemID)
			}
			state.loadingChangeVisualizationStatus = changingStatus
		},
		removeLoadingChangeVisualizationStatus(state, itemID){
			const changingStatus = [...state.loadingChangeVisualizationStatus]
			const foundIndex = changingStatus.findIndex((item) => item === itemID)
			if(foundIndex !== -1){
				changingStatus.splice(foundIndex, 1)
			}
			state.loadingChangeVisualizationStatus = changingStatus
		}
	},
	actions: {
		async getAdditionalByID(context, query){
			const data = {
				additional_id: query.additional_id
			}
			!query.dontSetLoading && context.commit('setCurrentAdicional', {loading: true})
			const additional = await getAdditionalByID(data)
			if(additional.code === 200){
				let additionalData = additional.additional
				additionalData.prev_seen_by_me = query.seen_by_me
				context.commit('setCurrentAdicional', additionalData)
			}
			else{
				context.commit('setCurrentAdicional', null)
			}
		},
		async getAdditionals(context, query){
			let page = 1
			let per_page = 50
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				statuses: query.statuses,
				order: context.state.listingOrder,
				start: per_page * (page - 1),
				limit: per_page,
				filtered_specialties: query.filtered_specialties,
				filtered_subjects: query.filtered_subjects,
				filtered_responsable: query.filtered_responsable,
			}

			let listing = Object.assign(context.state.listado, {status: 99})
			context.commit('setListado', listing)
			const additionals = await list(data)
			if(additionals.code === 200){
				context.commit('setListado', {
					status: 1,
					count: additionals.additional.length,
					data: additionals.additional,
					total_rdi: additionals.total_additional,
				})
			}
		},
		async getAdditionalInformativeTable(context, query){
			let page = 1
			let per_page = 50
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				statuses: query.statuses,
				order: context.state.listingOrder,
				start: per_page * (page - 1),
				limit: per_page
			}

			let listing = Object.assign(context.state.listadoInformativeTable, {status: 99})
			context.commit('setListadoInformativeTable', listing)
			const additionals = await listPaymentStatus(data)
			if(additionals.code === 200){
				context.commit('setListadoInformativeTable', {
					status: 1,
					count: additionals.additional.length,
					data: additionals.additional,
					payment_statuses: additionals.agps,
					total_additional: additionals.total_additional,
				})
			}
		},
		async getAdditionalsLikely(context, query){
			let page = 1
			let per_page = 50
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				statuses: query.statuses,
				order: context.state.listingOrder,
				start: per_page * (page - 1),
				limit: per_page
			}

			let listing = Object.assign(context.state.listadoPosibles, {status: 99})
			context.commit('setListadoPosibles', listing)
			const additionals = await listAdditionalLikely(data)
			if(additionals.code === 200){
				context.commit('setListadoPosibles', {
					status: 1,
					count: additionals.additional.length,
					data: additionals.additional,
					total_additional: additionals.total_additional,
				})
			}
		},
		async getAdditionalLikely(context, query){
			const data = {
				additional_likely_id: query.additional_likely_id
			}
			!query.dontSetLoading && context.commit('setCurrentAdicionalPosible', {loading: true})
			const additional = await getAdditionalLikely(data)
			if(additional.code === 200){
				let additionalData = additional.additional
				context.commit('setCurrentAdicionalPosible', additionalData)
			}
			else{
				context.commit('setCurrentAdicionalPosible', null)
			}
		},
	},
	getters: {
		currentTarea: state => {
			return state.currentTarea
		}
	}
})

export default store