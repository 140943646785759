import {endpoints} from './endpoints'
import request from '../request'

export function getProjectStorageSize(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectStorageSize,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteFile(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteFile,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}