var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":"","fill-height":""}},[_c('v-card',{staticStyle:{"width":"100%"},attrs:{"fill-height":""}},[_c('v-card-text',[(_vm.info)?_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.info.title)+" "),_c('v-icon',{attrs:{"color":_vm.info.general_status,"small":""}},[_vm._v("mdi-record-circle")])],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Velocidad de Solución Promedio")]),_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v("Por Día = "+_vm._s(_vm.info.speed_solution.value))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"8"}},[_c('v-row',_vm._l((_vm.info.circle_graphs),function(graph,index){return _c('v-col',{key:index,staticClass:"px-1",attrs:{"cols":"2"}},[_c('DoughnutChart',{attrs:{"chartdata":{
									datasets: [
										{
											backgroundColor: [graph.color,'#D9D9D9'],
											data: [graph.value, 100 - graph.value],
										} 
									]
								},"options":{
									tooltips: {enabled: false},
									cutoutPercentage: 75,
									responsive: true,
									maintainAspectRatio: true,
									elements: {
										center: {
											text: ''
										}
									}
								}}})],1)}),1)],1)],1):_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}),_c('v-skeleton-loader',{attrs:{"type":"heading"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}}),_c('v-skeleton-loader',{staticClass:"d-flex",attrs:{"type":"avatar,avatar,avatar"}})],1)],1),_c('v-divider',{staticClass:"my-3"}),(_vm.info)?_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Tiempo de Respuesta")]),_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v("Por Día = "+_vm._s(_vm.info.response_time.value))])]),_c('v-col',{attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.info.bottom.graph_title)+" "),_c('v-icon',{attrs:{"color":_vm.info.bottom.general_status,"small":""}},[_vm._v("mdi-record-circle")])],1),_vm._l((_vm.info.bottom.graphs),function(graph,index){return _c('div',{key:index,staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(graph.title))]),_c('v-progress-linear',{staticClass:"ml-2",staticStyle:{"flex":"1"},attrs:{"value":graph.value,"color":graph.color,"rounded":""}})],1)})],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }