<template>
	<v-navigation-drawer
		v-model="$store.menuStore.state.menuSidebarStatus"
		fixed
		:mini-variant="$store.menuStore.state.sidebarMini"
		:width="$store.menuStore.state.sidebarMini ? '50':'220'"
	>
		<v-icon dark class="toggle-button pa-1" @click="toggleMenu">mdi-menu-open</v-icon>
		<div style="width: 100%;" class="fill-height">
			<v-row no-gutters justify="center">
				<v-col justify="center" class="text-center pa-4">
					<img :src="require('../../assets/Svg/logoH.svg')" style="max-width: 110px; width: 100%;"/>
					<div v-if="$store.userStore.state.userInfo">
						<div class="sidebar-avatars-container">
							<img :src="$store.userStore.state.userInfo.info.avatar" class="user-avatar" :class="$store.menuStore.state.sidebarMini ? 'mini':''" v-if="$store.userStore.state.userInfo.info.avatar"/>
							<img v-if="$store.projectsStore.state.selectedProject" :src="$store.projectsStore.state.selectedProject.project_image" class="project-avatar-sidebar" :class="$store.menuStore.state.sidebarMini ? 'mini':''"/>
							<v-icon v-else class="user-avatar icon" color="#2D4B95" x-large>mdi-account</v-icon>
						</div>
						<div v-if="!$store.menuStore.state.sidebarMini">
							<p class="mb-0" style="color: #606060;">{{$store.userStore.state.userInfo.name}}</p>
							<p class="mb-0 body-2" style="color: #2D4B95;"
								v-if="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject.job_type">{{$store.projectsStore.state.selectedProject.job_type.name}}</p>
							<div class="d-flex flex-row justify-center align-center" v-if="$store.projectsStore.state.selectedProject">
								<p class="mb-0 caption font-weight-bold mr-1" style="color: #2D4B95;">{{$store.projectsStore.state.selectedProject.name}}</p>

								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											color="#5E85EF"
											class="info-icon"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon dark x-small>mdi-swap-horizontal-bold</v-icon>
										</v-btn>
									</template>
									<v-list dense class="py-0">
										<v-list-item dense class="mb-1" style="min-height: 25px; background: #5E85EF;">
											<p class="caption mb-0 font-weight-bold white--text">Cambiar de Proyecto</p>
										</v-list-item>
										<v-list-item-group color="#5E85EF">
											<v-list-item
												v-for="(project, index) in $store.projectsStore.state.projectsList"
												v-show="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject.id != project.id"
												:key="index"
												dense
												@click="changeProject(index)"
											>
												<v-list-item-title dense>
													{{ project.name }}
												</v-list-item-title>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-menu>
							</div>
						</div>
					</div>
					<div v-else>
						<v-skeleton-loader type="avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
					</div>
				</v-col>
			</v-row>
			<Menu />
			<div class="mt-5 px-2">
				<v-btn
					depressed
					color="#DD5050"
					dark
					block
					:small="$store.menuStore.state.sidebarMini"
					@click="logout()"
				>
					<v-icon>mdi-power</v-icon>
					<p class="mb-0 ml-2 body-2"
					v-show="!$store.menuStore.state.sidebarMini">Salir de laOT</p>
				</v-btn>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import Menu from './Menu/Index'
export default {
	name: 'Sidebar',
	components: { Menu },
	data: () => ({
	}),
	methods: {
		toggleMenu(){
			if(this.$vuetify.breakpoint.mobile){
				this.$store.menuStore.commit('setMenuSidebarStatus', !this.$store.menuStore.state.menuSidebarStatus)
				return null
			}
			this.$store.menuStore.commit('toggleSidebarMini')
		},
		logout(){
			this.$store.userStore.commit('logoutUser')
			this.$router.push({name: 'Login'})
		},
		async changeProject(index){
			const projectID = this.$store.projectsStore.state.projectsList[index].id
			const companyID = this.$store.projectsStore.state.projectsList[index].company_id
			await this.$store.projectsStore.dispatch('getSelectedProject', projectID)
			if(this.$store.userStore.state.userCompanies.length > 0){
				const found = this.$store.userStore.state.userCompanies.find((company) => {
					return company.company_id = companyID
				})
				if(found){
					this.$store.userStore.commit('setCompanyPermissions', found.capabilities)
					this.$store.userStore.commit('setSelectedCompany', found)
				}
			}

			this.getConsultasList(projectID)
			this.getRdiList(projectID)
			this.getAdditionalList(projectID)
			this.getDashboardInfo()
			this.getProjectUserList()
		},
    async getConsultasList(project_id){
      if(project_id){
        this.$store.consultasStore.dispatch('getMyTasks', {project_id})
      }
    },
    async getRdiList(project_id){
			if(project_id){
				this.$store.rdiStore.dispatch('getRDI', {project_id})
			}
			if(this.$store.rdiStore.state.selectedType == 4){
				this.$store.rdiStore.dispatch('getRdiGraphsData', {project_id})
			}
			else{
				this.$store.rdiStore.dispatch('getRDI', {project_id})
			}
    },
		async getAdditionalList(project_id){
				if(!project_id){
					return null
				}
				if(this.$store.adicionalesStore.state.selectedType == 4){
					this.$store.adicionalesStore.dispatch('getAdditionalsLikely', {project_id})
				}
				if(this.$store.adicionalesStore.state.selectedType == 3){
					this.$store.adicionalesStore.dispatch('getAdditionalInformativeTable', {project_id})
				}
				else{
					this.$store.adicionalesStore.dispatch('getAdditionals', {project_id})
				}
		},
		getDashboardInfo(){
			this.$store.projectsStore.dispatch('getDashboardInfo')
			this.$store.fileManagerStore.dispatch('getProjectStorageSize', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
		},
		getProjectUserList(){
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				start: 0,
				limit: 20
			}
			this.$store.usersStore.dispatch('getUsersList', data)
		}
	}
};
</script>
<style>
	.toggle-button{
		position: absolute!important;
		top: 0;
		right: 0;
		background: #5E85EF;
	}
	.v-skeleton-loader__bone{
		margin-right: auto;
		margin-left: auto;
	}
	.sidebar-avatars-container{
		width: 100px;
		height: 100px;
		position: relative;
		margin: auto;
	}
	.user-avatar{
		width: 80px;
		height: 80px;
		border-radius: 80px;
		object-fit: cover;
	}
	.user-avatar.mini{
		width: 30px;
		height: 30px;
		border-radius: 30px;
	}
	.user-avatar.icon{
		background-color: #F0F2F8;
	}
	.info-icon{
		background-color: #2D4B95;
		border-radius: 25px;
		padding: 3px;
		width: 22px!important;
		height: 22px!important;
	}
	.project-avatar-sidebar{
		width: 40px;
		height: 40px;
		border-radius: 40px;
		position: absolute;
		bottom: 10px;
		right: 0;
	}
</style>
