import Vue from 'vue'
import Vuex from 'vuex'
import {getProjectStorageSize} from '@/helpers/api/fileManager'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		projectStorageInfo: {
			total_size: 0,
			total_count: 0,
			total_files_size: 0,
			total_files_count: 0,
			total_files_version_size: 0,
			total_files_version_count: 0,
		},
		loadingStorageInfo: false,
	},
	mutations: {
		setLoadingStorageInfo(state, status){
			state.loadingStorageInfo = status
		},
		setProjectStorageInfo(state, info){
			state.projectStorageInfo = info
		},
	},
	actions: {
		async getProjectStorageSize(context, query){
			context.commit('setLoadingStorageInfo', true)
			const data = { project_id: query.project_id }
			const storageInfo = await getProjectStorageSize(data)
			if(storageInfo.code === 200){
				context.commit('setProjectStorageInfo', storageInfo.data)
				context.commit('setLoadingStorageInfo', false)
			}
		}
	}
})

export default store