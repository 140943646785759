<template>
	<div class="py-2">
		<div class="d-flex flex-row align-center justify-space-between pb-2">
			<div class="d-flex flex-row align-center">
				<v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
				<p class="mb-0 font-weight-bold text-primary body-2">Generar Nueva Revisión</p>
			</div>
			<p class="mb-0 text-primary caption font-weight-bold" v-if="revisionForm.new_status != 2">Se generará la revisión número {{additionalData.revisions.length}}</p>
		</div>

		<v-form ref="form" v-model="validform" class="my-2">
			<v-row>
				<v-col cols="12">
					<!-- <v-row align="center">
						<v-col cols="12" sm="7">
							<h2 class="mr-4 mb-0 body-2 font-weight-bold">Cambio de Estado</h2>
							<p class="mb-0 caption text-primary">Sólo selecciona una opción si quieres aceptar o solicitar que el adicional sea cancelado</p>
						</v-col>
						<v-col cols="12" sm="5">
							<div class="d-flex flex-row">
								<v-btn
									v-for="(option, index) in revisionStatuses"
									:key="index"
									dark
									x-small
									color="#5E85EF"
									class="mx-1"
									:outlined="revisionForm.new_status !== option.status"
									@click="changeStatus(option.status)"
								>
									<p class="mb-0">{{option.name}}</p>
								</v-btn>
							</div>
						</v-col>
					</v-row> -->
					<v-row>
						<v-col cols="4" class="py-0">
							<h2 class="ml-2 mb-0 body-2 font-weight-bold">Costo Directo</h2>
							<div class="d-flex flex-row">
								<v-text-field
									v-model="revisionForm.direct_cost"
									placeholder="Costo Directo"
									type="number"
									outlined
									dense
									rounded
									class="custom-input-numbers text-center"
									:rules="[...requiredRule,...decimalsRule]"
								>
								</v-text-field>
							</div>
						</v-col>
						<v-col cols="4" class="py-0">
							<h2 class="ml-2 mb-0 body-2 font-weight-bold">Gastos Generales</h2>
							<div class="d-flex flex-row">
								<v-text-field
									v-model="revisionForm.general_expenses"
									placeholder="Gastos Generales"
									type="number"
									outlined
									dense
									rounded
									class="custom-input-numbers text-center"
									:rules="[...requiredRule,...decimalsRule]"
								>
								</v-text-field>
							</div>
						</v-col>
						<v-col cols="4" class="py-0">
							<h2 class="ml-2 mb-0 body-2 font-weight-bold">Utilidad</h2>
							<div class="d-flex flex-row">
								<v-text-field
									v-model="revisionForm.utility"
									placeholder="Utilidad"
									type="number"
									outlined
									dense
									rounded
									class="custom-input-numbers text-center"
									:rules="[...requiredRule,...decimalsRule]"
								>
								</v-text-field>
							</div>
						</v-col>
						<v-col cols="8" class="py-0">
							<h2 class="ml-2 mb-0 body-2 font-weight-bold">Moneda</h2>
							<div class="d-flex flex-row">
								<v-select
									v-model="revisionForm.currency_id"
									:items="$store.generalStore.state.systemCurrencies.currencies"
									:loading="$store.generalStore.state.systemCurrencies.loading"
									item-text="name"
									item-value="id"
									label="Seleccione Moneda"
									single-line
									hint="Selecciona la moneda para el adicional"
									:rules="requiredRule"
									persistent-hint
									hide-details
									outlined
									dense
									required
								></v-select>
							</div>
						</v-col>
						<v-col cols="4" class="py-0">
							<h2 class="ml-2 mb-0 body-2 font-weight-bold">Total Solicitado</h2>
							<div class="d-flex flex-row">
								<v-text-field
									:value="computedTotalRequest"
									placeholder="Total Solicitado"
									outlined
									dense
									rounded
									readonly
									class="custom-input-numbers text-center"
								>
								</v-text-field>
							</div>
						</v-col>
					</v-row>
					<v-row align="center">
						<v-col cols="12">
							<h2 class="ml-2 mb-2 body-1 font-weight-bold">Descripción</h2>
							<tiptap-vuetify
								v-model="revisionForm.description"
								:extensions="extensions"
								required
								:rules="requiredRule"
								id="create-tarea-text-editor"
							/>
							<div class="my-2 d-flex flex-row" style="width: 100%">
								<div class="d-flex flex-row justify-space-between" style="width: 100%">
									<div class="d-flex flex-row align-center flex-wrap" style="flex: 1;">
										<div v-for="(file,index) in files" :key="index" class="ma-1" style="flex: 1 0 45%;">
											<div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
												<v-btn
													icon
													color="#5E85EF"
													x-small
													class="button-preview-img"
													@click="removeAttachment(index)"
												>
													<v-icon>mdi-close</v-icon>
												</v-btn>
												<img :src="filesPreview[index].src" class="preview-img"/>
											</div>
											<div v-else class="d-flex flex-row align-center justify-start">
												<v-btn
													icon
													color="#5E85EF"
													x-small
													@click="removeAttachment(index)"
												>
													<v-icon>mdi-close</v-icon>
												</v-btn>
												{{file.name}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="d-flex justify-space-between" style="width: 100%" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
								<v-btn
									class="mx-2 my-1"
									rounded
									color="white"
									raised
									small
									@click="closeAddRevision"
								>
									<p class="mb-0 text-primary">Cancelar</p>
								</v-btn>
								<div class="d-flex">
									<v-btn
										class="mx-2 my-1"
										rounded
										color="white"
										raised
										small
										@click="onButtonClick"
									>
										<p class="mb-0 text-primary">Adjuntar Archivo</p>
									</v-btn>
									<input
										ref="uploader"
										class="d-none"
										type="file"
										@change="onFileChanged"
									>
									<v-btn
										class="mx-2 my-1"
										rounded
										color="#2D4B95"
										raised
										small
										:loading="loadingData"
										@click="() => preCreateRevision()"
									>
										<p class="mb-0 white--text">{{confirmButtonText}}</p>
									</v-btn>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import { v4 as uuidv4 } from 'uuid'
import {addRevision} from '@/helpers/api/additionals'

export default {
	name: 'AddRevision',
	props: {
		closeAddRevision: {
			type: Function,
			required: true
		},
		additional: {
			type: Object,
			required: true
		}
	},
	components: {TiptapVuetify},
	computed: {
		additionalData() {
			return this.$store.adicionalesStore.state.currentAdicional
		},
		computedTotalRequest(){
			return this.$options.filters.toThousandFilter(parseFloat(this.revisionForm.direct_cost) + parseFloat(this.revisionForm.general_expenses) + parseFloat(this.revisionForm.utility))
		},
		lastRevision(){
			return this.additionalData.revisions[this.additionalData.revisions.length - 1]
		}
	},
	data: () => ({
		validform: true,
		files: [],
		filesPreview: [],
		revisionStatuses: [
			{name: 'Aceptado', status: 2},
			{name: 'Solicita Cancelar', status: 3}
		],
		requiredRule: [
			v => !!v || 'Campo requerido'
		],
		decimalsRule: [
			v => v.toString().match(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/) || 'El monto puede tener 2 decimales, formato XXXXXX.XX'
		],
		extensions: [
			History,
			Blockquote,
			Link,
			Underline,
			Strike,
			Italic,
			ListItem,
			BulletList,
			OrderedList,
			[Heading, {
				options: {
					levels: [1, 2, 3]
				}
			}],
			Bold,
			Code,
			HorizontalRule,
			Paragraph,
			HardBreak
		],
		loadingData: false,
		revisionForm: {
			additional_id: null,
			new_status: null,
			currency_id: 2,
			direct_cost: 0,
			general_expenses: 0,
			utility: 0,
			description: ''
		},
		cancelTimer: 5,
		inCancelTime: false,
		cancelInterval: null,
		confirmButtonText: 'Generar Nueva Revisión',
	}),
	mounted(){
		this.setDefaultValues()
	},
	methods: {
		setDefaultValues(){
			this.revisionForm = {
				additional_id: this.$store.adicionalesStore.state.currentAdicional.id,
				new_status: null,
				currency_id: this.lastRevision.currency_id ? this.lastRevision.currency_id:this.$store.projectsStore.state.selectedProject.details.currency,
				direct_cost: this.lastRevision.direct_cost,
				general_expenses: this.lastRevision.general_expenses,
				utility: this.lastRevision.utility,
				description: ''
			}
		},
		changeStatus(newStatus){
			if(this.revisionForm.new_status === newStatus){
				this.revisionForm.new_status = null
			}
			else{
				this.revisionForm.new_status = newStatus
			}
		},
		onButtonClick() {
			this.$refs.uploader.click()
		},
		async onFileChanged(e) {
			const newFile = e.target.files[0]
			this.files.push(newFile)
			if(newFile.type.includes('image')){
				const base64File = await this.toBase64(newFile)
				this.filesPreview.push({src: base64File})
			}
			else{
				this.filesPreview.push({noImage: true})
			}
		},
		preCreateRevision(){
			if(this.inCancelTime){
				clearInterval(this.cancelInterval)
				this.confirmButtonText = 'Generar Nueva Revisión'
				this.inCancelTime = false
				return null
			}
			if(this.revisionForm.description === '' || !this.revisionForm.description){
				const datas = {
					message: 'Por favor escribe una descripción. Este campo no puede estar vacio.',
					title: 'Error',
					created: new Date(),
					type: 'success',
					icon: 'mdi-alert-circle',
					color: '#fb8c00'
				}
				this.$store.globalStore.dispatch('addMessage', datas)
				return false
			}
			if(this.$refs.form.validate()){
				this.inCancelTime = true
				this.cancelTimer = 5
				this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
				this.cancelInterval = setInterval(() => {
					this.cancelTimer--
					this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
					if(this.cancelTimer == 0){
						this.inCancelTime = false
						clearInterval(this.cancelInterval)
						this.addAdditionalRevision()
					}
				}, 1000);
			}
		},
		async addAdditionalRevision(){
			this.loadingData = true
			const revision = await addRevision(this.revisionForm)
			let data = {
				message: 'Hubo un error al ingresar la revisión. Por favor intenta nuevamente.',
				title: 'Error',
				created: new Date(),
				type: 'success',
				icon: 'mdi-alert-circle',
				color: '#fb8c00'
			}
			if(revision.code === 200){
				data = {
					message: 'Revisión ingresada correctamente.',
					title: 'Revisión creada',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.prepareFilesToUpload(revision.new_revision.id)
				this.$store.adicionalesStore.dispatch('getAdditionalByID', {
					additional_id: this.revisionForm.additional_id,
					seen_by_me: true,
					dontSetLoading: true
				})
				this.closeAddRevision()
			}
			this.$store.globalStore.dispatch('addMessage', data)
			this.loadingData = false
		},
		prepareFilesToUpload(revision_id){
			this.files.forEach((file) => {
				const fileData = {
					type: 6,
					payload: {
						additional_id: this.additional.id,
						additional_revision_id: revision_id,
						project_id: this.$store.projectsStore.state.selectedProject.id
					},
					description: this.additional.title,
					total: file.size,
					uuid: uuidv4(),
					file
				}
				this.$store.globalStore.commit('setFilesToUploadCenter', fileData)
			})
		},
    toBase64(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result)
        };
        reader.onerror = function () {
          resolve(null)
        };
      })
    },
	}
}
</script>

<style scoped>
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
</style>