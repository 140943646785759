<template>
	<div>
		<NewProjectModal />
		<EditProjectModal v-if="this.$store.projectsStore.state.editProjectModal" />
		<EditUserModal v-if="this.$store.usersStore.state.selectedUserInfo && this.$store.usersStore.state.editUserModal" />
		<EditPermisosUserModal v-if="this.$store.usersStore.state.selectedUserInfo && this.$store.usersStore.state.editPermisosUserModal" />
		<EditPermisosEmpresaUserModal v-if="this.$store.usersStore.state.selectedUserInfo && this.$store.usersStore.state.editPermisosEmpresaUserModal" />
		<EditUserProjectsModal v-if="this.$store.usersStore.state.selectedUserInfo && this.$store.usersStore.state.editUserProjectsModal" />
		<NewCompanyModal />
		<NewUserModal v-if="this.$store.usersStore.state.newUserModal"/>
		<CreateTareaModal v-if="this.$store.consultasStore.state.createTareaModal" :modalStatus="this.$store.consultasStore.state.createTareaModal" :closeModal="() => this.$store.consultasStore.commit('setCreateTareaModal', false)"/>
		<CreateRDIModal v-if="this.$store.rdiStore.state.createRDIModal" :modalStatus="this.$store.rdiStore.state.createRDIModal" :closeModal="() => this.$store.rdiStore.commit('setCreateRDIModal', false)"/>
		<CreateAdicionalModal v-if="this.$store.adicionalesStore.state.createAdicionalModal" :modalStatus="this.$store.adicionalesStore.state.createAdicionalModal" :closeModal="() => this.$store.adicionalesStore.commit('setCreateAdicionalModal', false)"/>
		<CreateAdicionalLikelyModal v-if="this.$store.adicionalesStore.state.createPosibleAdicionalModal" :modalStatus="this.$store.adicionalesStore.state.createPosibleAdicionalModal" :closeModal="() => this.$store.adicionalesStore.commit('setCreatePosibleAdicionalModal', false)"/>
		<TareaModal />
		<RDIModal />
		<AdicionalModal />
		<AcceptCancelModal />
		<AdicionalLikelyModal />
		<CloseOpenProjectModal v-if="this.$store.projectsStore.state.closeOpenProjectModal" :closeOpenProjectModal="this.$store.projectsStore.state.closeOpenProjectModal"/>
	</div>
</template>

<script>
import NewProjectModal from './Projects/NewProjectModal'
import EditProjectModal from './Projects/EditProjectModal'
import NewCompanyModal from './Projects/NewCompanyModal'
import CloseOpenProjectModal from './Projects/CloseOpenProjectModal'
import NewUserModal from './Users/NewUserModal'
import EditUserModal from './Users/EditUserModal'
import EditPermisosUserModal from './Users/EditPermisosUserModal'
import EditPermisosEmpresaUserModal from './Users/EditPermisosEmpresaUserModal'
import EditUserProjectsModal from './Users/EditUserProjectsModal'
import CreateTareaModal from './Tasks/CreateTareaModal'
import TareaModal from './Tasks/TareaModal'
import CreateRDIModal from './Rdis/CreateRDIModal'
import RDIModal from './Rdis/RDIModal'
import CreateAdicionalModal from './Adicionales/CreateAdicionalModal'
import CreateAdicionalLikelyModal from './Adicionales/CreateAdicionalLikelyModal'
import AdicionalLikelyModal from './Adicionales/AdicionalLikelyModal'
import AdicionalModal from './Adicionales/AdicionalModal'
import AcceptCancelModal from './General/AcceptCancelModal'

export default {
	name: 'GlobalModalsIndex',
	components: {NewProjectModal, EditProjectModal, NewCompanyModal, NewUserModal, EditUserModal, EditPermisosUserModal,
							EditUserProjectsModal, CreateTareaModal, TareaModal, CreateRDIModal, RDIModal, AcceptCancelModal,
							EditPermisosEmpresaUserModal, CloseOpenProjectModal, CreateAdicionalModal, AdicionalModal, CreateAdicionalLikelyModal, AdicionalLikelyModal},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
