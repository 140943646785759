import axios from 'axios'

// const BASE = 'http://127.0.0.1:8002/api/'
// const BASE = 'http://127.0.0.1:80/api/'
// const BASE = 'https://api.laot.cl:8002/api/' //Desarrollo AWS
const BASE = 'https://api.laot.cl/api/' //Producción AWS

const service = axios.create({ baseURL: BASE })

service.defaults.timeout = 20000

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const contentType = config.data instanceof FormData ? 'multipart/form-data':'application/json'
    const token = localStorage.getItem('userToken')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = contentType
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    // do something with request error
    // console.log('API: ', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response
    // console.log(res.code)
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.status === 403 || res.status === 50008 || res.status === 50012 || res.status === 50014) {
        // to re-login
        localStorage.removeItem('userToken')
        location.reload()
      }
      return Promise.reject(res || 'Error')
    } else {
      return res.data
    }
  },
  async error => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      await localStorage.removeItem('userToken')
      if(error.response.config && error.response.config.url != '/auth/login'){
        location.reload()
      }
    }
    return Promise.reject(error.response)
  }
)

export default service