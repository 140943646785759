<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.projectsStore.state.newCompanyModal"
      max-width="600"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Creación Empresa</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Nombre</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newCompanyData.name"
                    label="Nombre"
                    placeholder="Ingrese nombre de la empresa"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Descripción</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newCompanyData.description"
                    label="Descripción"
                    placeholder="Ingrese descripción de la empresa"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Logo</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-file-input
                    v-model="newCompanyData.logo"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Seleccione logo empresa"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Url Sitio Web</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newCompanyData.url"
                    label="Url"
                    placeholder="Ingrese sitio web de la empresa"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#2D4B95"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {create} from '@/helpers/api/company'
import {getUser} from '@/helpers/api/user'
export default {
  name: 'NewCompanyModal',
  data: () => ({
    validform: true,
    newCompanyData: {
      name: null,
      description: null,
      url: null,
      logo: null,
    },
    loadingData: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
  }),
  mounted(){
  },
  methods: {
    closeModal(){
      this.$store.projectsStore.commit('setNewCompanyModal', false)
      this.newCompanyData = {
        name: null,
        description: null,
        url: null,
        logo: null,
      }
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const formData = new FormData()
        Object.keys(this.newCompanyData).forEach((key) => {
          if(this.newCompanyData[key]){
            formData.append(key, this.newCompanyData[key])
          }
        })
        const company = await create(formData)
        if(company.code === 200){
          await getUser()
          this.closeModal()
        }
        this.loadingData = false
      }
    }
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>