<template>
	<div class="d-none"></div>
</template>

<script>
import { getDatabase, ref, onValue} from "firebase/database";

export default {
	name: 'FirebaseController',
  computed: {
    userProjects() {
      return this.$store.projectsStore.state.projectsList
    },
  },
  watch: {
    userProjects(userProjects) {
      if(userProjects && !this.projects){
				this.projects = userProjects
				this.setFirebaseProjectListenner(userProjects)
      }
    }
  },
	data: () => ({
		projects: null
	}),
	mounted(){
	},
	methods: {
		playNotification(){
			var audio = new Audio(require('@/assets/Sounds/notification.mp3'))
			audio.play()
		},
		setFirebaseProjectListenner(projects){
			projects.forEach(project => {
				const user_id = project.user_project.user_id
				const db = getDatabase()
				if(project.user_project && project.user_project.capabilities && project.user_project.capabilities.view_tasks_listing){
					const tasksRef = ref(db, project.id + '/' + user_id + '/tasks')
					onValue(tasksRef, (snapshot) => {
						const data = snapshot.val()
						if(data){
							const makeSound = project.useroptions && project.useroptions.notification_sound
							this.showNotification(data, makeSound)
							if(project.id == this.$store.projectsStore.state.selectedProject.id){
								this.$store.notificationsStore.dispatch('getNotifications', {
									project_id: this.$store.projectsStore.state.selectedProject.id
								})
								this.getConsultasList(this.$store.projectsStore.state.selectedProject.id)
							}
						}
					})
				}
				if(project.user_project && project.user_project.capabilities && project.user_project.capabilities.view_rdi){
					const rdiRef = ref(db, project.id + '/rdis')
					onValue(rdiRef, (snapshot) => {
						const data = snapshot.val()
						if(data){
							const makeSound = project.useroptions && project.useroptions.notification_sound
							this.showNotification(data, makeSound)
							if(project.id == this.$store.projectsStore.state.selectedProject.id){
								this.$store.notificationsStore.dispatch('getNotifications', {
									project_id: this.$store.projectsStore.state.selectedProject.id
								})
								this.getRDIList(this.$store.projectsStore.state.selectedProject.id)
							}
						}
					})
				}
				if(project.user_project && project.user_project.capabilities && project.user_project.capabilities.view_additionals){
					const rdiRef = ref(db, project.id + '/additional')
					onValue(rdiRef, (snapshot) => {
						const data = snapshot.val()
						if(data){
							const makeSound = project.useroptions && project.useroptions.notification_sound
							this.showNotification(data, makeSound)
							if(project.id == this.$store.projectsStore.state.selectedProject.id){
								this.$store.notificationsStore.dispatch('getNotifications', {
									project_id: this.$store.projectsStore.state.selectedProject.id
								})
								this.getAdditionalList(this.$store.projectsStore.state.selectedProject.id)
							}
						}
					})
				}
			})
		},
		showNotification(data, sound = false){
			Object.values(data).forEach(notificationData => {
				if(notificationData.created_by != this.$store.userStore.state.userInfo.id){
					this.$store.globalStore.dispatch('addMessage', notificationData)
					sound && this.playNotification()
				}
			});
		},
		getConsultasList(project_id){
			if(project_id){
				this.$store.consultasStore.dispatch('getMyTasks', {project_id})
			}
		},
		getRDIList(project_id){
			if(project_id){
				this.$store.rdiStore.dispatch('getRDI', {project_id})
			}
		},
		getAdditionalList(project_id){
			if(project_id){
				this.$store.adicionalesStore.dispatch('getAdditionals', {project_id})
			}
		}
	}
};
</script>

<style scoped>
</style>