var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":"","fill-height":""}},[_c('v-card',{staticStyle:{"width":"100%"},attrs:{"fill-height":""}},[(!_vm.info)?_c('v-card-text',[_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}),_c('v-skeleton-loader',{attrs:{"type":"heading"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}}),_c('v-skeleton-loader',{staticClass:"d-flex",attrs:{"type":"avatar,avatar,avatar"}})],1)],1)],1):_c('v-card-text',[_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{attrs:{"cols":"5"}},[_c('router-link',{staticClass:"mb-0 font-weight-bold pointer-cursor",attrs:{"tag":"p","to":{ name: 'Rdi' }}},[_vm._v(_vm._s(_vm.info.title))]),_c('p',{staticClass:"mb-0"},[_vm._v("Velocidad de Solución Promedio")]),_c('p',{staticClass:"mb-0 font-weight-bold"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm._f("oneDecimalsNumber")(_vm.info.speed_solution.value)))]),_vm._v(" "+_vm._s(_vm.info.speed_solution.suffix))])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row justify-start align-center",staticStyle:{"flex":"1"}},[_c('p',{staticClass:"mb-0 font-weight-bold mr-1"},[_vm._v(_vm._s(_vm.info.total_title))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.$options.filters.getColorStatusModulo(_vm.info.semaforo_rdis),"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-record-circle")])]}}])},[_c('p',{staticClass:"caption mb-0"},[_vm._v("Estado general del módulo: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$options.filters.getTextStatusModulo(_vm.info.semaforo_rdis)))])])])],1),(_vm.checkPermissions(['create_rdi']))?_c('v-btn',{staticClass:"pa-0",staticStyle:{"height":"auto"},attrs:{"text":""},on:{"click":function($event){return _vm.$store.rdiStore.commit('setCreateRDIModal', true)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#5e85ef","small":""}},[_vm._v("mdi-plus-circle")]),_c('p',{staticClass:"mb-0 caption text-capitalize"},[_vm._v("Nueva RDI")])],1):_vm._e()],1),_c('v-row',_vm._l((_vm.info.circle_graphs),function(graph,index){return _c('v-col',{key:index,staticClass:"px-1",attrs:{"cols":"4"}},[_c('DoughnutChart',{attrs:{"chartdata":{
									datasets: [
										{
											backgroundColor: [graph.color,'#D9D9D9'],
											data: [graph.value, 100 - graph.value],
										} 
									]
								},"centerText":graph.center_text,"options":{
									tooltips: {enabled: false},
									cutoutPercentage: 75,
									responsive: true,
									maintainAspectRatio: true,
									elements: {
										center: {
											text: graph.center_text
										}
									}
								}}})],1)}),1)],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.info.adicionales.title))]),_c('p',{staticClass:"mb-0"},[_vm._v("Tiempo promedio de espera")]),_c('p',{staticClass:"mb-0 font-weight-bold"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm._f("oneDecimalsNumber")(_vm.info.adicionales.speed_solution.value)))]),_vm._v(" "+_vm._s(_vm.info.adicionales.speed_solution.suffix)+" "),(_vm.info.adicionales.speed_solution_last)?_c('span',{staticClass:"caption"},[_vm._v("/ "),_c('span',[_vm._v(_vm._s(_vm._f("oneDecimalsNumber")(_vm.info.adicionales.speed_solution_last.value)))]),_vm._v(" "+_vm._s(_vm.info.adicionales.speed_solution_last.suffix))]):_vm._e()])]),_c('v-col',{attrs:{"cols":"7"}},[_c('BarChart',{staticStyle:{"max-height":"100px"},attrs:{"chartdata":{
							labels: ['S4', 'S3', 'S2', 'S1'],
							datasets: [
								{
									backgroundColor: '#EAB261',
									data: _vm.info.adicionales.graphs.created_by_week
								},
								{
									backgroundColor: '#34BFA3',
									data: _vm.info.adicionales.graphs.solved_by_week
								}
							]
						},"options":{
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							tooltips: {
								callbacks: {
									label: function(tooltipItem) {
										return tooltipItem.yLabel;
									}
								}
							},
							scales: {
								yAxes: [{
									ticks: {
										beginAtZero: true
									}
								}]
							}
						}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }