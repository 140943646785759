import * as firebase from 'firebase/app'

export function initFirebaseService(){
	const firebaseConfig = {
		apiKey: "AIzaSyBeps9Pd-SgafGlQtzmF9Hw7dYtoTMm_Oc",
		authDomain: "tuot-34726.firebaseapp.com",
		databaseURL: "https://tuot-34726.firebaseio.com",
		projectId: "tuot-34726",
		storageBucket: "tuot-34726.appspot.com",
		messagingSenderId: "174765608126",
		appId: "1:174765608126:web:67527172841ad7d0f722ef"
	};

	firebase.initializeApp(firebaseConfig);
}
