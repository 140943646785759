import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		sidebarStatus: false,
		menuSidebarStatus: false,
		sidebarMini: false,
		sidebarMenu: [
			{
				name: 'Panel de Control',
				routeName: 'Dashboard',
				icon: 'mdi-view-dashboard-outline',
				active: true
			},
			{
				name: 'Proyectos',
				routeName: 'Proyectos',
				icon: 'mdi-format-list-text',
				active: false,
				subMenu: [
					{
						name: 'Cerrados',
						routeName: 'ClosedProjects',
						icon: 'mdi-view-dashboard-outline',
						active: false
					}
				]
			},
			{
				name: 'Usuarios',
				routeName: 'Usuarios',
				icon: 'mdi-account-multiple-outline',
				active: false
			},
			{
				name: 'Tareas',
				routeName: 'Consultas',
				icon: 'mdi-clipboard-text-outline',
				active: false
			},
			{
				name: 'RDIs',
				routeName: 'Rdi',
				icon: 'mdi-file-document-multiple-outline',
				active: false
			},
			{
				name: 'Planimetría',
				routeName: 'Planimetria',
				icon: 'mdi-floor-plan',
				active: false
			},
			{
				name: 'Adicionales',
				// routeName: 'Adicionales',
				icon: 'mdi-paperclip',
				active: false
			},
			{
				name: 'Gestión de Documentos',
				icon: 'mdi-file-search-outline',
				active: false
			},
			{
				name: 'Programa de Compras',
				icon: 'mdi-chart-line',
				active: false
			},
			{
				name: 'Sub Contratos',
				icon: 'mdi-clipboard-edit-outline',
				active: false
			},
			{
				name: 'Bodega',
				icon: 'mdi-package-variant-closed',
				active: false
			},
			{
				name: 'Programación',
				icon: 'mdi-calendar-month-outline',
				active: false
			},
		]
	},
	mutations: {
		setSidebarStatus(state, status){
			state.sidebarStatus = status
		},
		setMenuSidebarStatus(state, status){
			state.menuSidebarStatus = status
		},
		toggleSidebarMini(state){
			state.sidebarMini = !state.sidebarMini
		}
	}
})

export default store