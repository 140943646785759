<template>
	<v-container fluid pa-0 my-3 fill-height>
		<v-card class="fill-height pa-0" style="width: 100%;">
			<v-card-text class="pa-0">
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="12" sm="3">
						<v-img
							height="220"
							:src="info.project_image"
							style="border-top-left-radius: 5px;border-bottom-left-radius: 5px;position: relative;"
						>
							<div class="icon-project-select">
								<div v-if="info.deleted_at && info.status && info.status.user">
									<v-chip x-small color="warning">Cerrado por: {{info.status.user.name}}</v-chip>
								</div>
								<div class="d-flex justify-end">
									<v-btn
										:color="info.deleted_at ? '#3EBFA3':'#F4516C'"
										fab
										x-small
										dark
										class="mx-1"
										@click="startDeleteProject()"
										v-if="checkCompanyLevelPermissions(['status_project'])"
									>
										<v-icon dark>{{info.deleted_at ? 'mdi-restore':'mdi-delete'}}</v-icon>
									</v-btn>
									<v-btn
										fab
										dark
										x-small
										color="#5E85EF"
										@click="changeProject()"
									>
										<v-icon dark >mdi-swap-horizontal-bold</v-icon>
									</v-btn>
								</div>
							</div>
						</v-img>
					</v-col>
					<v-col cols="12" sm="9">
						<v-row class="pa-2">
							<v-col cols="12" sm="5">
								<div class="d-flex">
									<v-icon dark class="info-icon mr-2">mdi-information-outline</v-icon>
									<p class="mb-0 font-weight-bold">Información del Proyecto: <span class="font-weight-regular" style="color: #606060;">{{info.name}}</span></p>
								</div>
							</v-col>
							<v-col cols="12" sm="7">
								<div class="d-flex">
									<p class="mb-0 font-weight-bold" style="color: #606060;">ID: <span class="font-weight-regular" style="color: #606060;">{{info.id}}</span></p>
									<v-divider vertical class="mx-2"></v-divider>
									<p class="mb-0 font-weight-bold" style="color: #606060;">Nombre: <span class="font-weight-regular" style="color: #606060;">{{info.name}}</span></p>
									<v-divider vertical class="mx-2"></v-divider>
									<p class="mb-0 font-weight-bold" style="color: #606060;">Monto Contrato: <span class="font-weight-regular" style="color: #606060;" v-if="info.details">${{info.details.contract_value | toThousandFilter}}</span></p>
								</div>
							</v-col>
						</v-row>
						<v-row class="pa-2">
							<v-col cols="12" sm="4" v-if="info.adicionales">
								<p class="mb-0 font-weight-bold">{{info.adicionales.title}} <v-icon :color="info.adicionales.general_status" small>mdi-record-circle</v-icon></p>
								<div class="d-flex align-center" v-for="(graph, index) in info.adicionales.graphs" :key="index">
									<p class="mb-0 caption" style="flex: 1">{{graph.title}}</p>
									<v-progress-linear :value="graph.value" :color="graph.color" rounded class="ml-2" style="flex: 2;"></v-progress-linear>
								</div>
							</v-col>
							<v-col cols="12" sm="4" v-else>
								<p class="mb-0 font-weight-bold"><v-icon small>mdi-close</v-icon> No hay información de adicionales</p>
							</v-col>
							<v-col cols="12" sm="4" v-if="info.rdi">
								<p class="mb-0 font-weight-bold">{{info.rdi.title}} <v-icon :color="info.rdi.general_status" small>mdi-record-circle</v-icon></p>
								<LineChart
									:height="130"
									:chartdata="info.rdi.graph"
									:options="{
										legend: {display: false},
										tooltips: {enabled: false},
										responsive: true,
										maintainAspectRatio: true,
										scales : {
												xAxes: [{ gridLines: {display: false} }],
												yAxes: [{
														stacked: true,
														ticks: {
															min: 0,
															stepSize: 10,
														}
												}]
										}
									}"/>
							</v-col>
							<v-col cols="12" sm="4" v-else>
								<p class="mb-0 font-weight-bold"><v-icon small>mdi-close</v-icon> No hay información de RDI</p>
							</v-col>
							<v-col cols="12" sm="4" v-if="info.programa">
								<p class="mb-0 font-weight-bold">{{info.programa.title}} <v-icon :color="info.programa.general_status" small>mdi-record-circle</v-icon></p>
								<v-row>
									<v-col cols="3" class="px-1" v-for="(graph, index) in info.programa.graphs" :key="index">
										<DoughnutChart
											:chartdata="{
												datasets: [
													{
														backgroundColor: [graph.color,'#D9D9D9'],
														data: [graph.value, 100 - graph.value],
													} 
												]
											}"
											:options="{
												tooltips: {enabled: false},
												cutoutPercentage: 75,
												responsive: true,
												maintainAspectRatio: true
											}"/>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" sm="4" v-else>
								<p class="mb-0 font-weight-bold"><v-icon small>mdi-close</v-icon> No hay información de Programa</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import DoughnutChart from '../../Charts/Doughnut'
import LineChart from '../../Charts/Line'
export default {
	name: 'ProjectListItem',
	components: {DoughnutChart, LineChart},
	props: ['info', 'projectIndex'],
	data: () => ({
		//
	}),
	mounted(){
	},
	methods: {
		changeProject(){
			this.$store.projectsStore.commit('setSelectedProject', this.info)
			this.getConsultasList(this.info.id)
			this.getDashboardInfo()
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		async getConsultasList(project_id){
			if(project_id){
				this.$store.consultasStore.dispatch('getMyTasks', {project_id})
			}
		},
		getDashboardInfo(){
			this.$store.projectsStore.dispatch('getDashboardInfo')
		},
		startDeleteProject(){
			this.$store.projectsStore.commit('setCloseOpenProjectModal', {status: true, info: this.info})
		}
	}
};
</script>

<style scoped>
	.project-detail-card, .project-detail-card p{
		color: #2D4B95;
	}
	.info-icon{
		border-radius: 100%;
		background: #2D4B95;

	}
	.icon-project-select{
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
</style>