<template>
	<v-container fluid pa-0 fill-height>
		<v-card fill-height style="width: 100%;">
			<v-card-text>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="12" class="pb-0">
						<p class="mb-0 font-weight-bold">{{info.title}} <v-icon :color="info.general_status" small>mdi-record-circle</v-icon></p>
					</v-col>
					<v-col cols="4" class="pt-0">
						<p class="mb-0">Velocidad de Solución Promedio</p>
						<p class="mb-0 font-weight-bold">Por Día = {{info.speed_solution.value}}</p>
					</v-col>
					<v-col cols="8" class="pt-0">
						<v-row>
							<v-col cols="2" class="px-1" v-for="(graph, index) in info.circle_graphs" :key="index">
								<DoughnutChart
									:chartdata="{
										datasets: [
											{
												backgroundColor: [graph.color,'#D9D9D9'],
												data: [graph.value, 100 - graph.value],
											} 
										]
									}"
									:options="{
										tooltips: {enabled: false},
										cutoutPercentage: 75,
										responsive: true,
										maintainAspectRatio: true,
										elements: {
											center: {
												text: ''
											}
										}
									}"/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row class="project-detail-card" v-else>
					<v-col cols="5">
						<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
						<v-skeleton-loader type="heading"></v-skeleton-loader>
					</v-col>
					<v-col cols="7">
						<v-skeleton-loader type="list-item"></v-skeleton-loader>
						<v-skeleton-loader type="avatar,avatar,avatar" class="d-flex"></v-skeleton-loader>
					</v-col>
				</v-row>
				<v-divider class="my-3"></v-divider>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="5">
						<p class="mb-0">Tiempo de Respuesta</p>
						<p class="mb-0 font-weight-bold">Por Día = {{info.response_time.value}}</p>
					</v-col>
					<v-col cols="7">
						<p class="mb-0 font-weight-bold">{{info.bottom.graph_title}} <v-icon :color="info.bottom.general_status" small>mdi-record-circle</v-icon></p>
						<div class="d-flex align-center" v-for="(graph, index) in info.bottom.graphs" :key="index">
							<p class="mb-0">{{graph.title}}</p>
							<v-progress-linear :value="graph.value" :color="graph.color" rounded class="ml-2" style="flex: 1;"></v-progress-linear>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import DoughnutChart from '@/components/Charts/Doughnut'
export default {
	name: 'ProjectDetailCardConsultas',
	components: {DoughnutChart},
	props: ['info'],
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>

<style scoped>
	.project-detail-card, .project-detail-card p{
		color: #2D4B95;
	}
</style>