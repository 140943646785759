import Vue from 'vue'
import Vuex from 'vuex'
import {getUsersList, getJobTypes} from '@/helpers/api/user'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		selectedUser: null,
		loadingUserList: true,
		usersList: [],
		loadingJobTypes: true,
		jobTypes: [],
		newUserModal: false,
		editUserModal: false,
		editPermisosUserModal: false,
		editPermisosEmpresaUserModal: false,
		editUserProjectsModal: false,
		selectedUserInfo: null,
		lastProjectJobTypes: null,
		usersListForItems: {
			project_id: null,
			users: []
		},
		loadingUserListForItems: false
	},
	mutations: {
		setSelectedUser(state, user){
			state.selectedUser = user
		},
		setUsersList(state, users){
			state.usersList = users
		},
		setNewUserModal(state, status){
			state.newUserModal = status
		},
		setLoadingJobTypes(state, status){
			state.loadingJobTypes = status
		},
		setJobTypes(state, data){
			state.jobTypes = data
		},
		setLoadingUserList(state, status){
			state.loadingUserList = status
		},
		setSelectedUserInfo(state, info){
			state.selectedUserInfo = info
		},
		setEditUserModal(state, status){
			state.editUserModal = status
		},
		setEditPermisosUserModal(state, status){
			state.editPermisosUserModal = status
		},
		setEditPermisosEmpresaUserModal(state, status){
			state.editPermisosEmpresaUserModal = status
		},
		setEditUserProjectsModal(state, status){
			state.editUserProjectsModal = status
		},
		setLastProjectJobTypes(state, company_id){
			state.lastProjectJobTypes = company_id
		},
		setUsersListForItems(state, users){
			state.usersListForItems = users
		},
		setLoadingUserListForItems(state, status){
			state.loadingUserListForItems = status
		},
	},
	actions: {
		async getUsersList(context, query){
			context.commit('setLoadingUserList', true)
			const data = {
				project_id: query.project_id,
				start: 0,
				limit: 20
			}
			const users = await getUsersList(data)
			if(users.code === 200){
				context.commit('setUsersList', users.users)
			}
			context.commit('setLoadingUserList', false)
		},
		async getUsersListForItems(context, query){
			context.commit('setLoadingUserListForItems', true)
			const data = {
				project_id: query.project_id,
				start: 0,
				limit: 20
			}
			const users = await getUsersList(data)
			if(users.code === 200){
				context.commit('setUsersListForItems', {
					project_id: query.project_id,
					users: users.users
				})
			}
			context.commit('setLoadingUserListForItems', false)
		},
		async jobTypesGet(context, project_id){
			if(context.state.lastProjectJobTypes != project_id || context.state.jobTypes.length == 0){
				context.commit('setLoadingJobTypes', true)
				const jobTypes = await getJobTypes({project_id})
				if(jobTypes.code === 200){
					context.commit('setJobTypes', jobTypes.job_types)
					context.commit('setLastProjectJobTypes', project_id)
				}
				context.commit('setLoadingJobTypes', false)
			}
		},
	}
})

export default store