var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-start align-start",staticStyle:{"position":"relative"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn mx-1",attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{attrs:{"color":_vm.getColor(0),"small":""}},[_vm._v(_vm._s(_vm.getIcon(0)))]),_c('div',{staticClass:"custom-jobtype-text"},[_vm._v("Creador")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getName(0)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn mx-1",attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{attrs:{"color":_vm.getColor(2),"small":""}},[_vm._v(_vm._s(_vm.getIcon(2)))]),_c('div',{staticClass:"custom-jobtype-text"},[_vm._v("Jefe OT")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getName(2)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn mx-1",attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{attrs:{"color":_vm.getColor(4),"small":""}},[_vm._v(_vm._s(_vm.getIcon(4)))]),_c('div',{staticClass:"custom-jobtype-text"},[_vm._v("ITO")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getName(4)))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }