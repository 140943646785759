import Vue from 'vue'
import Vuex from 'vuex'
import {getDashboard, getDashboardRDI} from '@/helpers/api/dashboard'
import {getProjectByID, getProjectsByUser, getProjectSubjects} from '@/helpers/api/project'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		listingType: 1,
		selectedProject: null,
		selectedConsultasInfo: null,
		selectedAvancesInfo: null,
		selectedAdicionalesInfo: null,
		selectedRdiInfo: null,
		projectsList: null,
		closedProjectsList: null,
		projectTypes: null,
		newProjectModal: false,
		editProjectModal: false,
		newCompanyModal: false,
		projectPermissions: null,
		projectSubjects: [],
		loadingProjectSubjects: false,
		closeOpenProjectModal: {
			status: false,
			info: null
		}
	},
	mutations: {
		setListingType(state, type){
			state.listingType = type
		},
		setSelectedProject(state, project){
			if(project){
				localStorage.setItem('selectedProjectID', project.id)
			}
			else{
				localStorage.removeItem('selectedProjectID')
			}
			state.selectedProject = project
		},
		setSelectedConsultasInfo(state, info){
			state.selectedConsultasInfo = info
		},
		setSelectedAvancesInfo(state, info){
			state.selectedAvancesInfo = info
		},
		setSelectedAdicionalesInfo(state, info){
			state.selectedAdicionalesInfo = info
		},
		setSelectedRdiInfo(state, info){
			state.selectedRdiInfo = info
		},
		setProjectsList(state, info){
			state.projectsList = info
		},
		setClosedProjectsList(state, info){
			state.closedProjectsList = info
		},
		setNewProjectModal(state, status){
			state.newProjectModal = status
		},
		setEditProjectModal(state, status){
			state.editProjectModal = status
		},
		setNewCompanyModal(state, status){
			state.newCompanyModal = status
		},
		setProjectTypes(state, data){
			state.projectTypes = data
		},
		setProjectPermissions(state, permissions){
			state.projectPermissions = permissions
		},
		setProjectSubjects(state, subjects){
			state.projectSubjects = subjects
		},
		setLoadingProjectSubjects(state, status){
			state.loadingProjectSubjects = status
		},
		setCloseOpenProjectModal(state, info){
			state.closeOpenProjectModal = info
		},
	},
	actions: {
		async getDashboardInfo(context){
			context.dispatch('getDashboardTasks')
			context.dispatch('getDashboardRDI')
		},
		async getDashboardTasks(context){
			if(context.state.selectedProject){
				context.commit('setSelectedConsultasInfo', null)
				const project_id = context.state.selectedProject.id
				const dashboard = await getDashboard({project_id})
				if(dashboard.code === 200){
					context.commit('setSelectedConsultasInfo', {
						title: 'Consultas y Restricciones',
						semaforo_consultas: dashboard.semaforo_consultas,
						total_title: 'Total Consultas',
						circle_graphs: [
							{
								value: dashboard.total_tasks_percent, 
								title: 'Descripción', 
								color: '#EAB261', 
								center_text: dashboard.total_tasks.toString()
							},
							{
								value: dashboard.ended_tasks_percent, 
								title: 'Descripción', 
								color: '#34BFA3', 
								center_text: dashboard.ended_tasks.toString()
							},
							{
								value: dashboard.pending_tasks_percent, 
								title: 'Descripción', 
								color: '#F45771', 
								center_text: dashboard.pending_tasks.toString()
							}
						],
						speed_solution: {value: dashboard.solution_speed, suffix: 's/d'},
						general_status: 'success',
						adicionales: {
							title: 'Promedio',
							total_title: 'Adicionales',
							speed_solution: {value: dashboard.waiting_time, suffix: 'dias'},
							speed_solution_last: {value: dashboard.waiting_time_last, suffix: 'dias'},
							general_status: 'warning',
							graphs: {
								created_by_week: dashboard.created_by_week,
								solved_by_week: dashboard.solved_by_week
							},
						}
					})
				}
			}
		},
		async getDashboardRDI(context){
			if(context.state.selectedProject){
				context.commit('setSelectedRdiInfo', null)
				const project_id = context.state.selectedProject.id
				const dashboard = await getDashboardRDI({project_id})
				if(dashboard.code === 200){
					context.commit('setSelectedRdiInfo', {
						title: 'RDI',
						semaforo_rdis: dashboard.semaforo_rdis,
						total_title: 'Total RDI',
						circle_graphs: [
							{
								value: dashboard.total_rdis_percent, 
								title: 'Descripción', 
								color: '#EAB261', 
								center_text: dashboard.total_rdis.toString()
							},
							{
								value: dashboard.ended_rdis_percent, 
								title: 'Descripción', 
								color: '#34BFA3', 
								center_text: dashboard.ended_rdis.toString()
							},
							{
								value: dashboard.pending_rdis_percent, 
								title: 'Descripción', 
								color: '#F45771', 
								center_text: dashboard.pending_rdis.toString()
							}
						],
						speed_solution: {value: dashboard.solution_speed, suffix: 's/d'},
						general_status: 'success',
						adicionales: {
							title: 'Promedio',
							total_title: 'Adicionales',
							speed_solution: {value: dashboard.waiting_time, suffix: 'dias'},
							speed_solution_last: {value: dashboard.waiting_time_last, suffix: 'dias'},
							general_status: 'warning',
							graphs: {
								created_by_week: dashboard.created_by_week,
								solved_by_week: dashboard.solved_by_week
							},
						}
					})
				}
			}
		},
		async getUserProjects(context){
			const projects = await getProjectsByUser()
			if(projects.code === 200){
				context.commit('setProjectsList', projects.projects)
			}
		},
		async getProjectSubjects(context, projectID){
			context.commit('setLoadingProjectSubjects', true)
			const subjects = await getProjectSubjects({project_id: projectID})
			if(subjects.code === 200){
				context.commit('setProjectSubjects', subjects.subjects)
			}
			context.commit('setLoadingProjectSubjects', false)
		},
		async getSelectedProject(context, projectID){
			if(projectID){
				context.commit('setProjectPermissions', null)
				const project = await getProjectByID({project_id: projectID})
				if(project.code === 200){
					context.commit('setSelectedProject', project.project)
					context.commit('setProjectPermissions', project.permissions)
				}
				else{
					context.commit('setSelectedProject', null)
				}
			}
			else{
				const projects = await getProjectsByUser()
				if(projects.code === 200){
					context.commit('setProjectsList', projects.projects)
				}

				let project_id = localStorage.getItem('selectedProjectID')
				if(parseInt(project_id)){
					project_id = parseInt(project_id)
					context.commit('setSelectedProject', 1)
					const project = await getProjectByID({project_id})
					if(project.code === 200){
						context.commit('setSelectedProject', project.project)
						context.commit('setProjectPermissions', project.permissions)
					}
					else{
						context.commit('setSelectedProject', null)
						context.commit('setProjectPermissions', null)
					}
				}
				else{
					if(projects.code === 200 && projects.projects[0]){
						context.commit('setSelectedProject', projects.projects[0])
					}
				}
			}
			return new Promise((resolve) => {
				resolve(true)
			})
		}
	}
})

export default store