import {endpoints} from './endpoints'
import request from '../request'

export function getDashboard(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getDashboard,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getDashboardRDI(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getDashboardRDI,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}