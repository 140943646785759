import moment from 'moment'

const filters = {
	toThousandFilter(num) {
		const number = (+num || 0).toString().split('.')[0]
		const decimal = (+num || 0).toString().split('.')[1]
		let formatted = number.replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'))
		if(decimal) formatted += ',' + decimal
		return formatted
	},
	toDateTime(date){
		return moment(date).format('DD-MM-YYYY HH:mm')
	},
	toDate(date, fromFormat = null, format = 'DD-MM-YYYY'){
		if(fromFormat){
			return moment(date, fromFormat).format(format)
		}
		return moment(date).format(format)
	},
	toHour(date){
		return moment(date).format('HH:mm')
	},
	oneDecimalsNumber(number){
		return Math.round(number * 10) / 10
	},
	waitingTime(item){
		const now = moment()
		const item_date = moment(item)
		if(now.diff(item_date, 'days') <= 0){
			return now.diff(item_date, 'hours') + ' Horas'
		}
		return now.diff(item_date, 'days') + ' Días'
	},
	getColorStatusModulo(semaforo_consultas){
		let color = '#34BFA3'
		if(semaforo_consultas >= 2){
			color = '#F45771'
		}
		else if(semaforo_consultas == 1){
			color = '#EAB261'
		}
		return color
	},
	getTextStatusModulo(semaforo_consultas){
		let text = 'Bien'
		if(semaforo_consultas >= 2){
			text = 'Malo'
		}
		else if(semaforo_consultas == 1){
			text = 'Regular'
		}
		return text
	},
	rdiResponsableUserType(response_status){
		if(response_status == 1){
			return 'ITO'
		}
		else if(response_status == 2){
			return 'Jefe OT'
		}
	},
	additionalResponsableUserType(response_status){
		if(response_status == 1){
			return 'ITO'
		}
		else if(response_status == 2){
			return 'Jefe OT'
		}
	},
	seenByMe(item){
		return item && item.seen_status === 1
	},
	seenByMeColor(item){
		if(item){
			if(item.seen_status === 0) return 'high'
			if(item.seen_status === 1) return 'success'
			if(item.seen_status === 2) return 'warning'
		}
		return 'high'
	},
	seenByMeIconAnimation(item){
		if(item && item.seen_status === 1){
			return ''
		}
		return 'animate__animated animate__wobble animate__infinite'
	},
	seenByMeText(item){
		if(item && item.seen_status === 1){
			return 'Ya visualizaste esta'
		}
		return 'No has atendido esta'
	},
	toDataSize(bytes){
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
	},
	highligthSearch(text, search){
		if(text && search && text != '' && search != ''){
			var regEx = new RegExp(search + '(?!([^<]+)?>)', "ig")
			return text.replace(regEx, function(a){
				return '<span class="highlight">' + a + '</span>'
			})
		}
		return text
	}
}

export default filters