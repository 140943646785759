<template>
	<v-container fluid id="consultas-container" class="d-flex flex-column">
		<v-btn-toggle style="width: 100%;" v-if="!$vuetify.breakpoint.mobile">
			<v-btn class="custom-button-consultas"
				:class="this.$store.consultasStore.state.selectedType === 1 ? 'selected':''" tile
				:disabled="!checkPermissions(['create_tasks'])"
				@click="$store.consultasStore.commit('setCreateTareaModal', true)">
				<v-icon>mdi-notebook-plus-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Nueva Tarea</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.consultasStore.state.selectedType === 2 ? 'selected':''" tile
				@click="changeListing(2)">
				<v-icon>mdi-file-document-multiple-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Mis Tareas</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.consultasStore.state.selectedType === 3 ? 'selected':''" tile
				@click="changeListing(3)">
				<v-icon>mdi-view-list-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Pendientes</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.consultasStore.state.selectedType === 4 ? 'selected':''" tile
				@click="changeListing(4)">
				<v-icon>mdi-text-box-check-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Finalizadas</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.consultasStore.state.selectedType === 5 ? 'selected':''" tile
				@click="changeListing(5)">
				<v-icon>mdi-account-group-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Pendientes Por Usuario</p>
			</v-btn>
			<!-- <v-btn class="custom-button-consultas" :class="selected === 5 ? 'selected':''" tile
				@click="selected = 5">
				<v-icon>mdi-account-group-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Por Usuario</p>
			</v-btn> -->
			<div class="d-flex align-center justify-end flex-grow-1 px-3">
				<v-btn rounded small color="#AAAAAA"
					:disabled="loadingExport"
					@click="excelExport()">
					<div class="d-flex align-center" v-if="loadingExport">
						<v-progress-circular indeterminate color="white" :width="2" :size="15"></v-progress-circular>
						<p class="mb-0 ml-2 white--text">Preparando Excel</p>
					</div>
					<div class="d-flex align-center" v-else>
						<v-icon color="white">mdi-microsoft-excel</v-icon>
						<p class="mb-0 ml-2 white--text">Exportar a Excel</p>
					</div>
				</v-btn>
			</div>
			<v-menu v-model="searchMenu" :offset-y="true" :close-on-content-click="false" contentClass="custom-content-search elevation-0">
				<template v-slot:activator="{ on, attrs }">
					<v-btn text class="d-flex pa-0 justify-center" style="height: auto;"
						v-bind="attrs"
						v-on="on">
						<v-badge
							v-if="$store.consultasStore.state.searchFilter && $store.consultasStore.state.searchFilter != ''"
							class="animate__animated animate__tada animate__infinite"
							color="success"
							icon="mdi-magnify-expand"
							light
						>
								<v-icon color="#5e85ef" class="ml-1">mdi-magnify</v-icon>
						</v-badge>
						<v-icon v-else color="#5e85ef" class="ml-1">mdi-magnify</v-icon>
					</v-btn>
				</template>
				<v-text-field
						outlined
						clearable
						dense
						rounded
						hide-details
						label="Búsqueda por título"
						type="text"
						class="search-input"
						v-model="searchInput"
						@keydown="sendToSearch"
						@click:clear="sendToSearchClickClear"
					>
						<template v-slot:append>
							<v-btn
								class="search-button elevation-0"
								fab
								dark
								small
								color="#5E85EF"
								@click="sendToSearchClick"
							>
								<v-icon dark>mdi-magnify</v-icon>
							</v-btn>
						</template>
						<template v-slot:label>
							<p class="mb-0 ml-5 caption">Búsqueda por título</p>
						</template>
				</v-text-field>
			</v-menu>
		</v-btn-toggle>
    <v-menu offset-y v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
					rounded
					color="#2D4B95"
					dark
					small
					block
          v-bind="attrs"
          v-on="on"
        >
          Seleccione Acción
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.consultasStore.state.selectedType === 1 ? 'selected':''" tile
						:disabled="!checkPermissions(['create_tasks'])"
						@click="$store.consultasStore.commit('setCreateTareaModal', true)">
						<v-icon>mdi-notebook-plus-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Nueva Tarea</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.consultasStore.state.selectedType === 2 ? 'selected':''" tile
						@click="changeListing(2)">
						<v-icon>mdi-file-document-multiple-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Mis Tareas</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.consultasStore.state.selectedType === 3 ? 'selected':''" tile
						@click="changeListing(3)">
						<v-icon>mdi-view-list-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Pendientes</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.consultasStore.state.selectedType === 4 ? 'selected':''" tile
						@click="changeListing(4)">
						<v-icon>mdi-text-box-check-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Finalizadas</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.consultasStore.state.selectedType === 5 ? 'selected':''" tile
						@click="changeListing(5)">
						<v-icon>mdi-account-group-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Pendientes Por Usuario</p>
					</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
		<MisTareas v-if="this.$store.consultasStore.state.selectedType === 2"/>
		<ListadoConsultas
			ref="listadoConsultas"
			v-if="(this.$store.consultasStore.state.selectedType === 3 || this.$store.consultasStore.state.selectedType === 4) && !$vuetify.breakpoint.mobile"
			:ended="this.$store.consultasStore.state.selectedType === 4"
		/>
		<ListadoConsultasMobile
			ref="listadoConsultas"
			v-if="(this.$store.consultasStore.state.selectedType === 3 || this.$store.consultasStore.state.selectedType === 4) && $vuetify.breakpoint.mobile"
			:ended="this.$store.consultasStore.state.selectedType === 4"
		/>
		<TareasByUser v-if="this.$store.consultasStore.state.selectedType === 5" />
	</v-container>
</template>

<script>
import MisTareas from './MisTareas'
import ListadoConsultas from './ListadoConsultas'
import ListadoConsultasMobile from './ListadoConsultasMobile'
import TareasByUser from './TareasByUser'
import {exportTasksByProject} from '@/helpers/api/exports'
// import {list} from '@/helpers/api/tasks'
export default {
	name: 'ConsultasContainer',
	components: {MisTareas, ListadoConsultas, ListadoConsultasMobile, TareasByUser},
	data: () => ({
		searchInput: null,
		searchMenu: false,
		createTareaModal: false,
		loadingExport: false
	}),
	mounted(){
		// this.getConsultasList()
		if(this.$store.consultasStore.state.taskCodes.length === 0){
			this.getTaskCodes()
		}
	},
	methods: {
		sendToSearch(event){
      if (event.keyCode === 13) { 
				this.$store.consultasStore.commit('setSearchFilter', this.searchInput)
				this.searchMenu = false
      }
		},
		sendToSearchClick(){
			this.$store.consultasStore.commit('setSearchFilter', this.searchInput)
			this.searchMenu = false
		},
		sendToSearchClickClear(){
			this.searchInput = null
			this.searchMenu = false
			this.$store.consultasStore.commit('setSearchFilter', this.searchInput)
		},
		async getTaskCodes(){
			this.$store.consultasStore.dispatch('getTaskCodes')
		},
		changeListing(selected){
			if(this.$store.consultasStore.state.selectedType != selected){
				this.$store.consultasStore.commit('setSelectedType', selected)
				this.getConsultasList()
				this.$refs.listadoConsultas && this.$refs.listadoConsultas.clearFilters()
			}
		},
		async getConsultasList(){
			let project_id = null
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.consultasStore.dispatch('getMyTasks', {
							project_id,
							search: this.searchInput
						})
			}
		},
		async excelExport(){
			this.loadingExport = true
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const exportRdi = await exportTasksByProject(data)
			if(exportRdi.code == 200){
				window.open(exportRdi.url);
			}
			this.loadingExport = false
		}
	}
};
</script>

<style scoped>
	.text-primary{color: #2D4B95;}
	.custom-button-consultas.v-btn{
		background-color: white;
		color: #2D4B95;
		opacity: 1!important;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon,
	/deep/ .custom-button-consultas.v-btn p{
		color: #2D4B95!important;
		font-size: 13px;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon{font-size: 20px;}
	/deep/ .custom-button-consultas.v-btn:hover .v-icon,
	/deep/ .custom-button-consultas.v-btn:hover p,
	/deep/ .custom-button-consultas.v-btn.selected .v-icon,
	/deep/ .custom-button-consultas.v-btn.selected p{
		color: white!important;;
	}
	.custom-button-consultas.selected,
	.custom-button-consultas:hover{
		background-color: #2D4B95!important
	}
	.custom-content-search{
		background-color: transparent;
		padding-top: 7px;
		width: 400px;
	}
	.search-input{
		position: relative;
		background-color: white;
		border-color: red;
	}
	/deep/ .search-input input{margin-left: 20px;}
	.search-button{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	.search-container{
		position: absolute;
		width: 400px;
		z-index: 999;
	}
</style>