import Vue from 'vue'
import Vuex from 'vuex'
import {getUser, saveUserProjectOptions} from '@/helpers/api/user'

Vue.use(Vuex)
const token = localStorage.getItem('userToken')

const store = new Vuex.Store({
	state: {
		userInfo: null,
		isAuthenticated: token ? true:false,
		userCompanies: [],
		selectedCompany: null,
		companyPermissions: null,
	},
	mutations: {
		setUserInfo(state, user){
			state.userInfo = user
			state.isAuthenticated = true
		},
		setIsAuthenticated(state, status){
			state.isAuthenticated = status
		},
		setUserCompanies(state, companies){
			state.userCompanies = companies
		},
		logoutUser(state) {
			state.userInfo = null
			state.isAuthenticated = null
			localStorage.removeItem('userToken')
			localStorage.removeItem('selectedProjectID')
		},
		setCompanyPermissions(state, permissions){
			state.companyPermissions = permissions
		},
		setSelectedCompany(state, company){
			state.selectedCompany = company
		}
	},
	getters: {
		authState: state => {
			return state.isAuthenticated
		}
	},
	actions: {
		async getUserInfo(context){
			const user = await getUser()
			if(user.code == 200){
				context.commit('setUserInfo', user.user)
				context.commit('setUserCompanies', user.user.companies)
			}
		},
		async setUserProjectOptions(context, data){
			await saveUserProjectOptions(data)
			return new Promise((resolve) => {
				resolve(true)
			})
		}
	}
})

export default store