<template>
	<v-container fluid pa-0 mx-2 style="width: auto;"
		:style="menuOpen ? 'z-index: 999;':''">
		<v-overlay absolute :value="menuOpen"></v-overlay>
		<v-menu
			bottom
			offset-y
			left
			min-width="500"
			max-width="500"
			content-class="container-notification-menu-header"
			@input="changeMenu"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-badge
					:color="$store.notificationsStore.state.badgeColor"
					:content="$store.notificationsStore.state.badgeText"
					overlap
				>
					<v-btn
						fab
						dark
						:small="size === 'small'"
						:x-small="size === 'x-small'"
						:loading="$store.notificationsStore.state.loadingNotifications"
						color="#5E85EF"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon dark>mdi-bell-outline</v-icon>
					</v-btn>
				</v-badge>
			</template>
			<div class="d-flex flex-row align-center justify-space-between">
				<div class="d-flex flex-row px-4" style="border-left: 2px solid #2D4B95;">
					<v-icon dark color="#2D4B95" class="mr-5">mdi-bell-outline</v-icon>
					<p class="mb-0 font-weight-bold" style="color: #2D4B95;">Notificaciones</p>
				</div>
				<div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon color="grey" class="mr-3"
										v-bind="attrs"
										v-on="on"
										@click="markAllNotificationsAsRead">
								<v-icon>mdi-email-open-multiple-outline</v-icon>
							</v-btn>
						</template>
						<span>Marcar todo como visto</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon color="grey" class="mr-3"
										v-bind="attrs"
										v-on="on"
										@click="reloadNotifications">
								<v-icon>mdi-cached</v-icon>
							</v-btn>
						</template>
						<span>Recargar notifications</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider class="mx-2"></v-divider>
			<v-list v-if="$store.notificationsStore.state.notifications.length > 0">
				<v-list-item
					v-for="(item, i) in $store.notificationsStore.state.notifications"
					:key="i"
          class="px-0 py-0"
          @click="viewItem(item.item_id, item.item_type, item.id, i)"
				>
					<div style="width: 100%">
						<div class="d-flex flex-row px-4 pt-2 justify-space-between">
							<div class="d-flex flex-row">
								<img v-if="item.image && item.image !== ''"
									:src="item.image" class="notification-img"/>
								<v-icon v-else class="notification-img icon" color="#2D4B95" >mdi-account</v-icon>
								<div class="px-4 caption">
									<small class="mb-0" style="color: #707070;">{{ item.project_name }}</small>
									<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.title }}</p>
									<div class="mb-0 caption notification-description" style="color: #707070;" v-html="item.description">{{ item.description }}</div>
								</div>
							</div>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon
										@click.stop="markNotificationAsRead(item.id, i)"
										v-bind="attrs"
										v-on="on">
										<v-icon
											:color="loadingIndexes.includes(i) ? 'success':'grey lighten-1'"
											>mdi-check-all</v-icon>
									</v-btn>
								</template>
								<span>Marcar como vista</span>
							</v-tooltip>
						</div>
						<v-divider class="mt-2 mx-2"></v-divider>
					</div>
				</v-list-item>
			</v-list>
			<div v-else class="d-flex flex-column align-center justify-center">
				<v-icon class="mt-3 mb-2" large>mdi-check-circle-outline</v-icon>
				<p class="body-2">No hay notificaciones nuevas</p>
			</div>
		</v-menu>
	</v-container>
</template>

<script>
import {getTareaByID, markTaskAsRead} from '@/helpers/api/tasks'
import {getRdiByID, markRdiAsRead} from '@/helpers/api/rdi'
import {markNotificationAsReaded} from '@/helpers/api/general'

export default {
	name: 'NotificationHeader',
	props: {
		size: {
			type: String,
			default: 'small'
		}
	},
	components: {
	},
  computed: {
    loadingNotifications() {
      return this.$store.notificationsStore.state.loadingNotifications
    }
  },
  watch: {
    loadingNotifications(newData) {
      if(newData){
				this.reloading = true
      }
			else if(this.reloading){
				this.reloading = false
				this.loadingIndexes = []
				this.loadingTaskIndexes = []
				this.loadingRdiIndexes = []
			}
    }
  },
	data: () => ({
		reloading: false,
		loadingIndexes: [],
		loadingTaskIndexes: [],
		loadingRdiIndexes: [],
		menuOpen: false
	}),
	mounted(){
	},
	methods: {
		reloadNotifications(){
			this.$store.notificationsStore.dispatch('getNotifications', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
		},
		async viewTarea(task_id){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				this.reloadNotifications()
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = false
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		async viewRDI(rdi_id){
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				this.reloadNotifications()
				let rdiData = rdi.rdi
				rdiData.prev_seen_by_me = false
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
		},
		async markAsRead(item_id, type, index){
			if(type == 1){
				this.loadingTaskIndexes.push(index)
				const tasks = await markTaskAsRead({task_id: item_id})
				if(tasks.code == 200){
					this.reloadNotifications()
				}
			}
			else if(type == 2){
				this.loadingRdiIndexes.push(index)
				const tasks = await markRdiAsRead({rdi_id: item_id})
				if(tasks.code == 200){
					this.reloadNotifications()
				}
			}
		},
		async viewItem(item_id, type, notification_id, index){
			if(type == 1){
				this.viewTarea(item_id)
			}
			else if(type == 2){
				this.viewRDI(item_id)
			}
			this.markNotificationAsRead(notification_id, index)
		},
		async markNotificationAsRead(notification_id, index){
				this.loadingIndexes.push(index)
				const tasks = await markNotificationAsReaded({id: notification_id})
				if(tasks.code == 200){
					this.reloadNotifications()
				}
		},
		async markAllNotificationsAsRead(){
			const notification_ids = this.$store.notificationsStore.state.notifications.map((item) => {
				return item.id
			})
			if(notification_ids.length > 0){
				const tasks = await markNotificationAsReaded({ids: notification_ids})
				if(tasks.code == 200){
					this.reloadNotifications()
				}
			}
		},
		changeMenu(value){
			this.menuOpen = value
		}
	}
};
</script>

<style scoped>
	.container-notification-menu-header{
		background: white;
		margin-top: 10px;
		border-radius: 8px;
	}
	.notification-img{
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}
	.notification-img.icon{
		background-color: #F0F2F8;
	}
	/deep/ .notification-description p{margin: 0;}
</style>
