var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"width":"auto"},style:(_vm.menuOpen ? 'z-index: 999;':''),attrs:{"fluid":"","pa-0":"","mx-2":""}},[_c('v-overlay',{attrs:{"absolute":"","value":_vm.menuOpen}}),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":"","min-width":"500","max-width":"500","content-class":"container-notification-menu-header"},on:{"input":_vm.changeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":_vm.$store.notificationsStore.state.badgeColor,"content":_vm.$store.notificationsStore.state.badgeText,"overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":_vm.size === 'small',"x-small":_vm.size === 'x-small',"loading":_vm.$store.notificationsStore.state.loadingNotifications,"color":"#5E85EF"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-bell-outline")])],1)],1)]}}])},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c('div',{staticClass:"d-flex flex-row px-4",staticStyle:{"border-left":"2px solid #2D4B95"}},[_c('v-icon',{staticClass:"mr-5",attrs:{"dark":"","color":"#2D4B95"}},[_vm._v("mdi-bell-outline")]),_c('p',{staticClass:"mb-0 font-weight-bold",staticStyle:{"color":"#2D4B95"}},[_vm._v("Notificaciones")])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":"","color":"grey"},on:{"click":_vm.markAllNotificationsAsRead}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-open-multiple-outline")])],1)]}}])},[_c('span',[_vm._v("Marcar todo como visto")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":"","color":"grey"},on:{"click":_vm.reloadNotifications}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Recargar notifications")])])],1)]),_c('v-divider',{staticClass:"mx-2"}),(_vm.$store.notificationsStore.state.notifications.length > 0)?_c('v-list',_vm._l((_vm.$store.notificationsStore.state.notifications),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-0 py-0",on:{"click":function($event){return _vm.viewItem(item.item_id, item.item_type, item.id, i)}}},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row px-4 pt-2 justify-space-between"},[_c('div',{staticClass:"d-flex flex-row"},[(item.image && item.image !== '')?_c('img',{staticClass:"notification-img",attrs:{"src":item.image}}):_c('v-icon',{staticClass:"notification-img icon",attrs:{"color":"#2D4B95"}},[_vm._v("mdi-account")]),_c('div',{staticClass:"px-4 caption"},[_c('small',{staticClass:"mb-0",staticStyle:{"color":"#707070"}},[_vm._v(_vm._s(item.project_name))]),_c('p',{staticClass:"mb-0 caption font-weight-bold",staticStyle:{"color":"#707070"}},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"mb-0 caption notification-description",staticStyle:{"color":"#707070"},domProps:{"innerHTML":_vm._s(item.description)}},[_vm._v(_vm._s(item.description))])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.markNotificationAsRead(item.id, i)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.loadingIndexes.includes(i) ? 'success':'grey lighten-1'}},[_vm._v("mdi-check-all")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcar como vista")])])],1),_c('v-divider',{staticClass:"mt-2 mx-2"})],1)])}),1):_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-icon',{staticClass:"mt-3 mb-2",attrs:{"large":""}},[_vm._v("mdi-check-circle-outline")]),_c('p',{staticClass:"body-2"},[_vm._v("No hay notificaciones nuevas")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }