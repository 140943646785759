<template>
	<div class="d-flex align-center flex-wrap flex-grow-1 mx-2">
		<v-btn
			class="mx-2 my-1"
			rounded
			color="#5E85EF"
			dark
			small
			@click="() => $store.usersStore.commit('setNewUserModal', true)"
		>
			<v-icon dark small>mdi-square-edit-outline</v-icon>
			<p class="mb-0 ml-2">Añadir</p>
		</v-btn>
		<v-btn
			class="mx-2 my-1"
			rounded
			color="#34BFA3"
			dark
			small
			@click="exportUsers()"
		>
			<v-icon dark small>mdi-plus-circle-outline</v-icon>
			<p class="mb-0 ml-2">Exportar</p>
		</v-btn>
	</div>
</template>
<script>
import {projectUserExport} from '@/helpers/api/exports'

export default {
	name: 'UserActionButtons',
	data: () => ({
		//
	}),
	mounted(){
	},
	methods: {
		async exportUsers(){
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const exportUsers = await projectUserExport(data)
			if(exportUsers.code == 200){
				window.open(exportUsers.url);
			}
		}
	}
};
</script>