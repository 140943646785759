<template>
	<v-container class="d-flex flex-row flex-nowrap file-upload-center-container py-2 px-3"
		v-if="Object.keys($store.globalStore.state.filesToUploadCenter).length > 0">
		<div class="file-upload-center-inner-container">
			<p class="font-weight-bold body-2 text-primary mb-1">Subiendo archivos ({{Object.keys($store.globalStore.state.filesToUploadCenter).length}})</p>
			<div v-for="(fileKey, index) in Object.keys($store.globalStore.state.filesToUploadCenter)" :key="index" style="width: 100%">
				<div class="mb-1">
					<div class="d-flex flex-row justify-space-between">
						<p class="caption mb-0 text--secondary">{{$store.globalStore.state.filesToUploadCenter[fileKey].type | uploadType}} - {{$store.globalStore.state.filesToUploadCenter[fileKey].description}}</p>
						<p class="caption mb-0 text--secondary">{{$store.globalStore.state.filesToUploadCenter[fileKey].loaded | fileSize}}/{{$store.globalStore.state.filesToUploadCenter[fileKey].total | fileSize}}@{{$store.globalStore.state.filesToUploadCenter[fileKey] | uploadSpeed}}</p>
					</div>
					<p class="caption mb-0"><span class="font-weight-bold light-blue-text">{{$store.globalStore.state.filesToUploadCenter[fileKey].file.name}}</span></p>
				</div>
				<v-progress-linear
					v-if="$store.globalStore.state.filesToUploadCenter[fileKey].status"
					class="listing-loading-bar"
					buffer-value="0"
					:value="$store.globalStore.state.filesToUploadCenter[fileKey].loaded*100/$store.globalStore.state.filesToUploadCenter[fileKey].total"
					stream
					color="#5e85ef"
				></v-progress-linear>
				<v-divider class="my-2"
					v-if="Object.keys($store.globalStore.state.filesToUploadCenter).length > 0 && index < Object.keys($store.globalStore.state.filesToUploadCenter).length - 1"></v-divider>
			</div>
		</div>
	</v-container>
</template>

<script>
import moment from 'moment'
export default {
	name: 'FileUploadCenter',
	components: { },
	filters: {
		uploadType: function (value) {
			const types = ['', 'Tarea', 'Interacción Tarea', 'RDI', 'Interacción RDI', 'Adicional', 'Posible Adicional', 'Posible Adicional', 'Archivo Forge']
			return types[value]
		},
		fileSize: function (value) {
			const size = Math.round(value/10000) / 100 + 'Mb'
			return size
		},
		uploadSpeed: function (file) {
			const now = moment()
			const diff = now.diff(file.start, 'seconds')
			const speed = ( Math.round( (file.loaded/10000) / diff ) / 100 ).toFixed(2) + 'Mbps'
			return speed
		}
	},
	data: () => ({}),
	mounted(){
		this.startProcessingFiles()
	},
	methods: {
		startProcessingFiles(){
			
		}
	}
};
</script>

<style scoped>
	.text-primary{color: #2D4B95;}
	.light-blue-text{color: #5E85EF;}
	.file-upload-center-container{
		position: fixed;
		bottom: 55px;
		right: 20px;
		background-color: white;
		max-width: 420px;
		max-height: 500px;
		border-radius: 10px;
		border: 2px solid #2D4B95;
		z-index: 99999;
	}
	.file-upload-center-inner-container{
		width: 100%;
	}
</style>