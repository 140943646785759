<template>
  <div class="text-center">
    <v-dialog
      v-model="updateVersionModal"
      max-width="500"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <p class="mb-0 font-weight-bold text-primary title">Actualización Importante</p>
              </div>
            </div>
            <v-row align="center" class="my-2">
              <v-col cols="12">
                <p class="font-weight-bold text-primary">Hay actualizaciones importantes en el panel LaOT</p>

                <div class="d-flex">
									<v-icon small color="#2D4B95" class="mr-1 text-primary">mdi-file-document-multiple-outline</v-icon>
									<p class="mb-0 font-weight-bold">RDI</p>
								</div>
								<p class="mb-0">La nueva versión del panel implementa un cambio en el sistema de cierre de las RDI.</p>
								<p>Este sistema se basa en un proceso de aprobación de 3 partes. Esto significa que para generar el cierre de una RDI esta debe haber sido aprobada por las 3 partes involucradas: Usuario Creador, Usuario Jefe OT y Usuario ITO.</p>
								<p class="font-weight-bold">Puedes cambiar la forma de cierre en cualquier momento desde el panel lateral derecho en las opciones del proyecto, eligiendo el sistema antiguo o el nuevo.</p>
								<p class="caption mb-2">Puedes abrir este panel presionando el botón en la parte superior derecha, el mismo que ves a continuación:</p>
								<v-btn
									dark
									tile
									:rounded="false"
									color="#5E85EF"
									class="pa-0 mb-4"
									style="background: #5E85EF;aspect-ratio: 1;min-width: auto;"
									@click="toggleMenu"
								>
									<v-icon dark>mdi-expand-all-outline</v-icon>
								</v-btn>
								<p class="font-weight-bold">Todos los proyectos ya existenten mantendrán el sistema antiguo por defecto, los proyectos creados desde hoy utilizarán el sistema nuevo. En ambos podrás realizar el cambio que gustes.</p>

                <div class="d-flex">
									<v-icon small color="#2D4B95" class="mr-1 text-primary">mdi-floor-plan</v-icon>
									<p class="mb-0 font-weight-bold">Planimetría</p>
								</div>
								<p>Esta actualización incluye un módulo para visualización de modelos Autodesk.</p>

                <div class="d-flex">
									<v-icon small color="#2D4B95" class="mr-1 text-primary">mdi-clouds</v-icon>
									<p class="mb-0 font-weight-bold">Reporte del clima</p>
								</div>
								<p class="mb-0">Podrás mantenerte informado respecto al clima en la zona donde se encuentre tu proyecto.</p>
								<p>Para esto sólo deberás agregar la ciudad o comuna donde se encuentra tu proyecto.</p>
							</v-col>
						</v-row>
						<v-btn
							rounded
							color="#2D4B95"
							raised
							dark
							block
							@click="() => $store.generalStore.commit('setUpdateVersionModal', false)"
						>
							<p class="mb-0">Genial!</p>
						</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'UpdateVersion',
  computed: {
    updateVersionModal() {
      return this.$store.generalStore.state.updateVersionModal
    }
  },
  data: () => ({
  }),
  mounted(){
  },
  methods: {
		toggleMenu(){
			this.$store.menuStore.commit('setSidebarStatus', !this.$store.menuStore.state.sidebarStatus)
		},
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .interaction-container.response{
    background-color: #CAE7CC;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .icon-top{
    padding: 2px;
    margin: 0 2px;
    border-radius: 100%;
  }
  .icon-top.high{
    background-color: #F4516C;
  }
  .icon-top.medium{
    background-color: #fb8c00;
  }
  .icon-top.normal{
    background-color: #3EBFA3;
  }
  .icon-top.primary{
    background-color: #2D4B95;
  }
  .icon-top.light-blue{
    background-color: #5E85EF;
  }
  .parent-subtareas{
    display: flex;
    flex-wrap: wrap;
  }
  .parent-subtareas .subtareas-checkbox{
    flex: 1 0 30%;
  }
  .notification-img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
</style>