<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.projectsStore.state.newProjectModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Creación de Proyecto</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mt-4">
                <v-col cols="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Empresa</h2>
                </v-col>
                <v-col cols="9" class="py-0">
                  <div class="d-flex flex-row">
                    <v-select
                      v-model="newProjectData.company_id"
                      :items="$store.userStore.state.userCompanies"
                      :loading="!$store.userStore.state.userInfo"
                      item-text="company.name"
                      item-value="company.id"
                      label="Seleccione Empresa"
                      single-line
                      outlined
                      dense
                      required
                      :rules="requiredRule"
                    ></v-select>
                    <v-btn class="mx-2" fab dark x-small color="#5E85EF"
                      v-if="checkCompanyLevelPermissions(['create_company'])"
                      @click="$store.projectsStore.commit('setNewCompanyModal', true)">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">ID</h2>
                  <p class="mb-0 caption">Identificador de tu proyecto</p>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newProjectData.internal_id"
                    label="ID"
                    placeholder="Ingrese id del proyecto"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Nombre</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newProjectData.name"
                    label="Nombre"
                    placeholder="Ingrese nombre del proyecto"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Descripción</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newProjectData.description"
                    label="Descripción"
                    placeholder="Ingrese breve descripción del proyecto"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Descripción</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newProjectData.description"
                    label="Descripción"
                    placeholder="Ingrese breve descripción del proyecto"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Dirección</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newProjectData.address"
                    label="Dirección"
                    placeholder="Ingrese dirección del proyecto"
                    outlined
                    required
                    dense
                    class="custom-text-input"
                    :rules="requiredRule"
                  >
                    <template v-slot:append>
                      <v-btn
                        icon
                        color="#5E85EF"
                        @click="setShowMap"
                      >
                        <v-icon large>mdi-map-marker-check-outline</v-icon>
                      </v-btn>
                    </template>
									</v-text-field>
                </v-col>
								<v-col cols="12" v-if="showMap">
									<l-map
										style="height: 300px;width: 100%;"
										:zoom="zoom"
										:center="center"
										@move="updateCenter">
										<l-tile-layer
											:url="url"
											:attribution="attribution"
										/>
										<l-marker v-if="markerLatLng" :lat-lng="markerLatLng"></l-marker>
									</l-map>
								</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Imagen Proyecto</h2>
                  <p class="mb-0 caption">(Opcional)</p>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-file-input
                    v-model="newProjectData.project_image"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Imagen portada proyecto"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Tipo</h2>
                </v-col>
                <v-col cols="9" class="py-0">
                  <v-select
                    v-model="newProjectData.project_type_id"
                    :items="$store.projectsStore.state.projectTypes"
                    item-text="name"
                    item-value="id"
                    label="Seleccione Tipo"
                    single-line
                    outlined
                    dense
                    required
                    :rules="requiredRule"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-4 mb-8">
                <v-col cols="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Especialidades</h2>
                  <p class="mb-0 caption">(Opcional)</p>
                </v-col>
                <v-col cols="9" class="py-0">
                  <v-text-field
                    v-model="newEspecialidad"
                    label="Especialidad"
                    placeholder="Escribe la nueva especialidad y presiona enter o el botón"
                    outlined
                    dense
                    hide-details
                    class="custom-text-input"
                    @keydown.enter="addNewEspecialidad()"
                  >
                    <template v-slot:append>
                      <v-btn
                        icon
                        :disabled="!newEspecialidad || newEspecialidad === ''"
                        color="#5E85EF"
                        @click="addNewEspecialidad()"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <v-list-item-group>
                      <v-list-item  dense v-for="(especialidad, i) in especialidades" :key="i">
                        <template v-slot:default>
                          <v-list-item-action dense class="my-0 py-0">
                            <v-btn
                              icon
                              color="#F4516C"
                              @click="removeEspecialidad(i)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-content dense class="my-0 py-0">
                            <v-list-item-title dense>{{ especialidad.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Usuario Jefe OT</h2>
                  <p class="mb-0 caption">(Opcional)</p>
                </v-col>
                <v-col cols="9" class="py-0">
                  <v-autocomplete
                    v-model="newProjectData.jefe_ot_user"
                    :loading="loadingUsers"
                    :items="usersToSelect"
                    :search-input.sync="searchUsers"
                    item-text="name"
                    item-value="id"
                    item-disabled="false"
                    :filter="usersFilter"
                    cache-items
                    single-line
                    outlined
                    dense
                    required
                    label="Seleccione Usuario Jefe OT"
                    hint="Ingresa al menos 5 caracteres para iniciar búsqueda de usuarios (por nombre o email)"
                    persistent-hint
                  >
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <img :src="data.item.info.avatar">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mb-1">
                  <div class="d-flex flex-row align-center">
                    <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                    <p class="mb-0 font-weight-bold text-primary subtitle-1">Datos del Proyecto</p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Moneda por defecto</h2>
                  <v-select
                    v-model="newProjectData.currency"
                    :items="currencyType"
                    item-text="name"
                    item-value="id"
                    label="Seleccione Tipo"
                    single-line
                    outlined
                    dense
                    required
                    :rules="requiredRule"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Monto Contrato</h2>
                  <v-text-field
                    v-model="newProjectData.contract_value"
                    placeholder="Monto Contrato"
										type="number"
                    outlined
                    dense
                    rounded
                    class="custom-input-numbers text-center"
                    :rules="[...requiredRule,...decimalsRule]"
                  >
                    <template v-slot:prepend-inner>
                      <v-btn fab dark small color="#2D4B95" @click="() => newProjectData.contract_value > 0 ? newProjectData.contract_value--:null">
                        <v-icon dark>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:append>
                      <v-btn fab dark small color="#2D4B95" @click="() => newProjectData.contract_value++">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Plazo <small>(Días)</small></h2>
                  <v-text-field
                    v-model="newProjectData.total_days"
                    placeholder="Plazo en días"
										type="number"
                    outlined
                    dense
                    rounded
                    class="custom-input-numbers text-center"
                    :rules="requiredRule"
                  >
                    <template v-slot:prepend-inner>
                      <v-btn fab dark small color="#2D4B95" @click="() => newProjectData.total_days > 0 ? newProjectData.total_days--:null">
                        <v-icon dark>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:append>
                      <v-btn fab dark small color="#2D4B95" @click="() => newProjectData.total_days++">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Fecha Inicio</h2>
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="newProjectData.start_date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        class="remove-bottom-details date-limit-input"
                        @click:clear="date = null"
                        :rules="requiredRule"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newProjectData.start_date"
                      :min="minDate"
                      @change="datePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#2D4B95"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {getUser} from '@/helpers/api/user'
import {getProjectTypes, create, searchUsersByEmail} from '@/helpers/api/project'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'NewProjectModal',
  components: {
    LMap,
		LTileLayer,
    LMarker,
  },
  data: () => ({
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
			'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 15,
		center: [-33.4433561, -70.6701834],
		markerLatLng: null,
    validform: true,
    newProjectData: {
      company_id: null,
      internal_id: null,
      name: null,
      description: null,
      project_type_id: null,
      especialidades: null,
      jefe_ot_user: null,
      contract_value: 0,
      currency: 1,
      total_days: 0,
      start_date: null,
      project_image: null,
    },
    currencyType: [
      {id: 1, name: 'CLP', checked: true},
      {id: 2, name: 'UF'},
    ],
    datePicker: false,
    minDate: new Date().toISOString().slice(0,10),
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
		decimalsRule: [
			v => v.match(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/) || 'El monto puede tener 2 decimales, formato XXXXXX.XX'
		],
    especialidades: [],
    newEspecialidad: '',
    loadingData: false,
    loadingUsers: false,
    usersToSelect: [],
    searchUsers: null,
		showMap: false
  }),
  watch: {
    searchUsers (val) {
      if(val.length > 5){
        this.searchUsersBD(val)
      }
    },
  },
  mounted(){
    if(!this.$store.projectsStore.state.projectTypes){
      this.projectTypesGet()
    }
  },
  methods: {
		setShowMap(){
			if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition((position) => {
						const coords = {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						}
						this.center = coords
						this.markerLatLng = coords
					});
			}
			this.showMap = !this.showMap
		},
    updateCenter(event) {
      this.center = event.target.getCenter()
			this.markerLatLng = event.target.getCenter()
    },
    closeModal(){
      this.validform = true
      this.newProjectData = {
        company_id: null,
        internal_id: null,
        name: null,
        description: null,
        project_type_id: null,
        especialidades: null,
        jefe_ot_user: null,
        contract_value: 0,
        total_days: 0,
        start_date: null,
        project_image: null,
      }
      this.datePicker = false
      this.minDate = new Date().toISOString().slice(0,10)
      this.especialidades = []
      this.newEspecialidad = ''
      this.loadingData = false
      this.loadingUsers = false
      this.usersToSelect = []
      this.searchUsers = null
			this.markerLatLng = null
      this.$store.projectsStore.commit('setNewProjectModal', false)
    },
    async searchUsersBD(email){
      this.loadingUsers = true
      const users = await searchUsersByEmail({email})
      if(users.code === 200){
        this.usersToSelect = users.users
      }
      this.loadingUsers = false
    },
    usersFilter(item, queryText) {
      const name = item.name.toLowerCase()
      const email = item.email.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || email.indexOf(searchText) > -1
    },
    async projectTypesGet(){
      const projectTypes = await getProjectTypes()
      if(projectTypes.code === 200){
        this.$store.projectsStore.commit('setProjectTypes', projectTypes.project_types)
      }
    },
    addNewEspecialidad(){
      this.especialidades.push({name: this.newEspecialidad, parent_id: null})
      this.newEspecialidad = ''
    },
    removeEspecialidad(i){
      this.especialidades.splice(i, 1)
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        if(this.especialidades.length > 0){
          this.newProjectData.especialidades = JSON.stringify(this.especialidades)
        }
				if(this.markerLatLng){
					this.newProjectData.latitude = this.markerLatLng.lat
					this.newProjectData.longitude = this.markerLatLng.lng
				}
        const formData = new FormData()
        Object.keys(this.newProjectData).forEach((key) => {
          if(this.newProjectData[key]){
            formData.append(key, this.newProjectData[key])
          }
        })
        const project = await create(formData)
        if(project.code === 200){
          await getUser()
          this.closeModal()
          if(!this.$store.projectsStore.state.selectedProject){
            this.$store.projectsStore.commit('setSelectedProject', project.project)
          }
          this.$store.projectsStore.dispatch('getUserProjects')
        }
        this.loadingData = false
      }
    }
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
	/deep/ .custom-input-numbers input::-webkit-outer-spin-button,
	/deep/ .custom-input-numbers input::-webkit-inner-spin-button {
			/* display: none; <- Crashes Chrome on hover */
			-webkit-appearance: none;
			margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	/deep/ .custom-input-numbers input[type=number] {
			-moz-appearance:textfield; /* Firefox */
	}
</style>