var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start"}},_vm._l((_vm.$store.consultasStore.state.tareasbyUser.data),function(userTasks,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mb-2 py-2 creadas"},[_c('v-card-text',{staticClass:"title-card py-2"},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c('div',[_c('p',{staticClass:"title-section mb-0 caption"},[_vm._v("Responsable")]),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('h3',{staticClass:"title-section py-0 mr-2"},[_vm._v(_vm._s(userTasks.tasks[0].responsable_user.name))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#5E85EF","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('div',[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v("Indicadores últimos 5 días")]),_c('p',{staticClass:"mb-0 caption"},[_vm._v("Velocidad de solución: "+_vm._s(_vm._f("oneDecimalsNumber")(userTasks.indicators.solution_speed))+"/día")]),_c('p',{staticClass:"mb-0 caption"},[_vm._v("Tiempo de espera promedio de mis tareas: "+_vm._s(_vm._f("oneDecimalsNumber")(userTasks.indicators.waiting_time))+" días")])])])],1)]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"counter-tareas caption"},[(_vm.$store.consultasStore.state.tareasbyUser.status === 1)?_c('span',[_vm._v(_vm._s(userTasks.tasks.length))]):_c('v-progress-circular',{attrs:{"size":12,"width":2,"color":"white","indeterminate":""}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#5E85EF"},on:{"click":function($event){return _vm.showUserTasks(index)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showedUserTasks.includes(index) ? 'mdi-chevron-up':'mdi-chevron-down')+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver tareas del usuario")])])],1)])])],1),(_vm.showedUserTasks.includes(index))?_c('div',{staticClass:"tareas-user-container"},_vm._l((userTasks.tasks),function(tarea,index){return _c('v-card',{key:index,staticClass:"creadas mb-1",class:tarea.status === 3 ? 'status-ended':'',attrs:{"link":""},on:{"click":function($event){return _vm.viewTarea(tarea.id, tarea.seen_by_me)}}},[(tarea.private)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"incognito-container"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","x-small":""}},[_vm._v("mdi-incognito")])],1)]}}],null,true)},[_c('span',[_vm._v("Tarea privada: sólo la puedes ver tú y el responsable")])]):_vm._e(),_c('v-card-text',{staticClass:"tarea-card py-2 px-2"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex flex-row icon-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-top number"},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 white--text text-task-number caption"},[_vm._v(_vm._s(tarea.number))])])]}}],null,true)},[_c('span',[_vm._v("Tarea número "+_vm._s(tarea.number))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-top d-flex justify-center align-center",class:_vm.$options.filters.seenByMeColor(tarea.seen_by_me),on:{"click":function($event){$event.stopPropagation();return _vm.changeVisualizationStatus(tarea)}}},'div',attrs,false),on),[(!_vm.isLoadingChangeVisualizationStatus(tarea.id))?_c('v-icon',{class:_vm.$options.filters.seenByMeIconAnimation(tarea.seen_by_me),attrs:{"color":"white","small":""}},[_vm._v("mdi-bell")]):_c('v-progress-circular',{attrs:{"width":2,"size":16,"color":"white","indeterminate":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.seenByMeText(tarea.seen_by_me)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top",class:!tarea.seen ? 'high':'success',attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v("El responsable "+_vm._s(!tarea.seen ? 'no':'')+" ha visualizado esta tarea")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.checkIfNearDeadline(tarea))?_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top medium",attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-flag-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Se acerca la fecha crítica "+_vm._s(_vm._f("toDate")(tarea.critical_date)))])])],1)]),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[(tarea.code)?_c('p',{staticClass:"inner-text light-blue-text mb-0"},[_vm._v(_vm._s(tarea.code.sku)+" - "+_vm._s(tarea.code.specialties))]):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(tarea.title))])]),(tarea.status === 3)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top",attrs:{"color":"#34BFA3","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Tarea Finalizada")])])],1):_vm._e()]),_c('p',{staticClass:"inner-text text-creadas-color mb-0"},[_vm._v("Creado por: "+_vm._s(tarea.created_by_user.name))]),_c('p',{staticClass:"inner-text text-creadas-color mb-0"},[_vm._v("Responsable: "+_vm._s(tarea.responsable_user.name))])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end"},[(tarea.critical_date)?_c('div',[_c('p',{staticClass:"inner-text text-creadas-color mb-0 font-weight-bold"},[_vm._v("TE: "+_vm._s(tarea.waitingtime))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top",class:_vm.getTareaPriorityColor(tarea),attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-exclamation")])]}}],null,true)},[_c('span',[_vm._v("Prioridad "+_vm._s(_vm.getTareaPriorityText(tarea)))])])],1),(!tarea.critical_date)?_c('p',{staticClass:"inner-text text-creadas-color mb-0 font-weight-bold"},[_vm._v("TE: "+_vm._s(tarea.waitingtime))]):_vm._e(),_c('p',{staticClass:"mb-0 inner-text"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-calendar-range-outline")]),_vm._v(" "+_vm._s(_vm._f("toDate")(tarea.created_at))+" ")],1),(tarea.critical_date)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row align-center justify-end"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"#5E85EF"}},[_vm._v("mdi-calendar-range-outline")]),_c('p',{staticClass:"mb-0 inner-text light-blue-text"},[_vm._v(_vm._s(_vm._f("toDate")(tarea.critical_date)))])],1)]}}],null,true)},[_c('span',[_vm._v("Fecha crítica: "+_vm._s(_vm._f("toDate")(tarea.critical_date)))])]):_vm._e()],1)],1)],1)],1)}),1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }