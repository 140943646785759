<template>
	<v-container>
		<div class="d-flex align-center justify-space-between my-1">
			<h4>
				Rev {{revision.revision}} - Fecha: {{revision.created_at | toDate}} -  {{revision.created_at | toHour}}
				<v-chip
					color="primary"
					v-if="isFirstRevision"
					x-small
					outlined>
					Descripción Inicial
				</v-chip>
			</h4>
			<!-- <v-btn
				class="ma-0"
				rounded
				color="#3ebfa3"
				small
				v-if="iAmItoAdditional"
				:loading="loadingData"
				@click="() => preChangeStatus()"
			>
				<p class="mb-0 white--text">Aceptar Adicional</p>
			</v-btn> -->
		</div>
		<div class="revision-container pa-2">
			<v-row align="start" class="mt-0">
				<v-col cols="12" md="7">
					<h4 class="mb-1">Observación</h4>
					<div v-html="revision.description"></div>
				</v-col>
				<v-col cols="12" md="5">
					<div class="revision-cost-container w-100">
						<table width="100%" class="rounded-lg">
							<tbody>
								<tr>
									<td class="px-2 caption">
										<v-icon color="#2D4B95" small class="mr-1">mdi-currency-usd</v-icon> Costo Directo:
									</td>
									<td class="px-2 text-right">
										{{revision.currency_info.code}}${{revision.direct_cost | toThousandFilter}}
									</td>
								</tr>
								<tr>
									<td class="px-2 caption">
										<v-icon color="#2D4B95" small class="mr-1">mdi-currency-usd</v-icon> Gasto General:
									</td>
									<td class="px-2 text-right">
										{{revision.currency_info.code}}${{revision.general_expenses | toThousandFilter}}
									</td>
								</tr>
								<tr>
									<td class="px-2 caption">
										<v-icon color="#2D4B95" small class="mr-1">mdi-finance</v-icon> Utilidad:
									</td>
									<td class="px-2 text-right">
										{{revision.currency_info.code}}${{revision.utility | toThousandFilter}}
									</td>
								</tr>
								<tr>
									<td class="px-2 caption">
										<v-icon color="#2D4B95" small class="mr-1">mdi-cash-multiple</v-icon> Total Solicitado:
									</td>
									<td class="px-2 text-right">
										{{revision.currency_info.code}}${{revision.total_requested | toThousandFilter}}
									</td>
								</tr>
							</tbody>
						</table>
						<!-- <div class="px-2 revision-cost-container-inner">
							<v-icon color="#2D4B95" class="mr-2">mdi-currency-usd</v-icon> Costo Directo: {{revision.currency_info.code}}${{revision.direct_cost | toThousandFilter}}
						</div>
						<div class="px-2 revision-cost-container-inner dark">
							<v-icon color="#2D4B95" class="mr-2">mdi-currency-usd</v-icon> Gasto General: {{revision.currency_info.code}}${{revision.general_expenses | toThousandFilter}}
						</div>
						<div class="px-2 revision-cost-container-inner">
							<v-icon color="#2D4B95" class="mr-2">mdi-finance</v-icon> Utilidad: {{revision.currency_info.code}}${{revision.utility | toThousandFilter}}
						</div>
						<div class="px-2 revision-cost-container-inner dark">
							<v-icon color="#2D4B95" class="mr-2">mdi-cash-multiple</v-icon> Total Solicitado: {{revision.currency_info.code}}${{revision.total_requested | toThousandFilter}}
						</div> -->
					</div>
				</v-col>
			</v-row>
			<v-row align="start" class="mt-0">
				<v-col cols="12" md="12">
					<div class="d-flex justify-space-between">
						<p class="mb-0 text-primary font-weight-bold">
							{{revision.attachments.length === 0 ? 'Sin ':''}}
							Adjuntos
						</p>
						<div v-if="revision.attachments.length > 0">
							<v-btn
								class="mx-2 my-1"
								rounded
								color="#2D4B95"
								dark
								x-small
								:loading="loadingFilePath"
								@click="() => downloadAttachments(revision.attachments)"
							>
								<v-icon dark small>mdi-paperclip</v-icon>
								<p class="mb-0 ml-2">Descargar Todo</p>
							</v-btn>
							<v-btn
								class="mx-2 my-1"
								rounded
								color="#5E85EF"
								dark
								x-small
								:loading="loadingFilePath"
								@click="() => showAttachments = !showAttachments"
							>
								<v-icon dark small>{{showAttachments ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
								<p class="mb-0 ml-2">{{showAttachments ? 'Ocultar':'Ver Más'}}</p>
							</v-btn>
						</div>
					</div>
					<v-scroll-y-transition leave-absolute v-if="revision.attachments.length > 0">
						<div v-if="showAttachments" :key="1">
							<MediaGallery
								v-if="revision.attachments.filter(item => item.file.pathmedia).length > 0"
								:images="revision.attachments.filter(item => item.file.pathmedia).map(item => item.file.pathmedia)" />
							<v-divider class="my-2"></v-divider>
							<div class="adjuntos-container"
								v-if="revision.attachments.filter(item => !item.file.pathmedia).length > 0">
								<div class="d-flex flex-row align-center justify-space-between my-2 py-1 px-2"
									:class="index%2 != 0 ? 'bg-dark-list':''"
									:key="index"
									v-for="(attachment, index) in revision.attachments.filter(item => !item.file.pathmedia)">
									<p class="mb-0">{{attachment.file.name}}</p>
									<p class="mb-0 text-primary">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													class="mx-1 my-1"
													color="#5E85EF"
													dark
													small
													icon
													:loading="loadingFilePath"
													v-bind="attrs"
													v-on="on"
													@click="getFilesUrl(attachment, true)"
												>
													<v-icon color="white" small class="info-icon small-icon">mdi-open-in-new</v-icon>
												</v-btn>
											</template>
											<span>Visualizar en navegador</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													class="mx-1 my-1"
													color="#5E85EF"
													dark
													x-small
													:icon="$vuetify.breakpoint.mobile"
													:loading="loadingFilePath"
													v-bind="attrs"
													v-on="on"
													@click="getFilesUrl(attachment, true, true)"
												>
													<v-icon color="white" small class="bg-light-blue mr-2" :class="$vuetify.breakpoint.mobile ? 'info-icon':''">mdi-cloud-download-outline</v-icon>
													<p class="mb-0" v-if="!$vuetify.breakpoint.mobile">Descargar</p>
												</v-btn>
											</template>
											<span>Descargar archivo</span>
										</v-tooltip>
									</p>
								</div>
							</div>
						</div>
					</v-scroll-y-transition>
				</v-col>
			</v-row>
			<v-divider class="my-4" />
			<RevisionObervation :observations="revision.observations"/>
		</div>
	</v-container>
</template>

<script>
import MediaGallery from '@/components/Globals/Helpers/MediaGallery'
import {getPublicUrl} from '@/helpers/api/tasks'
import RevisionObervation from './RevisionObervation.vue'

export default {
	name: 'RevisionSingleContainer',
	components: {
		MediaGallery,
		RevisionObervation
	},
	computed: {
		iAmItoAdditional(){
			return this.currentAdicional.userprojectjobtype.job_type_id !== 4
		},
	},
	props: {
		isFirstRevision: {
			type: Boolean,
			required: true
		},
		revision: {
			type: Object,
			required: true
		},
		currentAdicional: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loadingData: false,
		loadingFilePath: false,
		showAttachments: false
	}),
	methods: {
    async getFilesUrl(attachments, singleFile = false, download = false){
      if(singleFile){ attachments = [attachments] }
      this.loadingFilePath = true
      const files = await getPublicUrl({attachments, force_download: download})
      if(files.code === 200){
        files.files.forEach((file) => {
          window.open(file.url);
        })
      }
      this.loadingFilePath = false
    },
	}
}
</script>

<style scoped>
  .text-primary{
    color: #2D4B95;
  }
	.revision-container{
		background-color: #EFEFEF;
	}
	.revision-cost-container-inner{
		border: 1px solid grey;
	}
	.revision-cost-container-inner.dark{
		background-color: #e2e2e2;
	}
	table, th, td {
		border: 1px solid grey;
		border-collapse: collapse;
	}
</style>