var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":"","id":"listado-consultas"}},[_c('v-card',{staticClass:"mb-2 py-1"},[_c('v-card-text',{staticClass:"header-table-card py-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"table-header-button d-flex flex-row pa-0 justify-start",staticStyle:{"height":"auto"},attrs:{"text":"","block":""},on:{"click":_vm.toggleOrder}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#5e85ef","small":""}},[_vm._v(_vm._s(_vm.getIconOrder()))]),_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.getNameOrder()))])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"table-header-button d-flex flex-row pa-0 justify-start",staticStyle:{"height":"auto"},attrs:{"text":"","block":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Creador")]),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#5e85ef","small":""}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.creatorMenu),callback:function ($$v) {_vm.creatorMenu=$$v},expression:"creatorMenu"}},[_c('v-list',{staticClass:"filter-selector-list",attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 font-weight-bold caption text-primary-color text-capitalize"},[_vm._v("Filtrar Por:")]),_c('v-divider')],1)]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.creatorFilter),callback:function ($$v) {_vm.creatorFilter=$$v},expression:"creatorFilter"}},_vm._l((_vm.$store.usersStore.state.usersList),function(user,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"auto"},attrs:{"value":user.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-title',{staticClass:"my-0 d-flex flex-row align-center"},[_c('v-checkbox',{staticClass:"caption my-0 py-0",attrs:{"dense":"","hide-details":"","input-value":active}}),_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(user.name))])],1)]}}],null,true)})}),1),_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","block":"","color":"#5e85ef","dark":""},on:{"click":function($event){return _vm.filterList()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-filter-outline")]),_c('p',{staticClass:"mb-0 caption text-capitalize"},[_vm._v("Aplicar")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"table-header-button d-flex flex-row pa-0 justify-start",staticStyle:{"height":"auto"},attrs:{"text":"","block":""}},[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize mr-1"},[_vm._v("Estado")]),(_vm.$store.projectsStore.state.selectedConsultasInfo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-icon",attrs:{"color":_vm.$options.filters.getColorStatusModulo(_vm.$store.projectsStore.state.selectedConsultasInfo.semaforo_consultas),"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")])]}}],null,false,2332476906)},[_c('p',{staticClass:"caption mb-0"},[_vm._v("Estado general del módulo: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$options.filters.getTextStatusModulo(_vm.$store.projectsStore.state.selectedConsultasInfo.semaforo_consultas)))])])]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"table-header-button d-flex flex-row pa-0 justify-start",staticStyle:{"height":"auto"},attrs:{"text":"","block":""}},[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Tarea")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"table-header-button d-flex flex-row pa-0 justify-start",staticStyle:{"height":"auto"},attrs:{"text":"","block":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Responsable")]),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#5e85ef","small":""}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.responsableMenu),callback:function ($$v) {_vm.responsableMenu=$$v},expression:"responsableMenu"}},[_c('v-list',{staticClass:"filter-selector-list",attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 font-weight-bold caption text-primary-color text-capitalize"},[_vm._v("Filtrar Por:")]),_c('v-divider')],1)]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.responsableFilter),callback:function ($$v) {_vm.responsableFilter=$$v},expression:"responsableFilter"}},_vm._l((_vm.$store.usersStore.state.usersList),function(user,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"auto"},attrs:{"value":user.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-title',{staticClass:"my-0 d-flex flex-row align-center"},[_c('v-checkbox',{staticClass:"caption my-0 py-0",attrs:{"dense":"","hide-details":"","input-value":active}}),_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(user.name))])],1)]}}],null,true)})}),1),_c('v-list-item',{staticStyle:{"min-height":"auto"},attrs:{"dense":""}},[_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","block":"","color":"#5e85ef","dark":""},on:{"click":function($event){return _vm.filterList()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-filter-outline")]),_c('p',{staticClass:"mb-0 caption text-capitalize"},[_vm._v("Aplicar")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"table-header-button d-flex flex-row pa-0 justify-start",staticStyle:{"height":"auto"},attrs:{"text":""}},[_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-capitalize"},[_vm._v("Estado")])])],1),_c('v-col',{staticClass:"d-flex flex-row justify-end align-center py-0 icon-container",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-top total"},'div',attrs,false),on),[_c('span',{staticClass:"white--text caption"},[_vm._v(_vm._s(_vm.$store.adicionalesStore.state.listadoPosibles.total_additional))])])]}}])},[_c('span',[_vm._v("Total de tareas")])])],1)],1)],1),(_vm.$store.adicionalesStore.state.listadoPosibles.status === 99)?_c('v-progress-linear',{staticClass:"listing-loading-bar",attrs:{"indeterminate":"","color":"#5e85ef"}}):_vm._e()],1),(_vm.$store.adicionalesStore.state.listadoPosibles.status === 1 || _vm.$store.adicionalesStore.state.listadoPosibles.count > 0)?_c('div',{staticClass:"listing-scroll"},[_vm._l((_vm.$store.adicionalesStore.state.listadoPosibles.data),function(tarea,index){return _c('v-card',{key:index,staticClass:"tarea-card mb-2",staticStyle:{"overflow":"hidden"},on:{"click":function($event){$event.stopPropagation();_vm.viewTarea(tarea.id, _vm.$options.filters.seenByMe(tarea.seen_by_me))}}},[(tarea.private)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"incognito-container"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-incognito")])],1)]}}],null,true)},[_c('span',[_vm._v("Tarea privada: sólo la puedes ver tú y el responsable")])]):_vm._e(),_c('v-card-text',{staticClass:"header-table-card py-2",class:tarea.status === 3 ? 'status-ended':''},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex flex-column"},[(tarea.status === 3)?_c('div',[_c('v-chip',{staticClass:"mb-1",attrs:{"color":"#34BFA3","text-color":"white","x-small":""}},[_vm._v(" Tarea Finalizada ")])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row icon-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-top number"},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 white--text text-task-number caption"},[_vm._v(_vm._s(tarea.number))])])]}}],null,true)},[_c('span',[_vm._v("Tarea número "+_vm._s(tarea.number))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top",class:_vm.getTareaPriorityColor(tarea),attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-exclamation")])]}}],null,true)},[_c('span',[_vm._v("Prioridad "+_vm._s(_vm.getTareaPriorityText(tarea)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canISeeBell(tarea))?_c('div',_vm._g(_vm._b({staticClass:"icon-top d-flex justify-center align-center",class:_vm.$options.filters.seenByMeColor(tarea.seen_by_me),on:{"click":function($event){$event.stopPropagation();return _vm.changeVisualizationStatus(tarea)}}},'div',attrs,false),on),[(!_vm.isLoadingChangeVisualizationStatus(tarea.id))?_c('v-icon',{class:_vm.$options.filters.seenByMeIconAnimation(tarea.seen_by_me),attrs:{"color":"white","small":""}},[_vm._v("mdi-bell")]):_c('v-progress-circular',{attrs:{"width":2,"size":16,"color":"white","indeterminate":""}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.seenByMeText(tarea.seen_by_me)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-top",class:!tarea.seen ? 'high':'success',attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v("El responsable "+_vm._s(!tarea.seen ? 'no':'')+" ha visualizado esta tarea")])])],1)])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 body-2 text--secondary"},[_vm._v(" "+_vm._s(tarea.created_by_user.name)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(tarea.created_by_user.id === _vm.$store.userStore.state.userInfo.id)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"#5e85ef","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-account")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Tú creaste esta tarea")])])],1),_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-primary-color"},[_vm._v(_vm._s(tarea.userjob.userjobtype.name))])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-img',{staticClass:"mr-3",attrs:{"src":_vm.setLights(tarea.lightstatus),"max-height":"50","max-width":"35","contain":""}}),_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-primary-color",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(tarea.title))])],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('p',{staticClass:"mb-0 body-2 text--secondary"},[_vm._v(_vm._s(tarea.responsable_user.name))]),_c('p',{staticClass:"mb-0 font-weight-bold body-2 text-primary-color"},[_vm._v(_vm._s(tarea.responsablejob.userjobtype.name))])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"status-container d-flex flex-row align-center pl-3"},[(_vm.checkIfNearDeadline(tarea))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!tarea.seen)?_c('v-icon',_vm._g(_vm._b({staticClass:"icon-status icon-top medium",attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-flag-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Se acerca la fecha crítica "+_vm._s(_vm._f("toDate")(tarea.critical_date)))])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('p',{staticClass:"mb-0 body-2 text--secondary caption"},[_vm._v("Espera: "+_vm._s(tarea.waitingtime))]),_c('p',{staticClass:"mb-0 font-weight-bold caption text-primary-color d-flex flex-row"},[_vm._v(" "+_vm._s(_vm._f("toDate")(tarea.created_at))+" "),(tarea.critical_date)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row critical-date-container px-1 mx-1 rounded"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-calendar-range-outline")]),_c('p',{staticClass:"mb-0 white--text"},[_vm._v(_vm._s(_vm._f("toDate")(tarea.critical_date)))])],1)]}}],null,true)},[_c('span',[_vm._v("Fecha crítica: "+_vm._s(_vm._f("toDate")(tarea.critical_date)))])]):_vm._e()],1)])],1)])],1)],1)],1)}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"color":"#2D4B95","length":Math.ceil(_vm.$store.adicionalesStore.state.listadoPosiblesTotal/50),"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],2):_c('div',_vm._l((5),function(i){return _c('v-card',{key:i,staticClass:"mb-2 py-2"},[_c('v-card-text',{staticClass:"py-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"min-width":"100%","type":"list-item"}})],1)])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }