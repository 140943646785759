<template>
	<v-card
		class="mx-auto"
	>
		<v-toolbar
			color="#2D4B95"
			dark
			dense
			height="30px"
			v-if="(searchResult.tasks && searchResult.tasks.length > 0) || (searchResult.rdis && searchResult.rdis.length > 0)"
		>
			<v-toolbar-title class="caption">Hemos encontrado algunos elementos similares de acuerdo al título. Por favor revísalas para no duplicar elementos.</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon @click="closeResults()">
				<v-icon>mdi-close-circle-outline</v-icon>
			</v-btn>
		</v-toolbar>
		<div style="max-height: 400px; overflow-y: scroll;">
			<v-list
				v-if="(searchResult.tasks && searchResult.tasks.length > 0) || (searchResult.rdis && searchResult.rdis.length > 0)"
				subheader
				two-line
				dense class="py-0"
			>
				<v-tabs
					v-model="tab"
					fixed-tabs
					class="mx-0"
					color="#2D4B95"
					height="30px"
				>
					<v-tab v-if="searchResult.tasks.length > 0" class="caption">Tareas</v-tab>
					<v-tab v-if="searchResult.rdis.length > 0" class="caption">RDI</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item v-if="searchResult.tasks.length > 0">
						<v-list-item
							v-for="(tarea, index) in searchResult.tasks"
							:key="index"
							@click="viewTarea(tarea.id, $options.filters.seenByMe(tarea.seen_by_me))"
							dense class="my-0 py-2 d-flex flex-row justify-space-between" style="min-height: 25px; max-height: 60px; border-bottom: 1px solid #e8e8e8">
							<div class="mb-0 d-flex flex-column align-start" style="flex: 1;">
								<div class="my-2 d-flex flex-row justify-space-between align-center">
									<div class="icon-top caption white--text mr-2">{{tarea.number}}</div>
									<p class="caption mb-0 font-weight-bold">{{tarea.title}}</p>
								</div>
								<p class="caption mb-0 description-container"><span v-html="tarea.description"></span></p>
							</div>
							<v-btn icon>
								<v-icon color="grey lighten-1">mdi-arrow-right-bold-circle</v-icon>
							</v-btn>
						</v-list-item>
					</v-tab-item>
					<v-tab-item v-if="searchResult.rdis.length > 0">
						<v-list-item
							v-for="(rdi, index) in searchResult.rdis"
							:key="index"
							@click="viewRDI(rdi.id)"
							dense class="my-0 py-2 d-flex flex-row justify-space-between" style="min-height: 25px; max-height: 60px; border-bottom: 1px solid #e8e8e8">
							<div class="mb-0 d-flex flex-column align-start" style="flex: 1;">
								<div class="my-2 d-flex flex-row justify-space-between align-center">
									<div class="icon-top caption white--text mr-2">{{rdi.number}}</div>
									<p class="caption mb-0 font-weight-bold">{{rdi.title}}</p>
								</div>
								<p class="caption mb-0 description-container"><span v-html="rdi.description"></span></p>
							</div>
							<v-btn icon>
								<v-icon color="grey lighten-1">mdi-arrow-right-bold-circle</v-icon>
							</v-btn>
						</v-list-item>
					</v-tab-item>
				</v-tabs-items>
			</v-list>
		</div>
	</v-card>
</template>

<script>
import {getTareaByID} from '@/helpers/api/tasks'
import {getRdiByID} from '@/helpers/api/rdi'

export default {
	name: 'SearchBlockForms',
	props: {
		closeResults: {
			type: Function
		},
		searchResult: {
			type: Object
		},
		searching: {
			type: Boolean,
			default: true
		}
	},
	components: {
	},
	data: () => ({
		tab: null
	}),
	mounted(){
	},
	methods: {
		async viewTarea(task_id, seen_by_me){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = seen_by_me
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		async viewRDI(rdi_id){
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				let rdiData = rdi.rdi
				rdiData.prev_seen_by_me = false
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
		}
	}
};
</script>

<style scoped>
	.icon-top{
		border-radius: 100%;
		background-color: #2D4B95;
		text-align: center;
		line-height: 20px;
		height: 20px;
		min-width: 20px;
	}
	.search-input{
		position: relative;
		background-color: white;
		border-color: red;
	}
	/deep/ .search-input input{margin-left: 20px;}
	.search-button{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	.description-container{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 300px;
		max-height: 20px;
	}
</style>
