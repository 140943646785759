<template>
  <div class="text-center" v-if="currentAdicional">
    <v-dialog
      v-model="currentAdicional"
      max-width="800"
      scrollable
      persistent
    >
      <v-card v-if="currentAdicional.loading">
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Cargando Adicional</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row align="center" class="mt-2">
              <v-col cols="12" md="12">
                <div class="d-flex flex-column text-center align-center py-1" style="width: 100%">
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="#2D4B95"
                    indeterminate
                  ></v-progress-circular>
                  <p class="mb-0 body-2 ml-2">Cargando información</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
			</v-card>
			<v-card v-else>
				<v-card-title class="px-8">
					<div class="d-flex align-center justify-space-between" style="width: 100%;"
						:class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
						<div class="d-flex flex-row align-center order-1 order-sm-0">
							<v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
							<div :class="$vuetify.breakpoint.mobile ? 'mt-2':''">
								<p class="mb-0 font-weight-bold text-primary title" :class="$vuetify.breakpoint.mobile ? 'body-2':''">{{currentAdicional.title}} - Nº {{currentAdicional.number}}</p>
							</div>
						</div>
						<HeaderButtons :closeModal="closeModal"/>
					</div>
				</v-card-title>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <v-row align="start" dense>
              <v-col cols="12" md="6">
                <p class="mb-1" v-if="currentAdicional.ito_user">
                  <v-icon color="#2D4B95" class="mr-2">mdi-account-outline</v-icon> ITO: {{currentAdicional.ito_user.name}}
                </p>
                <p class="mb-1">
                  <v-icon color="#2D4B95" class="mr-2">mdi-code-brackets</v-icon> Código: {{currentAdicional.code}}
                </p>
                <p class="mb-1">
                  <v-icon color="#2D4B95" class="mr-2">mdi-calendar-month-outline</v-icon> Fecha Emisión: {{currentAdicional.created_at | toDateTime}}
                </p>
                <p class="mb-1">
                  <v-icon color="#2D4B95" class="mr-2">mdi-clock-time-eight-outline</v-icon> Tiempo de espera: {{currentAdicional.created_at | waitingTime}}
                </p>
                <p class="mb-1" v-if="currentAdicional.critical_date">
                  <v-icon color="#2D4B95" class="mr-2">mdi-calendar-month-outline</v-icon> Fecha Crítica: {{currentAdicional.critical_date | toDate}}
                </p>
                <p class="mb-1"
                    v-if="currentAdicional.status == 3 && currentAdicional.ended_at">
                  <v-icon color="#2D4B95" class="mr-2">mdi-calendar-month-outline</v-icon> Fecha Solución: {{currentAdicional.ended_at | toDateTime}}
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p class="mb-1">
                  <b class="text-primary mr-2">Estado:</b>
									<v-chip
										:color="statusColor"
										x-small
										outlined>
										{{currentAdicional.statustext}}
									</v-chip>
                </p>
                <p class="mb-1">
                  <b class="text-primary">Proyecto:</b> {{currentAdicional.project.name}}
                </p>
                <div class="align-center d-flex mb-1">
                  <p class="mb-0 mr-2"><b class="text-primary">Especialidades:</b></p>
                  <v-chip
                    v-for="(specialty, index) in currentAdicional.specialties"
                    :key="index"
                    color="#5E85EF"
                    dark
                    small
                    class="mx-1">
                      <small>{{specialty.specialty.name}}</small>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
						<v-row align="center" v-if="files.length > 0" dense>
							<v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
								<div v-for="(file,index) in files" :key="index" class="ma-1" style="flex: 1 0 30%;">
									<div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
										<v-btn
											icon
											color="#5E85EF"
											x-small
											class="button-preview-img"
											@click="removeAttachment(index)"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<img :src="filesPreview[index].src" class="preview-img"/>
									</div>
									<div v-else class="d-flex flex-row align-center justify-start">
										<v-btn
											icon
											color="#5E85EF"
											x-small
											@click="removeAttachment(index)"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
										{{file.name}}
									</div>
								</div>
							</v-col>
						</v-row>
						<v-divider class="my-2"></v-divider>
						<v-row align="center" dense>
							<v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
								<RevisionSingleContainer
									v-for="(revision, index) in showingRevisions"
									:key="index"
									:revision="revision"
									:currentAdicional="currentAdicional"
									:isFirstRevision="index == 0"
									/>
								<v-btn
									class="mx-2 mb-2"
									rounded
									color="#5E85EF"
									dark
									x-small
									text
									block
									v-if="this.currentAdicional.revisions.length > 1"
									:loading="loadingFilePath"
									@click="showingAllRevisions = !showingAllRevisions"
								>
									<v-icon dark small>{{showingAllRevisions ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
									<p class="mb-0 ml-2">{{showingAllRevisions ? 'Mostrar sólo la última revisión':'Ver todas las revisiones'}}</p>
								</v-btn>
							</v-col>
						</v-row>
						<v-row align="center" dense>
							<v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
								<div class="status-container mx-2 px-2 py-1 rounded-lg" :class="statusColor">
									<p class="mb-0 white--text w-100 text-center body-2 font-weight-bold">
										{{statusText}}
									</p>
								</div>
							</v-col>
						</v-row>
						<v-scroll-y-transition>
							<v-row align="center" dense v-if="showAddRevision">
								<v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
									<div class="px-2 py-1" style="width: 100%">
										<v-divider class="my-1"></v-divider>
										<AddRevision ref="addRevisionContainer" :closeAddRevision="closeAddRevision" :additional="currentAdicional"/>
										<v-divider class="my-1"></v-divider>
									</div>
								</v-col>
							</v-row>
						</v-scroll-y-transition>
            <v-row align="center"
              v-if="currentAdicional.status != 4 && currentAdicional.status != 2 && checkPermissions(['change_status_additionals'])">
              <v-col cols="12">
                <div class="d-flex justify-end" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
                  <div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
										<v-speed-dial
											v-model="fab"
											direction="left"
										>
											<template v-slot:activator>
												<v-btn
													class="mx-2 my-1"
													rounded
													color="#2D4B95"
													dark
													raised
													small
													:loading="loadingChangeStatus"
												>
													<v-icon small class="mr-2" v-if="fab">
														mdi-close
													</v-icon>
													<v-icon small class="mr-2" v-else>
														mdi-plus
													</v-icon>
													Responder
												</v-btn>
											</template>
											<!-- <v-btn
												class="mx-2 my-1"
												rounded
												color="white"
												raised
												small
												disabled
												v-if="checkPermissions(['change_status_additionals'])"
												@click="onButtonClick"
											>
												<p class="mb-0 text-primary">Sin Acuerdo</p>
											</v-btn> -->
											<v-btn
												class="mx-2 my-1"
												rounded
												color="#2D4B95"
												dark
												raised
												small
												v-if="checkPermissions(['change_status_additionals'])"
												@click="changeStatusAdditional(3)"
											>
												<p class="mb-0">Anular Adicional</p>
											</v-btn>
											<v-btn
												class="mx-2 my-1"
												rounded
												color="#2D4B95"
												dark
												raised
												small
												@click="changeStatusAdditional(2) && canAcceptAditional()"
											>
												<p class="mb-0">Aceptar Adicional</p>
											</v-btn>
											<v-btn
												class="mx-2 my-1"
												rounded
												color="#5E85EF"
												dark
												raised
												small
												v-if="checkPermissions(['change_status_additionals']) && canAddRevisions()"
												@click="toggleAddRevisionVisibility()"
											>
												<p class="mb-0">Nueva Revisión</p>
											</v-btn>
										</v-speed-dial>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AcceptCancelModal :acceptRejectModal="acceptRejectModal" :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel"/>
		<SendContentByEmailModal :sendContentByEmailModal="sendByEmailModal" :closeModal="() => sendByEmailModal = false" :sendingType="2"/>
  </div>
</template>

<script>
import moment from 'moment'
import EventBus from '@/helpers/event_bus'
import _debounce from 'lodash/debounce'
import {getPublicUrl, getTareaByID} from '@/helpers/api/tasks'
import {downloadPDF} from '@/helpers/api/rdi'
import {changeAdditionalStatus} from '@/helpers/api/additionals'
import {getZippedFiles} from '@/helpers/api/fileUploads'
// import MediaGallery from '@/components/Globals/Helpers/MediaGallery'
import SendContentByEmailModal from '@/components/Globals/Modals/General/SendContentByEmailModal'
import AcceptCancelModal from '@/components/Globals/Modals/General/AcceptCancelModal.vue'
import HeaderButtons from '@/components/Globals/Modals/Adicionales/Parts/HeaderButtons'
import RevisionSingleContainer from '@/components/Globals/Modals/Adicionales/Parts/RevisionSingleContainer'
import AddRevision from '@/components/Globals/Modals/Adicionales/AddRevision'

export default {
  name: 'AdicionalModal',
  components: { SendContentByEmailModal, AcceptCancelModal, HeaderButtons, RevisionSingleContainer, AddRevision },
  computed: {
    currentAdicional() {
      return this.$store.adicionalesStore.state.currentAdicional
    },
		statusColor(){
			switch (this.currentAdicional.status) {
				case 1:
					return 'primary'
				case 2:
					return 'success'
				case 3:
					return 'warning'
				case 4:
					return 'danger'
				default:
					return 'primary'
			}
		},
		statusText(){
			switch (this.currentAdicional.status) {
				case 1:
					return 'Adicional creado, a la espera de ser respondido por ' + this.responsableUserName
				case 2:
					return 'Se ha aprobado el adicional'
				case 3:
					return 'Se ha solicitado anular el adicional'
				case 4:
					return 'El adicional ha sido cancelado'
				default:
					return 'primary'
			}
		},
		iAmResponsablePart(){
			//Usuario de última interacción: 1: USUARIO NO ITO, 2: USUARIO ITO
			const $resp_no_ito = this.currentAdicional.response_status === 2 && this.currentAdicional.userprojectjobtype.job_type_id !== 4
			const $resp_ito = this.currentAdicional.response_status === 1 && this.currentAdicional.userprojectjobtype.job_type_id === 4
			return $resp_no_ito || $resp_ito
		},
		responsableUserName(){
			if(this.iAmResponsablePart){
				return this.currentAdicional.created_by_user.name
			}
			return this.currentAdicional.ito_user ? 'ITO: ' + this.currentAdicional.ito_user.name:'ITO'
		},
		showingRevisions(){
			if(this.showingAllRevisions){
				return this.currentAdicional.revisions
			}
			else if(this.currentAdicional.revisions.length > 1) {
				return [
					this.currentAdicional.revisions[0],
					this.currentAdicional.revisions[this.currentAdicional.revisions.length - 1]
				]
			}
			return [
				this.currentAdicional.revisions[0]
			]
		}
  },
  watch: {
    currentRDI(newData) {
      if(newData && !newData.loading && newData.id){
        setTimeout(() => {
          this.pasteListener()
        }, 500);
      }
    }
  },
  data: () => ({
		fab: false,
    dialog: true,
    showMore: false,
		showAddRevision: false,
		showingAllRevisions: false,
		interactionSearch: '',
    message: '',
    response_type: 0,
    need_reload: false,
    files: [],
    filesPreview: [],
    loadingFilePath: false,
    is_response: false,
    loading: false,
    changeInChecklists: false,
		loadingChangeStatus: false,
    loadingChangeUser: false,
		loadingPDFDownload: false,
		sendByEmailModal: false,
		cancelTimer: 4,
		inCancelTime: false,
		cancelInterval: null,
		confirmButtonText: 'Responder',
		loadingEdit: false,
    acceptRejectModal: false,
    acceptRejectModalData: null,
  }),
  mounted(){
    this.setListeners()
  },
	beforeDestroy(){
		EventBus.$off('completedFileUpload')
	},
  methods: {
		canAcceptAditional(){
			return this.currentAdicional.userprojectjobtype.job_type_id == 4
		},
		canAddRevisions(){
			return this.currentAdicional.userprojectjobtype.job_type_id !== 4
		},
    checkInteractPermission(permissionToCheck){
      return this.checkPermissions(permissionToCheck)
    },
		changeInSubscription(value){
			this.editRdiForm.subscribe_status_mail = value
		},
    isUserPart(){
      return this.$store.userStore.state.userInfo.id === this.$store.rdiStore.state.currentRDI.created_by_user.id || this.$store.userStore.state.userInfo.id === this.$store.rdiStore.state.currentRDI.responsable_user.id
    },
		isMyRdi(){
      return this.$store.userStore.state.userInfo.id === this.$store.rdiStore.state.currentRDI.created_by_user.id
		},
		filteredSearch(items){
			if(this.interactionSearch && this.interactionSearch !== '' && items){
				const filtered = items.filter((item) => {
					return item.message.toLowerCase().includes(this.interactionSearch.toLowerCase())
				})
				return filtered
			}
			return items
		},
		toggleAddRevisionVisibility(){
			this.showAddRevision = !this.showAddRevision
			setTimeout(() => {
				console.log('this.$refs.addRevisionContainer: ', this.$refs.addRevisionContainer)
				this.$vuetify.goTo(this.$refs.addRevisionContainer)
			}, 2000)
		},
    pasteListener(){
      const textEditor = document.querySelector('#interaction-texteditor .ProseMirror')
      if(textEditor){
        textEditor.addEventListener('paste', this.pasteListenerHandler)
      }
    },
		async pasteListenerHandler(event) {
			const dT = event.clipboardData || window.clipboardData
			const file = dT.files[0]
			if(file){
				this.files.push(file)
				if(file.type.includes('image')){
					const base64File = await this.toBase64(file)
					this.filesPreview.push({src: base64File})
				}
				else{
					this.filesPreview.push({noImage: true})
				}
			}
		},
    changedChecklist(){
      this.changeInChecklists = true
    },
    setListeners(){
      EventBus.$on('completedFileUpload', async (payload) => {
        if(payload && payload.additional_id && this.currentAdicional && payload.additional_id == this.currentAdicional.id){
					this.startGetAdditionalByID(true, true)
        }
      })
    },
		startGetAdditionalByID: _debounce(function(seen_by_me = false, dontSetLoading = false){ this.getAdditionalByID(seen_by_me, dontSetLoading) }, 800),
		getAdditionalByID(seen_by_me = false, dontSetLoading = false){
			this.$store.adicionalesStore.dispatch('getAdditionalByID', {
				additional_id: this.currentAdicional.id,
				seen_by_me,
				dontSetLoading
			})
		},
		closeAddRevision(){
			this.showAddRevision = false
			this.need_reload = true
		},
    async getAdditionalList(){
      let project_id = null
      if(this.$store.projectsStore.state.selectedProject){
        project_id = this.$store.projectsStore.state.selectedProject.id
      }
      else{
        const id = localStorage.getItem('selectedProjectID')
        project_id = parseInt(id)
      }
      if(project_id){
        this.$store.adicionalesStore.dispatch('getAdditionals', {project_id})
      }
    },
    checkIfNearDeadline(rdi){
      if(!rdi.critical_date){ return false }
      const now = moment()
      const rdi_date = moment(rdi.critical_date)
      return rdi_date.diff(now, 'days') <= 2
    },
    getColorStatus(){
      if(this.$store.rdiStore.state.currentRDI.status === 1){
        return '#3EBFA3'
      }
      return '#F4516C'
    },
    closeModal(){
      const reload = this.need_reload || !this.currentAdicional.prev_seen_by_me
      this.$store.adicionalesStore.commit('setCurrentAdicional', null)
      if(reload){
        this.getAdditionalList()
      }
      this.need_reload = false
			this.showAddRevision = false
      this.loading = false
      this.files = []
      this.filesPreview = []
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      const newFile = e.target.files[0]
      this.files.push(newFile)
      if(newFile.type.includes('image')){
        const base64File = await this.toBase64(newFile)
        this.filesPreview.push({src: base64File})
      }
      else{
        this.filesPreview.push({noImage: true})
      }
    },
    removeAttachment(index){
      this.files.splice(index, 1)
      this.filesPreview.splice(index, 1)
    },
    toBase64(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result)
        };
        reader.onerror = function () {
          resolve(null)
        };
      })
    },
    async getFilesUrl(attachments, singleFile = false, download = false){
      if(singleFile){ attachments = [attachments] }
      this.loadingFilePath = true
      const files = await getPublicUrl({attachments, force_download: download})
      if(files.code === 200){
        files.files.forEach((file) => {
          window.open(file.url);
        })
      }
      this.loadingFilePath = false
    },
    async downloadAttachments(attachments){
      this.loadingFilePath = true
			const toZip = attachments.map(item => item.file)
      const files = await getZippedFiles({files_to_zip: toZip})
      if(files.code == 200){
          // this.downloadFile(files.fileurl, 'archivo.zip')
          window.open(files.fileurl);
      }
      this.loadingFilePath = false
    },
    closeModalAcceptCancel(status = false){
      this.acceptRejectModal = false
      if(status && this.acceptRejectModalData && this.acceptRejectModalData.type === 1){
        this.closeRDI()
      }
      if(status && this.acceptRejectModalData && this.acceptRejectModalData.type === 2){
        this.deleteRdi()
      }
      this.acceptRejectModalData = null
    },
    minimizeTask(){
      const data = {
        task_id: this.$store.rdiStore.state.currentRDI.id,
        title: this.$store.rdiStore.state.currentRDI.title,
        type: 'RDI',
        type_id: 2,
        number: this.$store.rdiStore.state.currentRDI.number
      }
      this.$store.globalStore.commit('addTaskToTasksHandler', data)
      this.closeModal()
    },
		async viewRdi(task_id, seen_by_me){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = seen_by_me
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		async downloadPDF(){
			this.loadingPDFDownload = true
			const pdf_url = await downloadPDF({rdi_id: this.$store.rdiStore.state.currentRDI.id})
      if(pdf_url.code == 200){
          window.open(pdf_url.pdf_url);
      }
			this.loadingPDFDownload = false
		},
		async changeStatusAdditional(newStatus){
			this.loadingChangeStatus = true
			let newStatusFinal = newStatus
			if( newStatus == 4 && this.currentAdicional.userprojectjobtype.job_type_id === 4 ){
				newStatusFinal = 3
			}
			await changeAdditionalStatus({
				additional_id: this.currentAdicional.id,
				new_status: newStatusFinal
			})
			await this.$store.adicionalesStore.dispatch('getAdditionalByID', {
				additional_id: this.currentAdicional.id,
				seen_by_me: true,
				dontSetLoading: true
			})
			this.need_reload = true
			this.loadingChangeStatus = false
		}
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .interaction-container.response{
    background-color: #CAE7CC;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
	.bg-light-blue-text{background-color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .icon-top{
    padding: 2px;
    margin: 0 2px;
    border-radius: 100%;
  }
  .icon-top.high{
    background-color: #F4516C;
  }
  .icon-top.medium{
    background-color: #fb8c00;
  }
  .icon-top.normal{
    background-color: #3EBFA3;
  }
  .icon-top.primary{
    background-color: #2D4B95;
  }
  .icon-top.light-blue, .bg-light-blue{
    background-color: #5E85EF;
  }
  .parent-subtareas{
    display: flex;
    flex-wrap: wrap;
  }
  .parent-subtareas .subtareas-checkbox{
    flex: 1 0 30%;
  }
  .notification-img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
	/deep/ .is_response_interaction .tiptap-vuetify-editor__content{
		border: 2px solid #3ebfa3;
	}
	.is_response_interaction_bottom_text{
		color: #3ebfa3;
	}
	/* .header-modals{} */
	.search-input{
		position: relative;
		background-color: white;
		border-color: red;
		max-width: 200px;
		max-height: 30px;
	}
	/deep/ .search-input input{margin-left: 10px;padding: 0;line-height: 14px; font-size: 14px;}
	/deep/ .search-input .v-input__slot{min-height: 30px!important;}
	/deep/ .search-input.v-text-field--outlined.v-input--dense .v-label{top: 6px;font-size: 14px;}
	/deep/ .search-input.v-text-field--outlined.v-input--dense .v-input__append-inner{margin-top: 3px;}
	.search-button{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 32px;
		height: 32px;
	}
	.status-container{
		width: 100%;
	}
</style>