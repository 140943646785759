<template>
	<v-container fluid style="background-color: #2D4B95;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<img :src="require('../../assets/Svg/logoWhite.svg')" style="max-width: 250px; width: 100%;"/>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="email"
					:rules="emailRules"
					label="Email"
					required
					dark
				></v-text-field>
				<v-btn
					:disabled="!valid"
					color="#5E85EF"
					dark
					:loading="loading"
					@click="requestResetPass"
				>
					Reestablecer Contraseña
				</v-btn>
				<v-btn
					:disabled="!valid || loading"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'Login'})"
				>
					Volver a Login
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import {resetPassRequest} from '@/helpers/api/user'

export default {
	name: 'ResetPassRequest',
	data: () => ({
		valid: true,
		loading: false,
		showMessage: false,
		message: '',
		messageColor: 'error',
		email: '',
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		]
	}),
	mounted(){
		localStorage.removeItem('selectedProjectID')
		this.$store.userStore.commit('setIsAuthenticated', false)
	},
	methods: {
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		async requestResetPass () {
			this.loading = true
			const reset = await resetPassRequest({email: this.email})
			this.loading = false
			
			if(reset.code === 200){
				this.message = 'Se ha enviado un link al correo ingresado para resetear tu contraseña.'
				this.messageColor = 'success'
				this.showMessage = true
			}
			else{
				this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
				if(reset.code === 404){
					this.message = 'El correo ingresado no está en nuestros registros.'
				}
				this.messageColor = 'warning'
				this.showMessage = true
			}
		},
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
