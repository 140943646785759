<template>
	<div class="d-flex flex-column justify-start align-start" style="position: relative;">
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-row justify-center align-center">
						<v-icon :color="getColor(0)" small>{{getIcon(0)}}</v-icon>
						<div class="custom-jobtype-text">Creador</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(0)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-row justify-center align-center">
						<v-icon :color="getColor(2)" small>{{getIcon(2)}}</v-icon>
						<div class="custom-jobtype-text">Jefe OT</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(2)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-row justify-center align-center">
						<v-icon :color="getColor(4)" small>{{getIcon(4)}}</v-icon>
						<div class="custom-jobtype-text">ITO</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(4)}}</span>
		</v-tooltip>
	</div>
	<!-- <v-speed-dial
		v-else
		v-model="fab"
		top
		class="custom-speed-dial"
	>
		<template v-slot:activator>
			<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" v-model="fab">
				<div class="d-flex flex-column justify-center align-center text-center">
					<v-icon v-if="fab" color="green" large>mdi-close</v-icon>
					<v-icon v-else color="green" large>mdi-check-circle-outline</v-icon>
				</div>
			</v-btn>
		</template>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => setApprovalCheck(0)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(0)" large>{{getIcon(0)}}</v-icon>
						<div class="custom-jobtype-text">Creador</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(0)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => setApprovalCheck(2)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(2)" large>{{getIcon(2)}}</v-icon>
						<div class="custom-jobtype-text">Jefe OT</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(2)}}</span>
		</v-tooltip>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="custom-btn mx-1" plain :disabled="disabledButtons" @click="() => setApprovalCheck(4)"
								v-bind="attrs"
								v-on="on">
					<div class="d-flex flex-column justify-center align-center text-center">
						<v-icon :color="getColor(4)" large>{{getIcon(4)}}</v-icon>
						<div class="custom-jobtype-text">ITO</div>
					</div>
				</v-btn>
			</template>
			<span>{{getName(4)}}</span>
		</v-tooltip>
	</v-speed-dial> -->
</template>

<script>
import moment from 'moment'

export default {
	name: 'ApprovalChecksListStatus',
	props: {
		rdi: {
			type: Object,
			required: true
		}
	},
	components: {},
  computed: {
		checkApprovalChecks(){
			const creator = this.rdi.approval_checks.some((item) => {
				return item.user_id == this.rdi.created_by
			})
			const jefe_ot = this.rdi.approval_checks.some((item) => {
				return item.job_type == 2
			})
			const ito = this.rdi.approval_checks.some((item) => {
				return item.job_type == 4
			})
			return creator && jefe_ot && ito
		}
  },
	data: () => ({
		loading: false,
		fab: false
	}),
	mounted(){
		// this.$store.projectsStore.state.selectedProject.job_type.id
	},
	methods: {
		getCurrentStatus(job_type_id){
			if(job_type_id == 0){
				//Creador
				const present = this.rdi.approval_checks.some((item) => {
					return item.user_id == this.rdi.created_by
				})
				return present
			}
			
			const present = this.rdi.approval_checks.some((item) => {
				return item.job_type == job_type_id
			})
			return present
		},
		getColor(job_type_id){
			if(job_type_id == 0){
				//Creador
				const present = this.rdi.approval_checks.some((item) => {
					return item.user_id == this.rdi.created_by
				})
				return present ? 'green':'#DD5050'
			}
			
			const present = this.rdi.approval_checks.some((item) => {
				return item.job_type == job_type_id
			})
			return present ? 'green':'#DD5050'
		},
		getIcon(job_type_id){
			if(job_type_id == 0){
				//Creador
				const present = this.rdi.approval_checks.some((item) => {
					return item.user_id == this.rdi.created_by
				})
				return present ? 'mdi-check-circle-outline':'mdi-circle-outline'
			}
			
			const present = this.rdi.approval_checks.some((item) => {
				return item.job_type == job_type_id
			})
			return present ? 'mdi-check-circle-outline':'mdi-circle-outline'
		},
		getName(job_type_id){
			if(job_type_id == 0){
				//Creador
				const user = this.rdi.approval_checks.find((item) => {
					return item.user_id == this.rdi.created_by
				})
				if(user){
					return user.user.name + ' - ' + moment(user.updated_at).format('DD/MM/YYYY HH:mm')
				}
				return '--'
			}
			
			const user = this.rdi.approval_checks.find((item) => {
				return item.job_type == job_type_id
			})
			if(user){
					return user.user.name + ' - ' + moment(user.updated_at).format('DD/MM/YYYY HH:mm')
			}
			return '--'
		}
	}
};
</script>

<style scoped>
	.custom-btn,
	.custom-btn:disabled {
		height: auto !important;
		min-height: unset !important;
		min-width: unset !important;
		padding: 0 !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: transparent !important;
		box-shadow: none !important;
		border: none;
	}
	.custom-name-text{
		font-size: 8px;
		color: grey;
	}
	.custom-jobtype-text{
		font-size: 9px;
		color: rgb(70, 70, 70);
	}
	.custom-btn.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content{
		opacity: 0.8;
	}
	.custom-speed-dial.v-speed-dial--top{
		top: 0;
	}
	.custom-speed-dial .v-speed-dial__list {
		background-color: white !important; /* Fondo blanco */
		border-radius: 50%; /* Opcional: redondea los bordes */
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Opcional: añade sombra */
	}
	.finalization-not-allowed{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.6);
		z-index: 99;
	}
</style>