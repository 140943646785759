<template>
	<v-container class="d-flex align-start" style="background: #fafafa" fluid>
		<v-row align="center">
			<v-col cols="12" sm="6">
				<v-autocomplete
					v-model="selectedFile"
					:loading="$store.forgeStore.state.loadingFiles"
					:items="$store.forgeStore.state.forgeFiles"
					:search-input.sync="searchForFiles"
					item-text="name"
					item-value="id"
					return-object
					item-disabled="false"
					single-line
					outlined
					dense
					required
					label="Seleccione Archivo para Cargar"
					hide-details
					style="width: 100%"
				>
					<template v-slot:item="data">
						<v-list-item-avatar>
							<v-icon>mdi-file-cad</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<div class="d-flex justify-space-between">
								<div>{{data.item.name}}</div>
								<div class="d-flex">
									<v-tooltip right>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												icon
												:color="isMainModel(data.item) ? '#F5B700':'grey'"
												small
												v-bind="attrs"
												v-on="on"
												:disabled="!checkPermissions(['assign_main_model'])"
												@click.prevent.stop="setMainModel(data.item)"
											>
												<v-icon small>mdi-star</v-icon>
											</v-btn>
										</template>
										<span>Asignar como modelo principal del proyecto</span>
									</v-tooltip>
									<v-tooltip right>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												icon
												small
												color="#F4516C"
												v-bind="attrs"
												v-on="on"
												:disabled="!checkPermissions(['delete_forge_model'])"
												@click.prevent.stop="deleteFile(data.item.id)"
											>
												<v-icon small>mdi-delete-outline</v-icon>
											</v-btn>
										</template>
										<span>Eliminar modelo del proyecto</span>
									</v-tooltip>
								</div>
							</div>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6">
					<v-btn class="custom-button-consultas"
						:disabled="!checkPermissions(['add_forge_model'])"
						@click="onButtonClick">
						<v-icon>mdi-upload</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Subir Archivo a Forge</p>
					</v-btn>
					<p class="mt-1 ml-1 mb-0 text-primary" style="font-size: 10px;">Archivos permitidos: .dwg,.dwfx,.rvt,.nwc,nwd</p>
					<input
						ref="uploader"
						class="d-none"
						type="file"
						accept=".dwg,.dwfx,.rvt,.nwc,.nwd"
						@change="uploadNewFileToForge"
					>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid'
import { setProjectMainModel, deleteProjectMainModel } from '@/helpers/api/forge'
import { deleteFile } from '@/helpers/api/fileManager'

export default {
	name: 'BlueprintHeader',
	data: () => ({
		searching: false,
		searchFile: null,
		showResults: false,
		searchResultsCount: 0,
		searchResult: [],
		searchForFiles: null
	}),
	computed: {
		project() {
			return this.$store.projectsStore.state.selectedProject
		},
		selectedFile: {
			get () {
				return this.$store.forgeStore.state.forgeSelectedFile
			},
			set (value) {
				this.$store.forgeStore.commit('setForgeSelectedFile', value)
			}
		}
	},
	watch: {
		searchForFiles (val, prev) {
			if(val && prev && val.length > 3){
				this.searchFiles(val)
			}
		},
		project(newData) {
			if(newData && this.$store.projectsStore.state.selectedProject.id){
				this.$store.forgeStore.dispatch('fetchForgeFiles', {
					project_id: this.$store.projectsStore.state.selectedProject.id
				})
			}
		}
	},
	mounted(){
		if(this.$store.projectsStore.state.selectedProject){
			this.$store.forgeStore.dispatch('fetchForgeFiles', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
		}
	},
	methods:{
		isMainModel(item){
			return this.$store.forgeStore.state.projectMainFile && this.$store.forgeStore.state.projectMainFile.file_id == item.id
		},
		async setMainModel(item){
			if(this.isMainModel(item)){
				await deleteProjectMainModel({
					main_model_id: item.forge_file.id
				})
			}
			else{
				await setProjectMainModel({
					project_id: this.$store.projectsStore.state.selectedProject.id,
					file_id: item.id
				})
			}
			this.$store.forgeStore.dispatch('fetchForgeFiles', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
		},
		async deleteFile(file_id){
			await deleteFile({file_id})
			this.$store.forgeStore.dispatch('fetchForgeFiles', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
		},
		closeResults(){
			this.showResults = false
			this.searching = false
		},
		searchFiles: _debounce(function(){ this.searchForString(this.searchForFiles) }, 800),
		async searchForString(searchString){
			if((this.selectedFile && searchString && searchString == this.selectedFile.name) ||
				(searchString.length > 0 && searchString.length < 3)
				){ return false }
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				search: this.searchForFiles
			}
			this.$store.forgeStore.dispatch('fetchForgeFiles', data)
		},
		onButtonClick() {
			this.$refs.uploader.click()
		},
		uploadNewFileToForge(e){
			const file = e.target.files[0]
			const fileData = {
				type: 8,
				payload: {
					project_id: this.$store.projectsStore.state.selectedProject.id
				},
				description: 'Archivo Forge',
				total: file.size,
				uuid: uuidv4(),
				file
			}
			this.$store.globalStore.commit('setFilesToUploadCenter', fileData)
		}
	}
};
</script>


<style scoped>
	.text-primary{color: #2D4B95;}
	.custom-button-consultas.v-btn{
		background-color: white;
		color: #2D4B95;
		opacity: 1!important;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon,
	/deep/ .custom-button-consultas.v-btn p{
		color: #2D4B95!important;
		font-size: 13px;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon{font-size: 20px;}
	/deep/ .custom-button-consultas.v-btn:hover .v-icon,
	/deep/ .custom-button-consultas.v-btn:hover p,
	/deep/ .custom-button-consultas.v-btn.selected .v-icon,
	/deep/ .custom-button-consultas.v-btn.selected p{
		color: white!important;;
	}
	.custom-button-consultas.selected,
	.custom-button-consultas:hover{
		background-color: #2D4B95!important
	}

</style>