<template>
	<v-container fluid class="px-0" id="listado-consultas">
		<v-card class="mb-2 py-1">
			<v-card-text class="header-table-card py-2">
				<v-row>
					<v-col cols="1">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;" @click="toggleOrder">
							<v-icon color="#5e85ef" small class="mr-1">{{getIconOrder()}}</v-icon>
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Número</p>
						</v-btn>
					</v-col>
					<v-col cols="2">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;" @click="toggleOrder">
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Detalle</p>
						</v-btn>
					</v-col>
					<v-col cols="2">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;">
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Estado</p>
						</v-btn>
					</v-col>
					<v-col cols="7">
						<div class="d-flex align-center flex-column">
							<div class="d-flex align-center w-100">
								<p class="mb-0 font-weight-bold body-2 text-capitalize">Estados de Pago</p>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon style="height: auto;"
											v-bind="attrs"
											v-on="on"
											:disabled="newPaymentStatus"
											@click="addPaymentStatus">
											<v-icon color="#5e85ef" small>mdi-plus-circle</v-icon>
										</v-btn>
									</template>
									<span>Crear Nuevo Estado de Pago</span>
								</v-tooltip>
							</div>
							<div class="w-100">
								<v-row align="center" class="flex-nowrap overflow-auto"
									v-if="newPaymentStatus || ($store.adicionalesStore.state.listadoInformativeTable.payment_statuses && $store.adicionalesStore.state.listadoInformativeTable.payment_statuses.length > 0)">
									<v-col cols="3"
										v-for="(ps, index) in $store.adicionalesStore.state.listadoInformativeTable.payment_statuses" :key="index">
										<p class="caption mb-0"><small>Estado de Pago {{index + 1}}</small></p>
										<div class="d-flex" v-if="observingPaymentStatus == null">
											<v-btn
												color="success"
												class="flex-grow-1"
												x-small
												:loading="$store.adicionalesStore.state.listadoInformativeTable.status === 99 || loadingNewPS"
												@click.stop="sendNewPaymentStatus"
											>
												<p class="mb-0 white--text">Aprobar</p>
											</v-btn>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn text icon style="height: auto;"
														v-bind="attrs"
														v-on="on"
														@click="observingPaymentStatus = index">
														<v-icon color="orange">mdi-alert-octagon</v-icon>
													</v-btn>
												</template>
												<span>Agregar observación a estado de pago</span>
											</v-tooltip>
										</div>
										<div class="d-flex" v-else>
											<v-btn
												color="#2D4B95"
												class="flex-grow-1"
												x-small
												:loading="$store.adicionalesStore.state.listadoInformativeTable.status === 99 || loadingNewPS"
												@click.stop="sendObservations"
											>
												<p class="mb-0 white--text">Enviar</p>
											</v-btn>
											<v-btn text icon style="height: auto;"
												@click="cancelObservations">
												<v-icon color="red" small>mdi-close-circle</v-icon>
											</v-btn>
										</div>
									</v-col>
									<v-col cols="3" class="d-flex flex-column justify-center align-center" v-if="newPaymentStatus">
										<p class="caption mb-0"><small>Nuevo Estado Pago</small></p>
										<div>
											<v-btn
												color="#2D4B95"
												x-small
												:loading="$store.adicionalesStore.state.listadoInformativeTable.status === 99 || loadingNewPS"
												@click.stop="sendNewPaymentStatus"
											>
												<p class="mb-0 white--text">Confirmar</p>
											</v-btn>
											<v-btn text icon style="height: auto;"
												@click="closeFormPS">
												<v-icon color="red" small>mdi-close-circle</v-icon>
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</div>
						</div>
						<!-- <v-btn
							dark
							rounded
							x-small
							color="#5E85EF"
							class="mx-1"
							@click="showRevDetails = !showRevDetails"
						>
							<p class="mb-0 text-capitalize">Detalle Revisiones</p>
						</v-btn> -->
					</v-col>
					<!-- <v-col cols="2" class="px-0">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;">
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Información Aprobación</p>
						</v-btn>
					</v-col> -->
				</v-row>
			</v-card-text>
			<v-progress-linear
				v-if="$store.adicionalesStore.state.listadoInformativeTable.status === 99"
				class="listing-loading-bar"
				indeterminate
				color="#5e85ef"
			></v-progress-linear>
		</v-card>
		<div v-if="$store.adicionalesStore.state.listadoInformativeTable.status === 1 || $store.adicionalesStore.state.listadoInformativeTable.count > 0" class="listing-scroll">
			<v-card class="tarea-card mb-2" v-for="(additional, index) in $store.adicionalesStore.state.listadoInformativeTable.data" :key="index"
			style="overflow: hidden;"
			@click.stop="viewAdditional(additional.id, $options.filters.seenByMe(additional.seen_by_me))">
				<v-card-text class="header-table-card py-2"
					:class="additional.status === 3 ? 'status-ended':''">
					<v-row align="center" class="flex-nowrap overflow-auto">
						<v-col cols="1">
							<div class="d-flex flex-column">
								<div class="d-flex flex-row icon-container">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top number"
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 white--text text-task-number caption">{{additional.number}}</p>
											</div>
										</template>
										<span>Adicional número {{additional.number}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top d-flex justify-center align-center" :class="$options.filters.seenByMeColor(additional.seen_by_me)"
												v-bind="attrs"
												v-on="on"
												@click.stop="changeVisualizationStatus(additional)">
												<v-icon color="white"
													:class="$options.filters.seenByMeIconAnimation(additional.seen_by_me)"
													v-if="!isLoadingChangeVisualizationStatus(additional.id)"
													small>mdi-bell</v-icon>
												<v-progress-circular
													v-else
													:width="2"
													:size="16"
													color="white"
													indeterminate
												></v-progress-circular>
											</div>
										</template>
										<span>{{$options.filters.seenByMeText(additional.seen_by_me)}} Adicional</span>
									</v-tooltip>
								</div>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="caption" style="margin-right: 1px">
								<div class="font-weight-bold additional-list-title" v-html="additional.title"></div>
								<div class="d-flex flex-row align-center" v-if="(additional.total_progress) > 0">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="#2D4B95" class="mr-1" small
												v-bind="attrs"
												v-on="on">mdi-progress-check</v-icon>
										</template>
										<span>Porcentaje de avance total</span>
									</v-tooltip>
									<v-progress-linear
										:value="(additional.total_progress)*100"
										color="primary"
										height="12"
										rounded
									>
										<small :class="(additional.total_progress) < 0.43 ? '':'white--text'">{{ Math.ceil((additional.total_progress)*100) }}%</small>
									</v-progress-linear>
								</div>
								<div class="d-flex flex-row align-center mt-1 mr-1" v-if="additional.total_amount > 0">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="#2D4B95" class="mr-1" small
												v-bind="attrs"
												v-on="on">mdi-currency-usd</v-icon>
										</template>
										<span>Monto total solicitado</span>
									</v-tooltip>
									<p class="mb-0 mr-2 normal font-weight-bold">{{additional.last_revision.currency_info.code}}${{additional.total_amount}}</p>
								</div>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="d-flex flex-row align-center justify-start">
								<div class="d-flex flex-column flex-grow-1">
									<p class="mb-0 body-2 text--secondary caption">
										<v-icon color="#2D4B95" class="mr-1" small>mdi-clipboard-edit-outline</v-icon>
										<span class="normal font-weight-bold">{{ additional.statustext }}</span>
									</p>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row justify-start align-center"
												v-bind="attrs"
												v-on="on">
												<div class="d-flex flex-row align-center mr-1">
													<v-icon color="#2D4B95" class="mr-1" small>mdi-currency-usd</v-icon>
													<p class="mb-0 normal caption font-weight-bold">{{additional.last_revision.currency_info.code}}${{additional.last_revision.total_requested | toThousandFilter}}</p>
												</div>
											</div>
										</template>
										<span>{{additional.approved_status ? 'Monto Aprobado en última revisión':'Monto Actual'}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row justify-start align-center"
												v-bind="attrs"
												v-on="on">
												<div class="d-flex flex-row align-center mr-1">
													<v-icon color="#2D4B95" class="mr-1" small>mdi-calendar</v-icon>
													<p class="mb-0 normal caption font-weight-bold">{{additional.last_revision.created_at | toDate}}</p>
												</div>
											</div>
										</template>
										<span>Fecha Última Revisión</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row justify-start align-center"
												v-if="additional.approved_status_change"
												v-bind="attrs"
												v-on="on">
												<div class="d-flex flex-row align-center mr-1">
													<v-icon color="#2D4B95" class="mr-1" small>mdi-calendar-check</v-icon>
													<p class="mb-0 normal caption font-weight-bold">{{additional.approved_status_change.created_at | toDate}}</p>
												</div>
											</div>
										</template>
										<span>Fecha Acuerdo</span>
									</v-tooltip>
								</div>
							</div>
						</v-col>
						<v-col cols="7">
							<v-row align="center">
								<v-col cols="3" class="pa-0"
									v-for="(ps, index) in paymentStatuses" :key="index">
									<ObservationPaymentStatusAdditionalForm v-if="observingPaymentStatus == index" :additional="additional" :additional_included="ps.additional_included" :closeForm="() => observingPaymentStatus = null" :onChangeProgress="(value) => {onChangeProgressObservation(value, additional)}"/>
									<AdditionalPaymentStatusIncluded v-else :additional="additional" :additional_included="ps.additional_included" :disabled="loading"/>
								</v-col>
								<v-col cols="3" class="pa-0"
									@click.stop="() => {}"
									v-if="newPaymentStatus">
									<NewPaymentStatusAdditionalForm :additional="additional" :closeForm="() => newPaymentStatus = []" :onChangeProgress="(value) => {onChangeProgress(value, additional)}"/>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-row align="center">
				<v-col cols="12">
					<v-pagination
						v-model="page"
						color="#2D4B95"
						:length="Math.ceil($store.rdiStore.state.listadoRDITotal/50)"
						:total-visible="7"
						@input="changePage"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-card class="mb-2 py-2" v-for="i in 5" :key="i">
				<v-card-text class="py-2">
					<v-row>
						<v-col cols="1">
							<div class="d-flex flex-row">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="4">
							<div class="d-flex flex-row justify-center align-center">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="3">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
	</v-container>
</template>

<script>
import moment from 'moment'
import NewPaymentStatusAdditionalForm from './Parts/NewPaymentStatusAdditionalForm'
import ObservationPaymentStatusAdditionalForm from './Parts/ObservationPaymentStatusAdditionalForm.vue'
import AdditionalPaymentStatusIncluded from './Parts/AdditionalPaymentStatusIncluded.vue'
import {changeVisualizationStatus, newPaymentStatus, newPaymentStatusInteraction} from '@/helpers/api/additionals'

export default {
	name: 'TablaInformativaAdicionales',
	components: {AdditionalPaymentStatusIncluded, NewPaymentStatusAdditionalForm, ObservationPaymentStatusAdditionalForm},
	props: {
		ended:{
			type: Boolean,
			default: false
		}
	},
  computed: {
    selectedProject() {
      return this.$store.projectsStore.state.selectedProject
    },
		paymentStatuses() {
			return this.$store.adicionalesStore.state.listadoInformativeTable.payment_statuses
		}
  },
  watch: {
    selectedProject(newData) {
      if(newData){
				this.getProjectSubjects()
      }
    }
  },
	data: () => ({
		specialtyFilter: [],
		specialtyMenu: false,
		subjectFilter: [],
		subjectMenu: false,
		responsableFilter: [],
		responsableMenu: false,
		estadoMenu: false,
		estadoFilter: [1, 2, 3, 4],
		page: 1,
		showRevDetails: false,
		newPaymentStatus: false,
		newPaymentStatusData: [],
		loadingNewPS: false,
		observingPaymentStatus: null,
		observationsArray: []
	}),
	mounted(){
    if(this.$store.projectsStore.state.projectSubjects.length == 0){
      this.getProjectSubjects()
    }
	},
	methods: {
		paymentStatusTotal(paymentStatusIncluded){
			return paymentStatusIncluded.reduce(
				(accumulator, currentValue) => accumulator + currentValue.amount,
				0
			)
		},
		async sendNewPaymentStatus(){
			this.loadingNewPS = true
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				additionals_included: this.newPaymentStatusData
			}
			try {
				await newPaymentStatus(data)
				this.newPaymentStatus = false
				this.newPaymentStatusData = []
				this.getAdditionalList()
			} catch (error) {
        const datas = {
					message: 'Hubo un error al crear el estado de pago. Por favor intenta nuevamente.',
					title: 'Error',
					created: new Date(),
					type: 'warning',
					icon: 'mdi-alert-circle',
					color: '#fb8c00'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
			}
			this.loadingNewPS = false
		},
		onChangeProgress(value, additional){
			if(!value){
				const foundIndex = this.newPaymentStatusData.findIndex((ps) => {
					return ps.additional_id == additional.id
				})
				if(foundIndex != -1){
					this.newPaymentStatusData.splice(foundIndex, 1)
				}
				return
			}
			const amount = +parseFloat(additional.last_revision.total_requested*value/100).toFixed( 2 );
			const data = {
				additional_id: additional.id,
				progress: value/100,
				amount
			}
			const foundIndex = this.newPaymentStatusData.findIndex((ps) => {
				return ps.additional_id == additional.id
			})
			let newArray = [...this.newPaymentStatusData]
			if(foundIndex != -1){
				newArray[foundIndex] = data
			}
			else{
				newArray.push(data)
			}
			this.newPaymentStatusData = newArray
		},
		onChangeProgressObservation(value){
			const foundIndex= this.observationsArray.findIndex((obs) => {
				return obs.additional_payment_status_id = value.additional_payment_status_id
			})
			if(foundIndex != -1){
				let newArray = [...this.observationsArray]
				newArray[foundIndex] = value
				this.observationsArray = newArray
			}
			else{
				this.observationsArray.push(value)
			}
		},
		cancelObservations(){
			this.observingPaymentStatus = null
			this.observationsArray = []
		},
		sendObservations(){
			if(this.observationsArray && this.observationsArray.length > 0){
				this.loading = true
				this.observationsArray.forEach(async (data) => {
					await newPaymentStatusInteraction(data)
				})
				this.getAdditionalList()
				this.cancelObservations()
				this.loading = false
			}
		},
		addPaymentStatus(){
			this.newPaymentStatus = true
		},
		closeFormPS(){
			this.newPaymentStatus = false
			this.newPaymentStatusData = []
		},
		psClick(ps){
			console.log('ps: ', ps)
		},
		statusColorClass(additional){
			if([3, 6].includes(additional.status)){ return 'normal' }
			else if([1, 2].includes(additional.status)){ return 'medium' }
			return 'high'
		},
		revsDetailsData(additionalData){
			if(this.showRevDetails){
				return additionalData.revisions ? additionalData.revisions:[]
			}
			return []
		},
		getAdditionalType(additionalType){
			const found = this.$store.adicionalesStore.state.additionalTypes.find(type => type.id === additionalType)
			if(found){
				return found.name
			}
			return '--'
		},
		async changeVisualizationStatus(rdi){
			if(!this.isLoadingChangeVisualizationStatus(rdi.id)){
				let newStatus = 0
				if(!rdi.seen_by_me){
					newStatus = 1
				}
				else if(rdi.seen_by_me.seen_status < 2){
					newStatus = rdi.seen_by_me.seen_status + 1
				}
				else if(rdi.seen_by_me.seen_status === 2){
					newStatus = 0
				}

				this.$store.rdiStore.commit('addLoadingChangeVisualizationStatus', rdi.id)
				const data = {
					rdi_id: rdi.id,
					status: newStatus
				}
				const statusUpdate = await changeVisualizationStatus(data)
				if(statusUpdate.code === 200){
					// Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
					rdi.seen_by_me.seen_status = newStatus
				}
				this.$store.rdiStore.commit('removeLoadingChangeVisualizationStatus', rdi.id)
			}
		},
		isLoadingChangeVisualizationStatus(rdiID){
			const found = this.$store.rdiStore.state.loadingChangeVisualizationStatus.find((rdi_id) => rdi_id === rdiID)
			return found
		},
		clearFilters(){
			this.creatorFilter = []
			this.responsableFilter = []
		},
    async getProjectSubjects(){
			if(this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.id){
				this.$store.projectsStore.dispatch('getProjectSubjects', this.$store.projectsStore.state.selectedProject.id)
			}
    },
		getIconOrder(){
			if(this.$store.rdiStore.state.listingOrder === 1 || this.$store.rdiStore.state.listingOrder === 3){
				return 'mdi-arrow-down-bold'
			}
			else if(this.$store.rdiStore.state.listingOrder === 2 || this.$store.rdiStore.state.listingOrder === 4){
				return 'mdi-arrow-up-bold'
			}
			return 'mdi-swap-vertical-bold'
		},
		toggleOrder(){
			this.$store.rdiStore.commit('setListingOrder')
			this.getAdditionalList()
		},
		getNameOrder(){
			if(this.$store.rdiStore.state.listingOrder === 1 || this.$store.rdiStore.state.listingOrder === 2){
				return 'Prioridad'
			}
			else if(this.$store.rdiStore.state.listingOrder === 3 || this.$store.rdiStore.state.listingOrder === 4){
				return 'Número'
			}
		},
		async getAdditionalList(){
			let project_id = null
			const page = this.page
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.adicionalesStore.dispatch('getAdditionalInformativeTable',
					{
						project_id, page,
						statuses: this.estadoFilter,
						filtered_specialties: this.specialtyFilter,
						filtered_responsable: this.responsableFilter
					})
			}
		},
		getTareaPriorityText(rdi){
			if(rdi.priority === 3){
				return 'Inmediata'
			}
			else if(rdi.priority === 2){
				return 'Media'
			}
			return 'Estándar'
		},
		checkIfNearDeadline(rdi){
			if(!rdi.critical_date){ return false }
			const now = moment()
			const rdi_date = moment(rdi.critical_date)
			return rdi_date.diff(now, 'days') <= 2
		},
		setLights(data){
			return require('@/assets/Svg/VistaListado/Semaforo'+data+'.svg')
		},
		filterList(){
			this.getAdditionalList()
			this.specialtyMenu = false
			this.subjectMenu = false
			this.responsableMenu = false
		},
		async viewAdditional(additional_id, seen_by_me){
			this.$store.adicionalesStore.dispatch('getAdditionalByID', {additional_id, seen_by_me})
		},
		changePage(){
			this.page
			this.getAdditionalList()
		},
		responseStatus(rdi){
			if(rdi.response_status == 1){
				return this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.job_type && this.$store.projectsStore.state.selectedProject.job_type.id == 4
			}
			else if(rdi.response_status == 2){
				return this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.job_type && this.$store.projectsStore.state.selectedProject.job_type.id != 4
			}
			return false
		}
	}
};
</script>

<style scoped>
	#listado-consultas{align-self: stretch;}
	.text-primary-color{
		color: #2D4B95;
	}
	.light-blue-text{color: #5E85EF;}
	.bg-normal{ background-color: #3EBFA3; }
	.inner-text{
		font-size: 10px;
	}
	.status-container{
		position: relative;
	}
	.icon-top.icon-status{
		position: absolute;
		width: 20px;
		height: 20px;
		left: -20px;
	}
	.icon-top{
		padding: 2px;
		margin: 0 2px;
		border-radius: 100%;
	}
	.high{
		color: #F4516C;
	}
	.medium{
		color: #fb8c00;
	}
	.normal{
		color: #3EBFA3;
	}
	.bg-high, .icon-top.high{
		background-color: #F4516C;
	}
	.bg-medium, .icon-top.medium{
		background-color: #fb8c00;
	}
	.bg-normal, .icon-top.normal{
		background-color: #3EBFA3;
	}
	.icon-top.primary{
		background-color: #2D4B95;
	}
	.icon-top.light-blue{
		background-color: #5E85EF;
	}
	.counter-tareas{
		background-color: #F4516C;
		border-radius: 100%;
		padding: 5px;
		width: 30px;
		height: 30px;
		line-height: 20px;
		color: white;
		font-size: 20px;
		text-align: center;
	}
	.status-icon{
		border: 1px solid #5e85ef;
		border-radius: 100%;
	}
	/deep/ .status-icon .inner{
		border: 1px solid #f2f2f2;
		border-radius: 100%;
		width: 14px;
		height: 14px;
	}
	/deep/ .status-icon .inner.danger{
		background-color: #F4516C;
	}
	/deep/ .header-table-card .table-header-button:hover:before{
		opacity: 0;
	}
	.text-tarea-field{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3; /* number of lines to show */
		-webkit-box-orient: vertical;
		max-height: 50px;
	}
	/deep/ .text-tarea-field h1,/deep/ .text-tarea-field h2,/deep/ .text-tarea-field h3{
		font-size: 1em!important;
	}
	.icon-container{flex-wrap: wrap;}
	.icon-container .icon-top{
		flex: 1 0 30%;
		width: 24px;
		max-width: 24px;
		height: 24px;
		max-height: 24px;
		margin-bottom: 2px;
	}
	.icon-container .icon-top.number{
		background-color: #2D4B95;
		text-align: center;
		line-height: 20px;
	}
	.text-task-number.caption{ font-size: 10px!important; }
	.icon-container .icon-top.total{
		background-color: #5E85EF;
		text-align: center;
		line-height: 20px;
	}
	.critical-date-container{
		background-color: #5E85EF;
	}
	.listing-loading-bar{
		position: absolute;
		bottom: 0;
	}
	.header-table-card{
		border: 1px solid white;
	}
	.status-ended{
		border: 1px solid #4caf50;
	}
/*	.listing-scroll{
		overflow-y: scroll;
		height: 100%;
	}*/
	.tarea-card{
		position: relative;
	}
	.incognito-container{
		width: 0;
		height: 0;
		border-top: 33px solid #616161;
		border-right: 33px solid transparent;
		border-radius: 0!important;
		position: absolute;
		left: 0px;
	}
	.incognito-container .v-icon{
		position: absolute;
		top: -32px;
		left: 1px;
	}
	.float-total-icon{
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
	}
	.near-deadline{
		background-color: #fb8c00;
	}
	.payment-status-container:hover{
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
	.additional-list-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
	/deep/.additional-list-description p{
		margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
	.w-100{width: 100%}
</style>