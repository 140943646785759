<template>
	<v-container fluid class="px-0" id="listado-consultas">

		<v-card class="mb-2 py-1">
			<v-card-text class="header-table-card py-2">
				<v-row>
					<v-col cols="10">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									rounded
									color="#2D4B95"
									dark
									small
									block
									v-bind="attrs"
									v-on="on"
								>
									Filtros
								</v-btn>
							</template>
							<v-list>
								<v-list-item>
									<v-btn class="custom-button-consultas d-flex flex-row align-center justify-space-between"
										block
										@click="toggleOrder">
										<p class="mb-0 font-weight-bold body-2 text-capitalize">
											{{getNameOrder()}}</p>
										<v-icon color="#5e85ef" small>{{getIconOrder()}}</v-icon>
									</v-btn>
								</v-list-item>
								<v-list-item>
									<v-menu v-model="specialtyMenu" :offset-y="true" :close-on-content-click="false">
										<template v-slot:activator="{ on, attrs }">
											<v-btn class="custom-button-consultas d-flex flex-row align-center justify-space-between" block
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 font-weight-bold body-2 text-capitalize">Especialidad</p>
												<v-icon color="#5e85ef" small>mdi-plus-circle</v-icon>
											</v-btn>
										</template>

										<v-list dense class="filter-selector-list">
											<v-list-item dense style="min-height: auto;">
												<div style="width: 100%" class="mb-2">
													<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
													<v-divider></v-divider>
												</div>
											</v-list-item>
											<v-list-item-group multiple v-model="specialtyFilter" v-if="$store.projectsStore.state.selectedProject">
												<v-list-item
													style="min-height: auto;"
													v-for="(user, index) in $store.projectsStore.state.selectedProject.specialties"
													:key="index"
													:value="user.id">
													<template v-slot:default="{ active }">
														<v-list-item-title class="my-0 d-flex flex-row align-center">
															<v-checkbox
																dense
																hide-details
																class="caption my-0 py-0"
																:input-value="active"></v-checkbox>
															<p class="mb-0 caption">{{user.name}}</p>
														</v-list-item-title>
													</template>
												</v-list-item>
											</v-list-item-group>
											<div class="d-flex justify-center" style="width: 100%" v-else>
												<v-progress-circular
													:size="20"
													:width="2"
													color="#2D4B95"
													indeterminate
												></v-progress-circular>
											</div>
											<v-list-item dense style="min-height: auto;">
												<v-btn x-small block color="#5e85ef" dark class="mt-2" @click="filterList()">
													<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
													<p class="mb-0 caption text-capitalize">Aplicar</p>
												</v-btn>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-list-item>
								<v-list-item>
									<v-menu v-model="subjectMenu" :offset-y="true" :close-on-content-click="false">
										<template v-slot:activator="{ on, attrs }">
											<v-btn class="custom-button-consultas flex-row align-center justify-space-between" block
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 font-weight-bold body-2 text-capitalize">Materia</p>
												<v-icon color="#5e85ef" small>mdi-plus-circle</v-icon>
											</v-btn>
										</template>

										<v-list dense class="filter-selector-list">
											<v-list-item dense style="min-height: auto;">
												<div style="width: 100%" class="mb-2">
													<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
													<v-divider></v-divider>
												</div>
											</v-list-item>
											<v-list-item-group multiple v-model="subjectFilter" v-if="$store.projectsStore.state.projectSubjects">
												<v-list-item
													style="min-height: auto;"
													v-for="(subject, index) in $store.projectsStore.state.projectSubjects"
													:key="index"
													:value="subject.id">
													<template v-slot:default="{ active }">
														<v-list-item-title class="my-0 d-flex flex-row align-center">
															<v-checkbox
																dense
																hide-details
																class="caption my-0 py-0"
																:input-value="active"></v-checkbox>
															<p class="mb-0 caption">{{subject.name}}</p>
														</v-list-item-title>
													</template>
												</v-list-item>
											</v-list-item-group>
											<div class="d-flex justify-center" style="width: 100%" v-else>
												<v-progress-circular
													:size="20"
													:width="2"
													color="#2D4B95"
													indeterminate
												></v-progress-circular>
											</div>
											<v-list-item dense style="min-height: auto;">
												<v-btn x-small block color="#5e85ef" dark class="mt-2"
													:loading="!$store.projectsStore.state.projectSubjects"
													@click="filterList()">
													<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
													<p class="mb-0 caption text-capitalize">Aplicar</p>
												</v-btn>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-list-item>
								<v-list-item>
									<v-menu v-model="responsableMenu" :offset-y="true" :close-on-content-click="false">
										<template v-slot:activator="{ on, attrs }">
											<v-btn class="custom-button-consultas flex-row align-center justify-space-between" block
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 font-weight-bold body-2 text-capitalize">Responsable</p>
												<v-icon color="#5e85ef" small>mdi-plus-circle</v-icon>
											</v-btn>
										</template>

										<v-list dense class="filter-selector-list">
											<v-list-item dense style="min-height: auto;">
												<div style="width: 100%" class="mb-2">
													<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
													<v-divider></v-divider>
												</div>
											</v-list-item>
											<v-list-item-group multiple v-model="responsableFilter">
												<v-list-item
													style="min-height: auto;"
													v-for="(resp, index) in [{value: 2, name: 'Jefe OT'}, {value: 1, name: 'ITO'}]"
													:key="index"
													:value="resp.value">
													<template v-slot:default="{ active }">
														<v-list-item-title class="my-0 d-flex flex-row align-center">
															<v-checkbox
																dense
																hide-details
																class="caption my-0 py-0"
																:input-value="active"></v-checkbox>
															<p class="mb-0 caption">{{resp.name}}</p>
														</v-list-item-title>
													</template>
												</v-list-item>
											</v-list-item-group>
											<v-list-item dense style="min-height: auto;">
												<v-btn x-small block color="#5e85ef" dark class="mt-2" @click="filterList()">
													<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
													<p class="mb-0 caption text-capitalize">Aplicar</p>
												</v-btn>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
					<v-col cols="2">
						<div class="flex-row justify-center align-center">
							<div class="d-flex flex-row justify-end align-center py-0 icon-container float-total-icon">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<div class="icon-top total"
											v-bind="attrs"
											v-on="on">
											<span class="white--text caption">{{$store.rdiStore.state.listadoRDI.total_rdi}}</span>
										</div>
									</template>
									<span>Total de RDI</span>
								</v-tooltip>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-progress-linear
				v-if="$store.rdiStore.state.listadoRDI.status === 99"
				class="listing-loading-bar"
				indeterminate
				color="#5e85ef"
			></v-progress-linear>
		</v-card>
		<div v-if="$store.rdiStore.state.listadoRDI.status === 1 || $store.rdiStore.state.listadoRDI.count > 0" class="listing-scroll">
			<v-card class="tarea-card mb-2" v-for="(rdi, index) in $store.rdiStore.state.listadoRDI.data" :key="index"
			style="overflow: hidden;"
			@click.stop="viewRDI(rdi.id, $options.filters.seenByMe(rdi.seen_by_me))">
				<v-tooltip bottom
							v-if="rdi.private">
					<template v-slot:activator="{ on, attrs }">
						<div class="incognito-container"
							v-bind="attrs"
							v-on="on">
							<v-icon color="white" small>mdi-incognito</v-icon>
						</div>
					</template>
					<span>Tarea privada: sólo la puedes ver tú y el responsable</span>
				</v-tooltip>
				<v-card-text class="header-table-card py-2"
					:class="rdi.status === 3 ? 'status-ended':''">
					<v-row align="center">
						<v-col cols="4">
							<div class="d-flex flex-column">
								<div class="d-flex flex-row icon-container">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top number"
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 white--text text-task-number caption">{{rdi.number}}</p>
											</div>
										</template>
										<span>RDI número {{rdi.number}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="white"
												class="icon-top"
												v-bind="attrs"
												v-on="on"
												:class="getTareaPriorityColor(rdi)" small>mdi-exclamation</v-icon>
										</template>
										<span>Prioridad {{getTareaPriorityText(rdi)}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top d-flex justify-center align-center" :class="$options.filters.seenByMeColor(rdi.seen_by_me)">
												<v-icon color="white"
													:class="$options.filters.seenByMeIconAnimation(rdi.seen_by_me)"
													v-bind="attrs"
													v-on="on"
													small>mdi-bell</v-icon>
											</div>
										</template>
										<span>{{$options.filters.seenByMeText(rdi.seen_by_me)}} RDI</span>
									</v-tooltip>
								</div>
							</div>
						</v-col>
						<v-col cols="8">
							<p class="mb-0 light-blue-text caption">Especialidades</p>
							<div v-for="(specialty, index) in rdi.specialties"
								:key="index" class="light-blue-text caption font-weight-bold d-inline-block" style="margin-right: 1px">
								{{specialty.specialty.name}}<span v-if="index + 1 < rdi.specialties.length">,</span>
							</div>
							<v-divider></v-divider>
							<p class="mb-0 light-blue-text caption">Materia</p>
							<div class="d-flex flex-row justify-start align-center">
								<p class="mb-0 body-2 text--secondary caption">{{rdi.subject.name}}</p>
							</div>
								<!-- <v-chip
									v-for="(specialty, index) in rdi.specialties"
									:key="index"
									color="#5E85EF"
									dark
									small
									class="mx-1">
										<small>{{specialty.specialty.name}}</small>
								</v-chip> -->
						</v-col>
						<v-col cols="12" class="pa-0">
							<v-divider></v-divider>
						</v-col>
						<v-col cols="6" class="px-1">
							<div class="d-flex flex-column justify-start align-center">
								<p class="mb-0 body-2 text--secondary">{{rdi.title}}</p>
								<div class="mb-0 caption body-2 text--secondary text-rdi-field" style="width: 100%;" v-html="rdi.description"></div>
							</div>
						</v-col>
						<v-divider vertical></v-divider>
						<v-col cols="6" class="px-1">
							<div class="d-flex flex-column justify-start align-center">

								<v-tooltip bottom v-if="rdi.status != 3">
									<template v-slot:activator="{ on, attrs }">
										<div class="d-flex flex-column mr-1 rounded px-1"
											v-bind="attrs"
											v-on="on">
											<div class="d-flex flex-row mr-1">
												<v-icon small :color="responseStatus(rdi) ? '#fb8c00':'#3EBFA3'" class="mr-1">mdi-account-outline</v-icon>
												<p class="mb-0 font-weight-bold"
													:class="responseStatus(rdi) ? 'medium':'normal'">{{rdi.response_status | rdiResponsableUserType}}</p>
											</div>
											<p class="d-flex mb-0 caption align-center" v-if="rdi.waitingresponsetime != 0">
												<v-icon x-small color="#2D4B95" class="mr-1">mdi-clock-time-seven-outline</v-icon>
												{{ rdi.waitingresponsetime }}
											</p>
										</div>
									</template>
									<span>Responsable Actual - Hace: {{ rdi.waitingresponsetime }}</span>
								</v-tooltip>
								<div v-else class="d-flex flex-row align-center mr-1">
									<v-icon color="#3EBFA3" class="mr-1">mdi-alert-circle-check</v-icon>
									<p class="mb-0 font-weight-bold normal caption">Finalizada</p>
								</div>

								<!-- <v-tooltip bottom v-if="responseStatus(rdi)">
									<template v-slot:activator="{ on, attrs }">
										<p class="mb-0 font-weight-bold medium"
											v-bind="attrs"
											v-on="on">En espera</p>
									</template>
									<span>Se está esperando tu respuesta</span>
								</v-tooltip>
								<v-tooltip bottom v-else>
									<template v-slot:activator="{ on, attrs }">
										<p class="mb-0 font-weight-bold normal"
											v-bind="attrs"
											v-on="on">Respondida</p>
									</template>
									<span>Ya respondiste esta RDI, se espera que ITO responda.</span>
								</v-tooltip> -->
								<div class="status-container d-flex flex-row align-center mt-3">
									<v-img :src="setLights(rdi.lightstatus)"
										max-height="50"
										max-width="35"
										contain
										class="mr-1"/>
									<div>
			<!-- 							<v-tooltip bottom v-if="checkIfNearDeadline(rdi)">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white"
													class="icon-status icon-top medium"
													v-bind="attrs"
													v-on="on"
													small v-if="!rdi.seen">mdi-flag-outline</v-icon>
											</template>
											<span>Se acerca la fecha crítica {{rdi.critical_date | toDate}}</span>
										</v-tooltip> -->
										<div class="d-flex flex-row mr-1 rounded">
											<v-icon x-small color="#2D4B95" class="mr-1">mdi-calendar-range-outline</v-icon>
											<p class="mb-0 text--secondary caption"><small>{{rdi.created_at | toDate}} {{rdi.created_at | toHour}}</small></p>
										</div>

										<v-tooltip bottom v-if="rdi.critical_date">
											<template v-slot:activator="{ on, attrs }">
												<div class="d-flex flex-row mr-1 rounded px-1"
													:class="checkIfNearDeadline(rdi) ? 'near-deadline':''"
													v-bind="attrs"
													v-on="on">
													<v-icon small :color="checkIfNearDeadline(rdi) ? 'white':'#2D4B95'" class="mr-1">mdi-calendar-range-outline</v-icon>
													<p class="mb-0 caption"
														:class="checkIfNearDeadline(rdi) ? 'white--text':'text--secondary'"><small>{{rdi.critical_date | toDate}}</small></p>
												</div>
											</template>
											<span>Fecha crítica: {{rdi.critical_date | toDate}}</span>
										</v-tooltip>

										<v-tooltip bottom v-if="rdi.ended_at">
											<template v-slot:activator="{ on, attrs }">
												<div class="d-flex flex-row mr-1 rounded px-1 bg-normal mt-1"
													v-bind="attrs"
													v-on="on">
													<v-icon small color="white" class="mr-1">mdi-calendar-range-outline</v-icon>
													<p class="mb-0 caption white--text">{{rdi.ended_at | toDate('YYYY-MM-DD HH:mm:ss')}}</p>
												</div>
											</template>
											<span>Fecha término: {{rdi.ended_at | toDate('YYYY-MM-DD HH:mm:ss')}}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-row align="center">
				<v-col cols="12">
					<v-pagination
						v-model="page"
						color="#2D4B95"
						:length="Math.ceil($store.rdiStore.state.listadoRDITotal/50)"
						:total-visible="7"
						@input="changePage"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-card class="mb-2 py-2" v-for="i in 5" :key="i">
				<v-card-text class="py-2">
					<v-row>
						<v-col cols="1">
							<div class="d-flex flex-row">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="4">
							<div class="d-flex flex-row justify-center align-center">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="3">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
		
	</v-container>
</template>

<script>
import moment from 'moment'
import {getRdiByID} from '@/helpers/api/rdi'

export default {
	name: 'ListadoRDIMobile',
	components: {},
	props: {
		ended:{
			type: Boolean,
			default: false
		}
	},
  computed: {
    selectedProject() {
      return this.$store.projectsStore.state.selectedProject
    }
  },
  watch: {
    selectedProject(newData) {
      if(newData){
				this.getProjectSubjects()
      }
    }
  },
	data: () => ({
		specialtyFilter: [],
		specialtyMenu: false,
		subjectFilter: [],
		subjectMenu: false,
		responsableFilter: [],
		responsableMenu: false,
		page: 1,
	}),
	mounted(){
    if(this.$store.projectsStore.state.projectSubjects.length == 0){
      this.getProjectSubjects()
    }
	},
	methods: {
		clearFilters(){
			this.creatorFilter = []
			this.responsableFilter = []
		},
    async getProjectSubjects(){
			if(this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.id){
				this.$store.projectsStore.dispatch('getProjectSubjects', this.$store.projectsStore.state.selectedProject.id)
			}
    },
		getIconOrder(){
			if(this.$store.rdiStore.state.listingOrder === 1 || this.$store.rdiStore.state.listingOrder === 3){
				return 'mdi-arrow-down-bold'
			}
			else if(this.$store.rdiStore.state.listingOrder === 2 || this.$store.rdiStore.state.listingOrder === 4){
				return 'mdi-arrow-up-bold'
			}
			return 'mdi-swap-vertical-bold'
		},
		toggleOrder(){
			this.$store.rdiStore.commit('setListingOrder')
			this.getRDIList()
		},
		getNameOrder(){
			if(this.$store.rdiStore.state.listingOrder === 1 || this.$store.rdiStore.state.listingOrder === 2){
				return 'Prioridad'
			}
			else if(this.$store.rdiStore.state.listingOrder === 3 || this.$store.rdiStore.state.listingOrder === 4){
				return 'Número'
			}
		},
		async getRDIList(){
			let project_id = null
			const page = this.page
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.rdiStore.dispatch('getRDI',
					{
						project_id, page,
						filtered_specialties: this.specialtyFilter,
						filtered_subjects: this.subjectFilter,
						filtered_responsable: this.responsableFilter
					})
			}
		},
		getTareaPriorityColor(rdi){
			if(rdi.priority === 3){
				return 'high'
			}
			else if(rdi.priority === 2){
				return 'medium'
			}
			return 'normal'
		},
		getTareaPriorityText(rdi){
			if(rdi.priority === 3){
				return 'Inmediata'
			}
			else if(rdi.priority === 2){
				return 'Media'
			}
			return 'Estándar'
		},
		checkIfNearDeadline(rdi){
			if(!rdi.critical_date){ return false }
			const now = moment()
			const rdi_date = moment(rdi.critical_date)
			return rdi_date.diff(now, 'days') <= 2
		},
		setLights(data){
			return require('@/assets/Svg/VistaListado/Semaforo'+data+'.svg')
		},
		filterList(){
			this.getRDIList()
			this.specialtyMenu = false
			this.subjectMenu = false
			this.responsableMenu = false
		},
		async viewRDI(rdi_id, seen_by_me){
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				let rdiData = rdi.rdi
				rdiData.prev_seen_by_me = seen_by_me
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
		},
		changePage(){
			this.page
			this.getRDIList()
		},
		responseStatus(rdi){
			if(rdi.response_status == 1){
				return this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.job_type && this.$store.projectsStore.state.selectedProject.job_type.id == 4
			}
			else if(rdi.response_status == 2){
				return this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.job_type && this.$store.projectsStore.state.selectedProject.job_type.id != 4
			}
			return false
		}
	}
};
</script>

<style scoped>
	#listado-consultas{align-self: stretch;}
	.text-primary-color{
		color: #2D4B95;
	}
	.light-blue-text{color: #5E85EF;}
	.bg-normal{ background-color: #3EBFA3; }
	.inner-text{
		font-size: 10px;
	}
	.status-container{
		position: relative;
	}
	.icon-top.icon-status{
		position: absolute;
		width: 20px;
		height: 20px;
		left: -20px;
	}
	.icon-top{
		padding: 2px;
		margin: 0 2px;
		border-radius: 100%;
	}
	.high{
		color: #F4516C;
	}
	.medium{
			color: #fb8c00;
		}
	.normal{
			color: #3EBFA3;
		}
	.icon-top.high{
		background-color: #F4516C;
	}
	.icon-top.medium{
		background-color: #fb8c00;
	}
	.icon-top.normal{
		background-color: #3EBFA3;
	}
	.icon-top.primary{
		background-color: #2D4B95;
	}
	.icon-top.light-blue{
		background-color: #5E85EF;
	}
	.counter-tareas{
		background-color: #F4516C;
		border-radius: 100%;
		padding: 5px;
		width: 30px;
		height: 30px;
		line-height: 20px;
		color: white;
		font-size: 20px;
		text-align: center;
	}
	.status-icon{
		border: 1px solid #5e85ef;
		border-radius: 100%;
	}
	/deep/ .status-icon .inner{
		border: 1px solid #f2f2f2;
		border-radius: 100%;
		width: 14px;
		height: 14px;
	}
	/deep/ .status-icon .inner.danger{
		background-color: #F4516C;
	}
	/deep/ .header-table-card .table-header-button:hover:before{
		opacity: 0;
	}
	.text-tarea-field{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3; /* number of lines to show */
		-webkit-box-orient: vertical;
		max-height: 50px;
	}
	/deep/ .text-tarea-field h1,/deep/ .text-tarea-field h2,/deep/ .text-tarea-field h3{
		font-size: 1em!important;
	}
	.icon-container{flex-wrap: wrap;}
	.icon-container .icon-top{
		flex: 1 0 30%;
		width: 24px;
		max-width: 24px;
		height: 24px;
		max-height: 24px;
		margin-bottom: 2px;
	}
	.icon-container .icon-top.number{
		background-color: #2D4B95;
		text-align: center;
		line-height: 20px;
	}
	.text-task-number.caption{ font-size: 10px!important; }
	.icon-container .icon-top.total{
		background-color: #5E85EF;
		text-align: center;
		line-height: 20px;
	}
	.critical-date-container{
		background-color: #5E85EF;
	}
	.listing-loading-bar{
		position: absolute;
		bottom: 0;
	}
	.header-table-card{
		border: 1px solid white;
	}
	.status-ended{
		border: 1px solid #4caf50;
	}
/*	.listing-scroll{
		overflow-y: scroll;
		height: 100%;
	}*/
	.tarea-card{
		position: relative;
	}
	.incognito-container{
		width: 0;
		height: 0;
		border-top: 33px solid #616161;
		border-right: 33px solid transparent;
		border-radius: 0!important;
		position: absolute;
		left: 0px;
	}
	.incognito-container .v-icon{
		position: absolute;
		top: -32px;
		left: 1px;
	}
	.float-total-icon{
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
	}
	.near-deadline{
		background-color: #fb8c00;
	}
	.text-rdi-field{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
		max-height: 50px;
	}
	/deep/ .ttext-rdi-field h1,/deep/ .text-rdi-field h2,/deep/ .text-rdi-field h3{
		font-size: 1em!important;
	}
	.custom-button-consultas.v-btn{
		background-color: white;
		color: #2D4B95;
		opacity: 1!important;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon,
	/deep/ .custom-button-consultas.v-btn p{
		color: #2D4B95!important;
		font-size: 13px;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon{font-size: 20px;}
	/deep/ .custom-button-consultas.v-btn:hover .v-icon,
	/deep/ .custom-button-consultas.v-btn:hover p,
	/deep/ .custom-button-consultas.v-btn.selected .v-icon,
	/deep/ .custom-button-consultas.v-btn.selected p{
		color: white!important;;
	}
	.custom-button-consultas.selected,
	.custom-button-consultas:hover{
		background-color: #2D4B95!important
	}
</style>