import {endpoints} from './endpoints'
import request from '../request'

export function fileUpload(data, onUploadProgress = null) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.fileUpload,
			method: 'post',
			data,
			onUploadProgress,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getZippedFiles(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getZippedFiles,
			method: 'post',
			data,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}